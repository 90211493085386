import React from "react";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';

export default function GDGraph({ graph_data }) {

  const series = [
    {
      name: "Geyser Demand Percentage",
      type: "line",
      data: graph_data.gd_values,
      color: "#FDB940",
    },
  ];

  const data = {
    series: series,
    options: {
      chart: {
        type: "line",
        stacked: false,
        animations: {
          enabled: false,
          dynamicAnimation: {
            enabled: false,
          },
        },
        background: '#ffffff',
      },
      markers: {
        size: [0, 0, 0, 0, 0, 10, 10],
        fillOpacity: [0, 0, 0, 0, 0, 1, 0.4],
      },
      fill: {
        type: "solid",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: graph_data.timestamps,
        type: "datetime",
        labels: {
          datetimeUTC: false,
      },
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: '#808080',
          }
        }
      },
      tooltip: {
        shared: true,
      },
    },
  };

   const renderCircle = () => (
    <div
      style={{
        width: "18px",
        height: "4px",
        borderRadius: "100%",
        backgroundColor: "#FDB940",
        marginLeft: "8px",
        marginTop: "40px"
      }}
    />
  );

  return (
    <Card sx={{ borderRadius: '12px', backgroundColor: '#F6F6F6', mt: 5, mb: 10 }}>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={0.25} />
        <Grid item xs={11.5} align="left">
          <Box display="flex" alignItems="center">
            <Typography
              style={{ fontSize: "25px", color: "black" }}
              sx={{ mt: 10, mb: 5 }}
            >
             Geyser Demand Percentage 
            </Typography>
            {renderCircle()}
          </Box>
        </Grid>
        <Grid item xs={0.25} />
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} align="center">
          <Box borderRadius={6} bgcolor="white" p={2} width="95%" mb={4}>
            <Chart options={data.options} series={data.series} height={400} />
          </Box>
        </Grid>
      </Grid>

    </Card>
  );
}
