//React
import React from "react";
//Routing
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
//RTK Toolkit
import { useGetJobCardStateQuery } from "../../Features/api/apiSlice";
//Components
import LoginPage from "../../Features/Authentication/LoginPage";
import LandingPage from "../../Features/EnergyManagement/LandingPage";
import ClientPage from "../../Features/EnergyManagement/ClientPage";
import GroupPage from "../../Features/EnergyManagement/GroupPage";
import DevicePage from "../../Features/EnergyManagement/DeviceUpdated";
import Notifications from "../../Global/notifications/Notifications";
import OperatorDashboardPage from "../../Features/OperatorDashboard/OperatorDashboardPage";
import InstallerLandingPage from "../../Features/Installer/InstallerLandingPage";
import QRCodeScanPage from "../../Features/Installer/QRCodeScanPage";
import ClientDetailsPage from "../../Features/Installer/ClientDetailsPage";
import { BeforeInstallPage, AfterInstallPage } from "../../Features/Installer/PhotoCaptureProcess";
import ConnectionTestInterludePage from "../../Features/Installer/ConnectionTestInterludePage";
import ConnectionTestPage from "../../Features/Installer/ConnectionTestPage";
import JobCompletePage from "../../Features/Installer/JobCompletePage";
import { WorkAreaBefore } from "../../Features/Installer/WorkAreaBefore";
import { WorkAreaAfter } from "../../Features/Installer/WorkAreaAfter";
import PreDispatchConnectionTestPage from "../../Features/Installer/PreDispatchConnectionTestPage";
import OperatorNavbar from "../navbar/OperatorNavbar";
import HistoricalDataPage from "../../Features/OperatorDashboard/HistoricalData/HistoricalData";
import MunicipalityNavbar from "../navbar/MunicipalityNavbar";
import MuncipipalityLandingPage from "../../Features/Municipality/MunicipalityLandingPage";
import ADMDPage from "../../Features/Municipality/ADMDPage";
import HowItWorksPage from "../../Features/ResidenceApp/Onboarding/HowItWorksPage";
import HWIPage from "../../Features/ResidenceApp/Onboarding/HWIPage";
import WhatsappPage from "../../Features/ResidenceApp/Onboarding/WhatsAppPage";
import AddToHomePage from "../../Features/ResidenceApp/Onboarding/AddToHomePage";
import TermsAndConditionsPage from "../../Features/ResidenceApp/Onboarding/TermsAndConditions";
import DeviceDetailsPage from "../../Features/ResidenceApp/Onboarding/DeviceDetailsPage";
import DashboardPage from "../../Features/ResidenceApp/Landing/Dashboard";
import ResidentsNavbar from "../../Features/ResidenceApp/Nav/ResidentsNavBar";
import SupportLanding from "../../Features/ResidenceApp/Support/SupportLanding";
import About from "../../Features/ResidenceApp/Support/About";
import TermsAndConditions from "../../Features/ResidenceApp/Support/TCs";

import Navbar from "../navbar/Navbar";
//Redux
import { useSelector } from "react-redux";
import { getUserGroups } from "../../Features/Authentication/authSlice";
import { checkIsLoggedIn } from "../../Features/Authentication/authSlice";
import NotificationsPage from "../../Features/ResidenceApp/Onboarding/Notifications";

export default function MainRouter() {

  const isLoggedIn = useSelector(checkIsLoggedIn);

  return (
    <>
      <Routes>
        {/* Private Routes */}
        <Route element={<PrivateRoute />}>

          {/*Raw Data Routes  */}
        <Route element={<AnyUserGroupExists required_user_groups={['global_super_user', 'dsm_operator']} />}>
          <Route exact path="/rawdata/" element={<><Navbar /><LandingPage /></>} />
          <Route exact path="/client/:client_id/metergroups/" element={<><Navbar /><ClientPage /></>} />
          <Route exact path="/client/:client_id/metergroup/:group_id/meters" element={<><Navbar /><GroupPage /></>} />
          <Route exact path="/client/:client_id/metergroup/:group_id/meter/:meter_id/" element={<><Navbar /><DevicePage /></>} />
      </Route>

          {/* Installer Routes inside Private Routes*/}
          <Route element={<AnyUserGroupExists required_user_groups={['global_super_user', 'dsm_installer','neura_ims_admin']} />}>

            <Route exact path="/installer/" element={<><InstallerLandingPage /></>} />
            <Route exact path="/installer/details/" element={<><ClientDetailsPage /></>} />
            <Route element={<JobcardStateCheck state_check={'QRC'} />}>
              <Route exact path="/installer/:job_card_reference_id/qrcode/" element={<><QRCodeScanPage /></>} />
            </Route>
            <Route element={<JobcardStateCheck state_check={'BEFWA'} />}>
              <Route exact path="/installer/:job_card_reference_id/:serial_number/:imsi_number/work_area_before_install/" element={<><WorkAreaBefore /></>} />
            </Route>
            <Route element={<JobcardStateCheck state_check={'BEF'} />}>
              <Route exact path="/installer/:job_card_reference_id/:serial_number/:imsi_number/before_install/" element={<><BeforeInstallPage /></>} />
            </Route>
            <Route element={<JobcardStateCheck state_check={'TES'} />}>
              <Route exact path="/installer/:job_card_reference_id/:serial_number/:imsi_number/connection_test_interlude/" element={<><ConnectionTestInterludePage /></>} />
              <Route exact path="/installer/:job_card_reference_id/:serial_number/:imsi_number/connection_test/" element={<><ConnectionTestPage /></>} />
            </Route>
            <Route element={<JobcardStateCheck state_check={'AFT'} />}>
              <Route exact path="/installer/:job_card_reference_id/:serial_number/:imsi_number/after_install/" element={<><AfterInstallPage /></>} />
            </Route>
            <Route element={<JobcardStateCheck state_check={'AFTWA'} />}>
              <Route exact path="/installer/:job_card_reference_id/:serial_number/:imsi_number/after_install_work_area/" element={<><WorkAreaAfter /></>} />
            </Route>
            <Route element={<JobcardStateCheck state_check={'REF'} />}>
              <Route exact path="/installer/:job_card_reference_id/:serial_number/:imsi_number/job_completed/" element={<><JobCompletePage /></>} />
            </Route>

          </Route>

          {/* Route for IMS admin to test device connection*/}
          <Route element={<AnyUserGroupExists required_user_groups={['global_super_user', 'neura_ims_admin']} />}>
            <Route exact path="/installer/imsconnectiontest/" element={<><PreDispatchConnectionTestPage /></>} />
          </Route>

          {/* Operator Routes inside Private Routes*/}
          <Route element={<AnyUserGroupExists required_user_groups={['global_super_user', 'dsm_operator','dsm_operator_view_only']} />}>
            <Route exact path="/" element={<><OperatorNavbar /><OperatorDashboardPage /></>} />
            <Route exact path="/operator/historical-data/:serial_number/:meter_id/" element={<><OperatorNavbar /><HistoricalDataPage /></>} />
            <Route exact path="/operator/admd/" element={<><OperatorNavbar /><ADMDPage /></>} />
          </Route>

          {/* Municipality Routes inside Private Routes*/}
          <Route element={<AnyUserGroupExists required_user_groups={[ 'global_super_user','dsm_municipality']} />}>
            <Route exact path="/municipality/" element={<><MunicipalityNavbar /><MuncipipalityLandingPage /></>} />
            <Route exact path="/municipality/admd/" element={<><MunicipalityNavbar /><ADMDPage /></>} />
          </Route>
        </Route>


        {/* Public Routes */}
        <Route exact path="/residents/:serial_number/" element={<><ResidentsNavbar/><DashboardPage /></>} />
        <Route exact path="/residents/:serial_number/support" element={<><ResidentsNavbar/><SupportLanding /></>} />
        <Route exact path="/residents/:serial_number/support/faq" element={<><ResidentsNavbar/><About /></>} />
        <Route exact path="/residents/:serial_number/support/terms-and-conditions" element={<><ResidentsNavbar/><TermsAndConditions /></>} />
        <Route exact path="/residents/:serial_number/how-it-works/" element={<><HowItWorksPage /></>} />
        <Route exact path="/residents/:serial_number/hot-water-index/" element={<><HWIPage /></>} />
        <Route exact path="/residents/:serial_number/whatsapp/" element={<><WhatsappPage /></>} />
        <Route exact path="/residents/:serial_number/notifications/" element={<><NotificationsPage /></>} />
        <Route exact path="/residents/:serial_number/add-to-home/" element={<><AddToHomePage /></>} />
        <Route exact path="/residents/:serial_number/terms-and-conditions/" element={<><TermsAndConditionsPage /></>} />
        <Route exact path="/residents/:serial_number/device-details/" element={<><DeviceDetailsPage /></>} />
        <Route exact path="/login" element={<LoginPage />}></Route>
      </Routes>

      <Notifications />
    </>
  );
}

function PrivateRoute() {
  const isLoggedIn = useSelector(checkIsLoggedIn);
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

function AnyUserGroupExists({ required_user_groups }) {
  const user_groups = useSelector(getUserGroups);
  const permission_check = required_user_groups.some(value => user_groups.includes(value));
  if (permission_check) {
    return <Outlet />
  } else {
    return <Navigate to="/login" />
  }
}

function JobcardStateCheck({ state_check }) {

  const {
    data: job_card_state,
    isSuccess: isGetJobCardStateSuccess,
  } = useGetJobCardStateQuery();

  if (isGetJobCardStateSuccess) {
    if (job_card_state.state === state_check) {
      return <Outlet />
    } else {
      return <Navigate to="/installer/" />
    }
  } else {
    return <></>
  }
}
