import React from "react";
import { useState } from "react";
//Material UI
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import SettingsIcon from "@mui/icons-material/Settings";
import SaveIcon from "@mui/icons-material/Save";
import {
    useGetSettingsQuery,
    useUpdateSettingsMutation,
} from "./scheduleApiSlice";

export default function SettingsButton() {
    const [dialog_open, setDialogOpen] = useState(false);

    const handleOpenSettings = () => {
        setDialogOpen(true);
    };

    const handleCloseSettings = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <ListItem sx={{ marginTop: 2 }} button onClick={handleOpenSettings}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
            </ListItem>
            <Dialog
            sx={{zIndex: 1500,}}
                BackdropProps={{ invisible: true }}
                PaperProps={{
                    style: {
                        backgroundColor: "#ECECEC",

                    },
                }}
                fullWidth
                maxWidth="sm"
                open={dialog_open}
                onClose={handleCloseSettings}
            >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <SettingsDialog closeDialog={handleCloseSettings} />
                </DialogContent>
            </Dialog>
        </>
    );
}

function SettingsDialog({ closeDialog }) {
    const [thermo_start, setthermo_start] = useState("");

    const [thermo_end, setthermo_end] = useState("");
    const [shave_start, setshave_start] = useState("");
    const [shave_end, setshave_end] = useState("");
    const [shift_start, setshift_start] = useState("");
    const [shift_end, setshift_end] = useState("");
    const [editSettings] = useUpdateSettingsMutation();
    const [integration_period, setintegration_period] = useState("");
    const { data: settings_data, isSuccess: isSettingsSuccess } =
        useGetSettingsQuery();
    React.useEffect(() => {
        if (isSettingsSuccess) {
            setthermo_end(settings_data.thermo_end);
            setthermo_start(settings_data.thermo_start);
            setshave_end(settings_data.shave_end);
            setshave_start(settings_data.shave_start);
            setshift_end(settings_data.shift_end);
            setshift_start(settings_data.shift_start);
            setintegration_period(settings_data.integration_period);
        }
    }, [isSettingsSuccess]);

    const handleSubmit = (event) => {
        const body = {
            thermo_end: thermo_end,
            thermo_start: thermo_start,
            shave_end: shave_end,
            shave_start: shave_start,
            shift_end: shift_end,
            shift_start: shift_start,
            integration_period: integration_period,
        };
        const request = {
            body: body,
        };

        editSettings(request);
        closeDialog();
    };
    return (
        <>
            <Grid container alignItems="center">
                <Grid item xs={6} align="left">
                    <Typography sx={{ mb: 2 }}>Settings</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                    <ClearIcon onClick={closeDialog} />
                </Grid>
            </Grid>
            <Grid
                container
                component="form"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
                sx={{ p: 1, "& button": { m: 2 } }}
                gap={1}
            >
                <HWI
                    setthermo_end={setthermo_end}
                    setthermo_start={setthermo_start}
                    thermo_end={thermo_end}
                    thermo_start={thermo_start}
                />
                <LoadShiftingHWI
                    setshift_end={setshift_end}
                    setshift_start={setshift_start}
                    shift_end={shift_end}
                    shift_start={shift_start}
                />
                <PeakShavingHWI
                    setshave_end={setshave_end}
                    setshave_start={setshave_start}
                    shave_end={shave_end}
                    shave_start={shave_start}
                />
                <Integration
                    integration_period={integration_period}
                    setintegration_period={setintegration_period}
                />

                <Grid item xs={12} align="right" style={{ marginBottom: 10 }}>
                    <Button
                        align="right"
                        variant="contained"
                        sx={{ mt: -2 }}
                        startIcon={<SaveIcon />}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

function HWI({ setthermo_end, setthermo_start, thermo_end, thermo_start }) {
    return (
        <Box
            sx={{
                width: "100%",
                color: "#000",
                backgroundColor: "#fff",
                borderRadius: 4,
                marginBottom: "25px",
            }}
        >
            <Grid container sx={{ p: 1 }} alignItems="center">
                <Grid item xs={12} align="center">
                    <Typography sx={{ mb: 2 }}>
                        Hot Water Index Settings
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="center">
                <Grid item xs={6} align="left">
                    <Grid container sx={{ p: 1 }} alignItems="center">
                        <Grid item xs={6} align="left">
                            <Typography>Thermo start</Typography>
                        </Grid>
                        <Grid item xs={6} align="left">
                            <TextField
                                value={thermo_start}
                                onChange={(e) =>
                                    setthermo_start(e.target.value)
                                }
                                required
                                fullidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6} align="left">
                    <Grid container sx={{ p: 1 }} alignItems="center">
                        <Grid item xs={6} align="left">
                            <Typography>Thermo end</Typography>
                        </Grid>
                        <Grid item xs={6} align="left">
                            <TextField
                                value={thermo_end}
                                onChange={(e) => setthermo_end(e.target.value)}
                                name="thermo_end"
                                required
                                fullidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

function LoadShiftingHWI({
    setshift_end,
    setshift_start,
    shift_end,
    shift_start,
}) {
    return (
        <Box
            sx={{
                width: "100%",
                color: "#000",
                backgroundColor: "#fff",
                borderRadius: 6,
                marginBottom: "25px",
            }}
        >
            <Grid container sx={{ p: 1 }} alignItems="center">
                <Grid item xs={12} align="center">
                    <Typography sx={{ mb: 2 }}> Load Shifting HWI</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="center">
                <Grid item xs={6} align="left">
                    <Grid container sx={{ p: 1 }} alignItems="center">
                        <Grid item xs={6} align="left">
                            <Typography>Shift start</Typography>
                        </Grid>
                        <Grid item xs={6} align="left">
                            <TextField
                                value={shift_start}
                                onChange={(e) => setshift_start(e.target.value)}
                                name="shift_start"
                                required
                                fullidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6} align="left">
                    <Grid container sx={{ p: 1 }} alignItems="center">
                        <Grid item xs={6} align="left">
                            <Typography>Shift end</Typography>
                        </Grid>
                        <Grid item xs={6} align="left">
                            <TextField
                                value={shift_end}
                                onChange={(e) => setshift_end(e.target.value)}
                                name="shift_end"
                                required
                                fullidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

function PeakShavingHWI({
    setshave_end,
    setshave_start,
    shave_end,
    shave_start,
}) {
    return (
        <Box
            sx={{
                width: "100%",
                color: "#000",
                backgroundColor: "#fff",
                borderRadius: 6,
                marginBottom: "25px",
            }}
        >
            <Grid container sx={{ p: 1 }} alignItems="center">
                <Grid item xs={12} align="center">
                    <Typography sx={{ mb: 2 }}>Peak Shaving HWI</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="center">
                <Grid item xs={6} align="left">
                    <Grid container sx={{ p: 1 }} alignItems="center">
                        <Grid item xs={6} align="left">
                            <Typography>Shave start</Typography>
                        </Grid>
                        <Grid item xs={6} align="left">
                            <TextField
                                value={shave_start}
                                onChange={(e) => setshave_start(e.target.value)}
                                name="shave_start"
                                required
                                fullidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6} align="left">
                    <Grid container sx={{ p: 1 }} alignItems="center">
                        <Grid item xs={6} align="left">
                            <Typography>Shave end</Typography>
                        </Grid>
                        <Grid item xs={6} align="left">
                            <TextField
                                value={shave_end}
                                onChange={(e) => setshave_end(e.target.value)}
                                name="shave_end"
                                required
                                fullidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

function Integration({ setintegration_period, integration_period }) {
    return (
        <Box
            sx={{
                width: "100%",
                color: "#000",
                backgroundColor: "#fff",
                borderRadius: 6,
                marginBottom: "25px",
            }}
        >
            <Grid container sx={{ p: 1 }} alignItems="center">
                <Grid item xs={12} align="center">
                    <Typography sx={{ mb: 2 }}>Integration</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="center">
                <Grid item xs={12} align="left">
                    <Grid container sx={{ p: 1 }} alignItems="center">
                        <Grid item xs={6} align="left">
                            <Typography>Integration Period</Typography>
                        </Grid>
                        <Grid item xs={6} align="left">
                            <TextField
                                value={integration_period}
                                onChange={(e) =>
                                    setintegration_period(e.target.value)
                                }
                                name="integration_period"
                                required
                                fullidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
