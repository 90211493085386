import * as React from "react";
import { useGetJobCardStateQuery } from "../api/apiSlice";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import theme from "../../Global/theme"
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";
import { getUserName } from "../Authentication/authSlice";
import LogOutDialog from "./LogoutDialog";
import { WithAnyPermissionGroupTrue } from "../../Global/permissions/checkPermissions";

export default function InstallerLandingPage() {

  let navigate = useNavigate();
  const user_name = useSelector(getUserName)

  const {
    data: job_card_data,
    isSuccess: isGetJobCardStateSuccess,
  } = useGetJobCardStateQuery();

  useEffect(() => {
    if (isGetJobCardStateSuccess) {
      console.log(job_card_data.state)

      if (job_card_data.state === 'QRC') {
        navigate(`/installer/${job_card_data.reference_id}/qrcode/`)
      } else if (job_card_data.state === 'BEF') {
        navigate(`/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/before_install/`)
      } else if (job_card_data.state === 'BEFWA') {
        navigate(`/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/work_area_before_install/`)
      } else if (job_card_data.state === 'TES') {
        navigate(`/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/connection_test_interlude/`)
      } else if (job_card_data.state === 'AFT') {
        navigate(`/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/after_install/`)
      } else if (job_card_data.state === 'AFTWA') {
        navigate(`/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/after_install_work_area/`)
      } else if (job_card_data.state === 'REF') {
        navigate(`/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/job_completed/`)
      }
    }
  }, [isGetJobCardStateSuccess]);

  const ButtonTestConnectionWithPermissions = WithAnyPermissionGroupTrue(ButtonTestConnection,["global_super_user","neura_ims_admin"])

  if (isGetJobCardStateSuccess && job_card_data.state === 'NONE') {
    return (
      <>

        <Helmet>
          <style>{'body { background: #FDB940; }'}</style>
        </Helmet>

        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 5 }}>
          <Grid item xs={8} align="center" />
          <Grid item xs={3.5} align="right">
            <LogOutDialog />
          </Grid>
          <Grid item xs={0.5} align="right" />


          <Typography
            style={{ fontSize: "40px", color: "white" }}
            sx={{ mt: 10, mb: 5 }}
          >
            Welcome
          </Typography>
          <Grid item xs={12} align="center">
            <Typography
              style={{ fontSize: "16px", color: "black" }}
              sx={{ mt: 5, mb: 5 }}
            >
              Installer name : {user_name}
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">

          </Grid>

          <Grid item xs={1} />
          <Grid
            item
            xs={10}
            align="center"
          >
            <Button
              style={theme.whiteButtonStyle.root}
              variant="outlined"
              onClick={() => navigate(`/installer/details/`)}
            >
              New Installation
            </Button>
          </Grid>
          <Grid item xs={1} />

          <ButtonTestConnectionWithPermissions />

        </Grid>
      </>
    )
  } else {
    return <></>
  }
}

function ButtonTestConnection() {

  let navigate = useNavigate();

  return (
    <>
      <Grid item xs={1} />
      <Grid
        item
        xs={10}
        align="center"
      >
        <Button
          style={theme.whiteButtonStyle.root}
          variant="outlined"
          onClick={() => navigate(`/installer/imsconnectiontest/`)}
          sx={{ mt: 1 }}
        >
          Test Connection
        </Button>
      </Grid>
      <Grid item xs={1} />
    </>
  )
}

