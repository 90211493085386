import React from 'react';
import './Stepper.css'; 

const Stepper = ({ steps, currentStep }) => {
    return (
      <div className="stepper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index < currentStep ? 'completed' : ''} ${
              index === currentStep ? 'active' : ''
            }`}
          />
        ))}
      </div>
    );
  };

export default Stepper;