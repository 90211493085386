import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { setnavName, setnavDrawer } from "../Api/ResidentsSlice";
import { useDispatch } from "react-redux";
import WhatsAppFab from "./FABIcon";
import { useGetResidentsNotificationsQuery } from "../Api/residentsAPISlice";
import { useGetDeviceDetailsQuery } from "../Api/residentsAPISlice";
import { ReactComponent as MeterOnIcon } from "./meter-on-icon.svg";
import { ReactComponent as MeterOffIcon } from "./meter-off-icon.svg";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
export default function DashboardPage() {
  let { serial_number } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onboardingCompleted = localStorage.getItem("onboardingCompleted");
  dispatch(setnavName(""));
  dispatch(setnavDrawer(true));

  React.useEffect(() => {
    if (onboardingCompleted != "true" || onboardingCompleted == "false") {
      navigate(`/residents/${serial_number}/how-it-works`);
    }
  }, []);


  const {
    data: residentsDetails,
    isLoading: isResidentsDetailsLoading,
    isError: isResidentsDetailsError,
    isSuccess: isResidentsDetailsSuccess,
  } = useGetDeviceDetailsQuery({ serial_number });

  const [isMeterOn, setIsMeterOn] = React.useState(false);
  const [normalCycleFontColor, setNormalCycleFontColor] = React.useState(
    "rgba(99, 101, 105, 1)"
  );
  const [tjommieCycleFontColor, setTjommieCycleFontColor] = React.useState(
    "rgba(99, 101, 105, 1)"
  );

  React.useEffect(() => {
    if (isResidentsDetailsSuccess) {
      if (
        residentsDetails.control_status === "Nil" ||
        residentsDetails.control_status === "Recovering"
      ) {
        setIsMeterOn(false);
        setTjommieCycleFontColor("rgba(99, 101, 105, 0.5)");
      } else {
        setIsMeterOn(true);
        setNormalCycleFontColor("rgba(99, 101, 105, 0.5)");
      }
    }
  }, [isResidentsDetailsSuccess, residentsDetails]);


  if (isResidentsDetailsError || isResidentsDetailsLoading) {
    return <></>;
  } else {
    return (
      <>
        <WhatsAppFab phoneNumber={"+27633157596"} />
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={11} align="center">
            <Grid container>
              <Grid item xs={12} sx={{ mt: 2, mb: 1 }} align="center">
                <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
                  {serial_number}
                </Typography>
              </Grid>

              <Notification serial_number={serial_number}/>
              <MeterBoard isMeterOn={isMeterOn} normalCycleFontColor={normalCycleFontColor} tjommieCycleFontColor={tjommieCycleFontColor} />
              <HeatingCoolingBoard thermostat_on={residentsDetails.energy_consuming_device.thermostat_on} />
              <HWIinfo/>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

function Notification({serial_number}) {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  const {
    data: residentsStatus,
    isLoading: isresidentsStatusLoading,
    isError: isresidentsStatusError,
  } = useGetResidentsNotificationsQuery({ serial_number, userTimezone });

  if(isresidentsStatusLoading || isresidentsStatusError){
    return (
      <Grid item xs={12} align="center" sx={{ mb: 1 }}>
    </Grid>
    )
  }
  else{
  return (
    <Grid item xs={12} align="center" sx={{ mb: 1 }}>
      <Box
        width="100%"
        height="40px"
        bgcolor="#FFF2DD"
        border={1}
        borderRadius={1}
        borderColor="#FDB940"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container alignItems="center" justifyContent="center">
       
          <Grid item xs={12} align="center">
          
            <Typography
                variant="body1"
                sx={{
                  fontSize: 14,
                  color: "#000",
                  display: "flex",
                  alignItems: 'center', justifyContent: 'center' }}>
               
                <InfoOutlinedIcon
                  sx={{
                    marginRight: "4px",
                    color: "inherit",
                    fontSize: 18,
                  }}
                />
                {residentsStatus.data}
              </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
}
function MeterBoard({ isMeterOn, normalCycleFontColor, tjommieCycleFontColor}) {

    return (
      <Grid item xs={12} align="center" sx={{ mb: 1 }}>
        <Box
          width="100%"
          height="auto"
          bgcolor="#F6F6F6"
          borderRadius={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={3} align="center" spacing={1} sx={{ mt: -3 }}>
              <Typography
                sx={{ fontSize: "18px", color: normalCycleFontColor }}
              >
                Normal Cycle
              </Typography>
            </Grid>
            <Grid item xs={6} align="center" spacing={1} sx={{mt:1.5}}>
              <Typography
                sx={{
                  fontSize: "15.5px",
                  fontFamily: "Roboto",
                  fontWeight: 200,
                }}
              >
                Thermostat status
              </Typography>
              <MeterONChart isMeterOn={isMeterOn} />
            </Grid>
            <Grid item xs={3} align="center" spacing={1} sx={{ mt: -3 }}>
              <Typography
                sx={{ fontSize: "18px", color: tjommieCycleFontColor }}
              >
                Tjommie Cycle
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }


function MeterONChart({ isMeterOn }) {
  const IconComponent = isMeterOn ? MeterOnIcon : MeterOffIcon;

  return (
    <>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <IconComponent />
      </div>
    </>
  );
}

function HeatingCoolingBoard({ thermostat_on }) {
  const icon_color = thermostat_on ? "#FDB940" : "#636363";
  const text_color = thermostat_on ? "#A16E13" : "#636363";
  const text = thermostat_on ? "Heating" : "Cooling";
  
  return (
    <>
      <Grid item xs={12} align="center" sx={{ mb: 1 }}>
        <Box
          width="100%"
          height="40px"
          bgcolor="#FFFAF0"
          borderRadius={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} align="center">
              <Typography
                variant="body1"
                sx={{
                  fontSize: 16,
                  color: text_color,
                  display: "flex",
                  alignItems: 'center', justifyContent: 'center' }}>
               
                <WbSunnyIcon
                  sx={{
                    marginRight: "4px",
                    color: icon_color,
                    fontSize: "inherit",
                  }}
                />
                {text}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}

function HWIinfo() {

  const text_color =  "#000";
  const text =  "Hot water indexing will keep your water warm";
  
  return (
    <>
      <Grid item xs={12} align="center" sx={{ mb: 1 }}>
        <Box
          width="100%"
          height="70px"
          bgcolor="#F6F6F6"
          borderRadius={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} align="center">
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  color: text_color,
                  display: "flex",
                  alignItems: 'center', justifyContent: 'center' }}>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
