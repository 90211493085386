import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ReactComponent as Tjommie } from "../Resources/Tjommie.svg";
import { ReactComponent as Whatsapp } from "../Resources/Whatsapp.svg";
import { ReactComponent as Notification } from "../Resources/Notification.svg";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
export default function NotificationsPage() {
  let { serial_number } = useParams();
  const navigate = useNavigate();

  const goToNext = () => {
    navigate(`/residents/${serial_number}/add-to-home`);
  };
  const goToPrevious = () => {
    navigate(`/residents/${serial_number}/whatsapp`);
  };
  const skipOnboarding = () => {
    if (onboardingCompleted == "true") {
      navigate(`/residents/${serial_number}/support`);
    } else {
      navigate(`/residents/${serial_number}/terms-and-conditions`);
    }
  };

  const onboardingCompleted = localStorage.getItem("onboardingCompleted");

  React.useEffect(() => {
    if (onboardingCompleted) {
    }
  }, []);
  return (
    <>
          <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      >
        <Grid item xs={11} align="center">
          <Grid container>
            <Grid item xs={2} align="left" sx={{mt:2}}>
              <ClearIcon sx={{ color: "white" }} onClick={skipOnboarding} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center"  bgcolor="#636569" height="74vh">
        <Grid item xs={11} align="center">
         
            <Grid container>
              <Grid item xs={12} sx={{ mt: 2, mb: 4 }} align="center">
                <Tjommie />
              </Grid>

              <Grid item xs={10} align="left">
                <Typography
                  variant="h5"
                  sx={{ color: "#FDB940", fontSize: "30px", textAlign: "left" }}
                >
                  {" "}
                 Alerts & Notifications
                </Typography>
              </Grid>

              {/* <Grid item xs={2} align="right">
                <Whatsapp />
              </Grid> */}
              <Grid item xs={0.5} />

              <Grid item xs={11} sx={{ mt: 2 }} align="center">
                <ParagraphedText />
              </Grid>
              <Grid item xs={10} align="left">
                <TjommieSupport />
              </Grid>
  
            </Grid>
         
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="10vh"
      >
         <Grid item xs={5} align="left">
                
                <Button
                  fullWidth
                  onClick={goToPrevious}
                  style={{ color: "#FDB940" }}
                >
                  Previous
                </Button>
                
              </Grid>
              <Grid item xs={6} align="left">
                <Button
                  fullWidth
                  onClick={goToNext}
                  style={{ color: "#000", backgroundColor: "#FDB940" }}
                >
                  Next
                </Button>
              </Grid>
      </Grid>
    </>
  );
}

function ParagraphedText() {
  return (
    <>
      <p style={{ fontSize: "14px", color: "white", textAlign: "left" }}>
      Please allow notifications if prompted or enable in your browser settings.
        <br />
      </p>
      <p style={{ fontSize: "14px", color: "white", textAlign: "left" }}>
      Stay informed with notifications ahead of time to let you know when your geyser may be turned off.
      </p>
    </>
  );
}

function TjommieSupport() {
  return (
    <>

       
        
            <Notification  style={{ width: '120%' }}  />
       
  
    </>
  );
}
