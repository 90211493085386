import React from 'react';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppFab = ({ phoneNumber }) => {
  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  };

  return (
    <Fab
      style={{
        backgroundColor: '#19C534',
        color: '#fff',
        position: 'fixed',
        bottom: '24px',
        right: '24px',
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', 
      }}
      onClick={handleWhatsAppClick}
    >
      <WhatsAppIcon />
    </Fab>
  );
};

export default WhatsAppFab;