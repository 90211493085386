import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./Features/Authentication/authSlice";
import { apiSlice } from "./Features/api/apiSlice";
import notificationsReducer from "./Global/notifications/notificationsSlice";
import loadShiftReducer from "./Features/LoadShiftSchedule/loadShiftSlice";
import stepperReducer from "./Features/Installer/Stepper/stepperSlice";

import residentsReducer from "./Features/ResidenceApp/Api/ResidentsSlice";

function check_if_production() {
  if (process.env.REACT_APP_PRODUCTION == 1) {
    return false;
  }
  else{
    return true;
  }}

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authenticationReducer,
    notifications: notificationsReducer,
    loadShift: loadShiftReducer,
    stepper: stepperReducer,
    residents: residentsReducer,
  },
  devTools: check_if_production(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});
