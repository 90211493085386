//React
import * as React from "react";
import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//Material UI
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import SchedulePage from "./SchedulePage";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import Chart from "react-apexcharts";
import { useGetClientMeterGroupsQuery } from "../api/apiSlice";
import CircularProgress from "@mui/material/CircularProgress";
export default function ClientPage() {
  let navigate = useNavigate();

  let { client_id } = useParams();
  const { data: meter_groups, isSuccess: isGetClientMeterGroupsSuccess } =
    useGetClientMeterGroupsQuery({ client_id });

  const handlePrevPage = () => {
    navigate(-1);
  };
  const [filteredList, setFilteredList] = new React.useState(meter_groups);

  React.useEffect(() => {
    setFilteredList(meter_groups);
  }, [isGetClientMeterGroupsSuccess, meter_groups]);

  const goToGroupDevices = (group_id) => {
    navigate(`/client/${client_id}/metergroup/${group_id}/meters/`);
  };



  const containerStyle = {
    position: "relative",
  };

  const buttonStyle = {
    position: "absolute",
    top: 33,
    right: 30,
    borderRadius: "100px",
    border: "none",
    color: "#FFFFFF",
    backgroundColor: "#48DD08",
    opacity: 0.5,
    textTransform: "none",
    zIndex: 1,
  };

  function MeterGroupCard({
    id,
    meter_group_name,
    active_schedule_group_name,
    group_description,
    scheduled_status,
    last_switch,
    next_switch,
    time_until_switch,
    aggregate_power,
    graph_data,
  }) {
    const [schedule_dialogue_open, setScheduleDialogueOpen] = useState(false);

    const handleOpenScheduleDialogue = () => {
      setScheduleDialogueOpen(true);
    };
    const handleCloseAllDialogues = () => {
      setScheduleDialogueOpen(false);
    };
    return (
      <>
        {/* <Grid item align="left" lg={1} /> */}
        <Grid item align="center" xs={4} lg={4} sx={{ mb: 1 }}>
          <Card
            sx={{
              minWidth: 375,
              maxWidth: 375,
              minHeight: 515,
              maxHeight: 515,
              display: "flex",
              flexDirection: "column",
              background: "#F8F8F8",
              padding: "20px",
              borderRadius: "10px",
            }}
            elevation={3}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                sx={{ color: "#666666", textAlign: "left", fontSize: "18px" }}
              >
                {meter_group_name}
              </Typography>
              {/* <IconButton
            aria-label="more options"
            size="small"
            sx={{ marginLeft: "auto" }}
          >
            <MoreHorizIcon />
          </IconButton> */}
            </div>
            <div style={containerStyle}>
              <Grid item align="center" xs={12}>
                <Chart
                  options={{
                    responsive: [
                      {
                        breakpoint: 600,
                        options: {
                          xaxis: {
                            tickAmount: 8,
                          },
                        },
                      },
                      {
                        breakpoint: 1200,
                        options: {
                          xaxis: {
                            tickAmount: 12,
                          },
                        },
                      },
                    ],
                    chart: {
                      type: "area",
                      height: 350,
                    },
                    fill: {
                      type: "gradient",
                      solid: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    xaxis: {
                      categories: graph_data.timestamp,
                    },
                    yaxis: {
                      decimalsInFloat: 2,
                    },
                  }}
                  series={[
                    {
                      name: "Value",
                      data: graph_data.total_active_energy,
                    },
                  ]}
                  type="area"
                  height={300}
                />
                <Button variant="outlined" style={buttonStyle}>
                  Stable
                </Button>
              </Grid>
            </div>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 1 }}
              sx={{ mt: 10, mb: 15 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item align="left" xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "#323232", fontSize: "16px" }}
                >
                  Schedule: {active_schedule_group_name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#6A6A6A", fontSize: "14px" }}
                >
                  {group_description}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="right"
                alignItems="right"
                justify="flex-end"
                spacing={1}
                xs={6}
              >
                {scheduled_status === "On" ? (
                  <>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ color: "#323232", fontSize: "16px" }}
                      >
                        On
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Brightness1Icon style={{ color: "#159900" }} />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ color: "#323232", fontSize: "16px" }}
                      >
                        Off
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Brightness1Icon style={{ color: "#DC3737" }} />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item align="left" xs={12}>
                <Typography
                  variant="body1"
                  sx={{ color: "#323232", fontSize: "16px" }}
                >
                  Maximum Power {aggregate_power} KW
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#159900", fontSize: "14px" }}
                >
                  Last Switch: {last_switch}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#6A6A6A", fontSize: "14px" }}
                >
                  Next Switch: {next_switch} ({time_until_switch})
                </Typography>
              </Grid>
              <Grid item align="center" xs={6}>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "5px",
                    border: "none",
                    color: "#795285",
                  }}
                  onClick={handleOpenScheduleDialogue}
                >
                  Schedule
                </Button>
                <Dialog
                  BackdropProps={{ invisible: true }}
                  open={schedule_dialogue_open}
                  PaperProps={{
                    sx: {
                      width: "620px",
                      height: "960px",
                    },
                  }}
                >
                  <DialogContent>
                    <SchedulePage group_id={id} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      style={{
                        borderRadius: "5px",
                        border: "none",
                        color: "#795285",
                      }}
                      onClick={handleCloseAllDialogues}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item align="center" xs={6}>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "5px",
                    border: "2px solid #795285",
                    color: "#795285",
                  }}
                  onClick={() => goToGroupDevices(id)}
                >
                  OPEN GROUP
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </>
    );
  }

  if (isGetClientMeterGroupsSuccess) {
    return (
      <>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 1 }}
        sx={{ mt: 10, mb: 15 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item align="center" xs={12} sx={{ mb: 1 }}>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ mt: 10, mb: 15 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item align="center" xs={1} sx={{ mb: 1 }}>
              <IconButton
                aria-label="more options"
                size="small"
                sx={{ color: "#795285", marginLeft: "auto" }}
                onClick={handlePrevPage}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item align="center" xs={2} sx={{ mb: 1 }}>
              <Typography
                variant="h6"
                sx={{ color: "#795285", textAlign: "left", fontSize: "18px" }}
              >
                Demand Side Management
              </Typography>
            </Grid>
            <Grid item align="center" xs={1} sx={{ mb: 1 }}>
              <Button variant="outlined" style={buttonStyle}>
                Add
                <AddIcon style={{ color: "#fff" }} />
              </Button>
            </Grid>
            <Grid item align="right" xs={6} sx={{ mb: 1 }}>
              <Typography
                variant="h6"
                sx={{ color: "#666666", textAlign: "right", fontSize: "18px" }}
              >
                <SearchBar
                  setFilteredList={setFilteredList}
                  meter_groups={meter_groups}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
        {/* Start of Card */}

<Grid
      container
    >
      <Grid item sm={2} md={1} lg={1}/>
      <Grid item xs={12} sm={8} md={10} lg={10.5}>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12 }}
          gap={2}
          sx={{ mt: -15, mb: 5, p: 2 }}
          direction="row"
          justifyContent={{ xs: "center", lg: "center" }}
          alignItems="center"
        >
        {filteredList?.map((meter_group) => (
          <MeterGroupCard key={meter_group.id} {...meter_group} />
        ))}
         </Grid>
      </Grid>
      <Grid item sm={2} md={1} lg={0.5} />
    </Grid>
        {/* End of Card */}
        </>
    );
  } else {
    return (
      <Box
        sx={{ display: "flex" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress sx={{ color: "#795285" }} />
      </Box>
    );
  }
}

function SearchBar({ setFilteredList, meter_groups }) {
  const [filter, setFilter] = new useState("");
  const clearSearch = () => {
    setFilter("");
    setFilteredList(meter_groups);
  };
  const filterBySearch = (event) => {
    const query = event.target.value;
    setFilter(query);
    var updatedList = [...meter_groups];

    updatedList = updatedList.filter((item) => {
      return (
        item.meter_group_name
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };
  return (
    <TextField
      id="outlined-basic"
      label="Search"
      variant="outlined"
      className="inputRounded"
      placeholder="Search..."
      value={filter || ""}
      onChange={(e) => filterBySearch(e)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRoundedIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <ClearIcon sx={{ color: "#795285" }} onClick={clearSearch} />
          </InputAdornment>
        ),
      }}
    />
  );
}
