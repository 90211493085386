import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ReactComponent as Tjommie } from "../Resources/Tjommie.svg";
import { ReactComponent as HWI } from "../Resources/HWIicon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
export default function HowItWorksPage() {
  let { serial_number } = useParams();
  const navigate = useNavigate();

  const goToNext = () => {
    navigate(`/residents/${serial_number}/hot-water-index`);
  };
  const skipOnboarding = () => {
    if (onboardingCompleted == "true") {
      navigate(-1);
    } else {
      navigate(`/residents/${serial_number}/terms-and-conditions`);
    }
  };
  const onboardingCompleted = localStorage.getItem("onboardingCompleted");

  React.useEffect(() => {
    if (onboardingCompleted) {
    }
  }, []);
  return (
    <Box bgcolor="#636569" minHeight="100vh">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
        
      >
        <Grid item xs={11} align="center">
          <Grid container>
            <Grid item xs={2} align="left" sx={{ mt: 2 }}>
              <ClearIcon sx={{ color: "white" }} onClick={skipOnboarding} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
       
      />

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
       
      >
        <Grid item xs={12} sx={{ mt: 2, mb: 14 }} align="center">
          <Tjommie width="45%" />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="4vh"
      />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="57vh"
      >
        <Grid item xs={11} align="center">
          <Grid container>
            <Grid item xs={5} align="left">
              <Typography
                variant="h5"
                sx={{ color: "#fff", fontSize: "30px", textAlign: "left" }}
              >
                {" "}
                HOW IT WORKS
              </Typography>
            </Grid>
            <Grid item xs={0.5} />
            <Grid item xs={5} sx={{ mt: 3 }} align="right">
              <HWI />
            </Grid>
            <Grid item xs={0.5} />

            <Grid item xs={11} sx={{ mt: 2 }} align="center">
              <ParagraphedText />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="10vh"
       
      >
        <Grid item xs={5} align="left" />
        <Grid item xs={6} align="left">
          <Button
            fullWidth
            onClick={goToNext}
            style={{ color: "#000", backgroundColor: "#FDB940" }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      >
        </Grid>
    </Box>
  );
}

function ParagraphedText() {
  return (
    <p style={{ fontSize: "14px", color: "white", textAlign: "left" }}>
      In order reduce demand peaks and shift load from high demand times geysers
      across the country will be switched off to collectively reduce load.
      <br />
      <br />
      Smart devices are installed at your distribution board - allowing the
      Tjommie team to control your geyser. Your geyser will be temporarily
      switched off during high demand times in order to reduce strain on the
      grid.
      <br />
      <br />
      The idea is to balance the load on the grid and reduce overall energy
      demand spikes.
    </p>
  );
}
