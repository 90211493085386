import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const municipalityAdapter = createEntityAdapter();

const initialState = municipalityAdapter.getInitialState();

export const municipalityAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

   
      getEnergyGraph: builder.query({
        query: (request) => `/dsm/municipality-dashboard/?start_date=${request.start_date}&end_date=${request.end_date}&user-timezone=${request.user_timezone}`,
        providesTags: ["energy_graph"],
      }),

      getEnergyValues: builder.query({
        query: (request) => `/dsm/municipality-dashboard/values/?start_date=${request.start_date}&end_date=${request.end_date}&user-timezone=${request.user_timezone}`,
        providesTags: ["energy_values"],
      }),
  }),
});

export const {
    useGetEnergyGraphQuery,
    useGetEnergyValuesQuery
} = municipalityAPISlice;
