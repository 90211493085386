//React
import React from "react";
import { useState } from "react";
//Material UI
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import { useEditScheduleMutation } from "../api/apiSlice";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import DeleteSchedule from "./DeleteSchedule";

export default function EditSchedule({ schedule, day }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <EditIcon style={{ color: "#000", fontSize: 14  }} onClick={() => handleOpen()} />
      <Dialog
        BackdropProps={{ invisible: true }}
        PaperProps={{
        
        }}
      
        open={open}
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <ScheduleDialogContent
            schedule={schedule}
            day={day}
            handleClose={handleClose}
            open={open}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

function ScheduleDialogContent({ schedule, day, handleClose }) {
  const dispatch = useDispatch();

  const dayList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const [schedule_type, setschedule_day] = useState(
    getDay(day)
  );

  const [startTime, setstartTime] = useState(
    dayjs("2023-01-01T" + schedule.schedule_off)
  );
  const [endTime, setendTime] = useState(
    dayjs("2023-01-01T" + schedule.schedule_on)
  );

  const [editSchedule] = useEditScheduleMutation();

  const handleSubmit = (event) => {
    const body = {
      schedule_day: getDayNumber(schedule_type),
      schedule_off: startTime.$H + ":" + startTime.$m + ":" + startTime.$s,
      schedule_on: endTime.$H + ":" + endTime.$m + ":" + endTime.$s,
    };
    const request = {
      body: body,
      schedule_id: schedule.id,
    };
    editSchedule(request);
    handleClose();
  };
  const onChangeStart = (time: Dayjs) => {
    setstartTime(time);
  };
  const onChangeEnd = (time: Dayjs) => {
    setendTime(time);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={11} align="left">
          <Typography >Edit schedule</Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <ClearIcon onClick={handleClose} />
        </Grid>

        <Grid item xs={12} align="center">
          <Typography>Schedule Day</Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <FormControl sx={{ mb: 2 }} fullWidth className="inputRounded">
            <Select
              required
              value={schedule_type}
              onChange={(e) => setschedule_day(e.target.value)}
            >
              {dayList.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

       
        <Grid item xs={12} md={5.5} align="center">
          <Grid item xs={12} align="center">
            <Typography >Switch off </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{ mb: 2 }} components={["TimePicker"]}>
                <TimePicker
                  className="inputRounded"
                  value={startTime}
                  onChange={onChangeStart}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={0} md={1} align="center" />
        <Grid item xs={12} md={5.5} align="center">
          <Grid item xs={12} align="center">
            <Typography>Switch on </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  className="inputRounded"
                  value={endTime}
                  onChange={onChangeEnd}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={2} align="left"/>
        <Grid item xs={4} align="left">
          <Button
            sx={{ mt: 2, mb: 2 }}
            style={{ color: "#000" }}
            onClick={handleSubmit}
          >
            Submit Changes
          </Button>
        </Grid>

        <Grid item xs={4} align="left">
         <DeleteSchedule schedule={schedule}/>
        </Grid>
        <Grid item xs={2} align="left"/>
      </Grid>
    </>
  );
}

function getDay(dayGiven) {
  let day = "";
  switch (dayGiven) {
    case 6:
      day = "Sunday";
      break;
    case 0:
      day = "Monday";
      break;
    case 1:
      day = "Tuesday";
      break;
    case 2:
      day = "Wednesday";
      break;
    case 3:
      day = "Thursday";
      break;
    case 4:
      day = "Friday";
      break;
    case 5:
      day = "Saturday";
    break;
    default:
      day = dayGiven;
  }

  return day;
}

function getDayNumber(dayGiven) {
  let day = "";
  switch (dayGiven) {
    case "Sunday":
      day = 6;
      break;
    case "Monday":
      day = 0;
      break;
    case "Tuesday":
      day = 1;
      break;
    case "Wednesday":
      day = 2;
      break;
    case "Thursday":
      day = 3;
      break;
    case "Friday":
      day = 4;
      break;
    case "Saturday":
      day = 5;
      break;
    default:
      day = dayGiven;
  }

  return day;
}
