import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import theme from "../../Global/theme";
import { setCurrentStep } from "./Stepper/stepperSlice";
import { useDispatch } from "react-redux";
import NavbarStepper from "./Stepper/NavBarStepper";

import Alert from "@mui/material/Alert";

export default function ConnectionTestInterludePage() {
  let { serial_number } = useParams();
  let { imsi_number } = useParams();
  let { job_card_reference_id } = useParams();
  let navigate = useNavigate();

  const dispatch = useDispatch();
  dispatch(setCurrentStep(5));

  return (
    <>
      <NavbarStepper />
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} align="center">
          <Typography variant="h5" color="grey" sx={{ mt: 5, mb: 2 }}>
            Proceed with device installation.
          </Typography>
        </Grid>
        <Grid item xs={10} align="center">
        <Alert severity="warning" sx={{ mb: 2 }}>
        Ensure the geyser is alone on circuit
          </Alert>
          </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} align="center">
          <Button
            style={theme.yellowButtonStyle.root}
            onClick={() =>
              navigate(
                `/installer/${job_card_reference_id}/${serial_number}/${imsi_number}/connection_test/`
              )
            }
          >
            Next
          </Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </>
  );
}
