//React
import * as React from "react";
import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//Material UI
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Avatar from "@mui/material/Avatar";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import SchedulePage from "./SchedulePage";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetMeterGroupMetersQuery } from "../api/apiSlice";

export default function GroupPage() {
  let navigate = useNavigate();
  let { client_id, group_id } = useParams();
  const { data: group_meters, isSuccess: isGetGroupMetersSuccess } =
    useGetMeterGroupMetersQuery({ client_id, group_id });
  const handlePrevPage = () => {
    navigate(-1);
  };
  const [filteredList, setFilteredList] = new useState(group_meters);

  React.useEffect(() => {
    setFilteredList(group_meters);
  }, [isGetGroupMetersSuccess, group_meters]);

  const goToDevice = (meter_id) => {
    navigate(`/client/${client_id}/metergroup/${group_id}/meter/${meter_id}/`);
  };

  const buttonStyle = {
    borderRadius: "100px",
    border: "none",
    color: "#fff",
    backgroundColor: "#219653",
    opacity: 1,
    textTransform: "none",
    zIndex: 2,
    fontSize: "12px",
  };

  function MeterCard({
    id,
    ecd_description,
    serial_number,
    uuid,
    connected,
    is_on,
    heating,
    latest_date_received,
  }) {
    const [schedule_dialogue_open, setScheduleDialogueOpen] = useState(false);

    const handleOpenScheduleDialogue = () => {
      setScheduleDialogueOpen(true);
    };
    const handleCloseAllDialogues = () => {
      setScheduleDialogueOpen(false);
    };
    return (
      <Grid item align="center" xs={4} sx={{ mb: 1 }}>
        <Card
          sx={{
            minWidth: 358,
            maxWidth: 358,
            minHeight: 150,
            maxHeight: 250,
            display: "flex",
            flexDirection: "column",
            background: "#F8F8F8",
            padding: "20px",
            borderRadius: "10px",
          }}
          elevation={3}
        >
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ mt: 10, mb: 15 }}
            rowGap={{ xs: 1, sm: 1, md: 1 }}
            direction="row"
            justifyContent="left"
            alignItems="top"
          >
            <Grid item align="left" xs={2}>
              <Avatar
                sx={{ bgcolor: "#BDBDBD", width: "40px", height: "40px" }}
              >
                G
              </Avatar>
            </Grid>
            <Grid item align="left" xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#000000", fontSize: "14px" }}
              >
                {ecd_description}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#6A6A6A", fontSize: "12px" }}
              >
                {serial_number}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#6A6A6A", fontSize: "12px" }}
              >
                {uuid}
              </Typography>
            </Grid>
            <Grid item align="right" xs={6}>
              {/* <IconButton
              aria-label="more options"
              size="small"
              sx={{ marginLeft: "auto" }}
            >
              <MoreHorizIcon />
            </IconButton> */}
              <Grid
                container
                spacing={{ xs: 2, sm: 2, md: 2 }}
                rowGap={{ xs: 2, sm: 2, md: 2 }}
                direction="row"
                justifyContent="left"
                alignItems="top"
              >
                {heating ? (
                  <>
                    <Grid item align="right" xs={8}>
                      <WbSunnyIcon style={{ color: "#C23D4A" }} />
                    </Grid>
                    <Grid item align="right" xs={4}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#000000", fontSize: "14px" }}
                      >
                        Heating
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    {" "}
                    <Grid item align="right" xs={8}>
                      <WbSunnyIcon style={{ color: "#2E93FA" }} />
                    </Grid>
                    <Grid item align="right" xs={4}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#000000", fontSize: "14px" }}
                      >
                        Cooling
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item align="left" xs={12}>
              <Grid
                container
                spacing={{ xs: 2, sm: 2, md: 2 }}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                {connected ? (
                  <>
                    <Grid item align="left" xs={3.2}>
                      <Button variant="outlined" style={buttonStyle}>
                        Connected
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    {" "}
                    <Grid item align="left" xs={3.2}>
                      <Button variant="outlined" style={buttonStyle}>
                        Disconnected
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item align="left" xs={4}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#828282", fontSize: "12px" }}
                  >
                    {latest_date_received}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item align="left" xs={12}>
              <Grid
                container
                spacing={{ xs: 2, sm: 2, md: 2 }}
                direction="row"
                justifyContent="right"
                alignItems="center"
              >
                <Grid item align="left" xs={3.5}>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "5px",
                      border: "none",
                      color: "#795285",
                    }}
                    onClick={handleOpenScheduleDialogue}
                  >
                    Schedule
                  </Button>
                  <Dialog
                    BackdropProps={{ invisible: true }}
                    open={schedule_dialogue_open}
                    PaperProps={{
                      sx: {
                        width: "620px",
                        height: "960px",
                      },
                    }}
                  >
                    <DialogContent>
                      <SchedulePage group_id={group_id} />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="outlined"
                        style={{
                          borderRadius: "5px",
                          border: "none",
                          color: "#795285",
                        }}
                        onClick={handleCloseAllDialogues}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item align="left" xs={2.5}>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "5px",
                      border: "2px solid #795285",
                      color: "#795285",
                    }}
                    onClick={() => goToDevice(id)}
                  >
                    Open
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
  if (isGetGroupMetersSuccess) {
    return (
      <>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 1 }}
        sx={{ mt: 10, mb: 15 }}
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Grid item align="center" xs={12} sx={{ mb: 1 }}>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ mt: 10, mb: 15 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item align="center" xs={1} sx={{ mb: 1 }}>
              <IconButton
                aria-label="more options"
                size="small"
                sx={{ color: "#795285", marginLeft: "auto" }}
                onClick={handlePrevPage}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item align="center" xs={2} sx={{ mb: 1 }}>
              <Typography
                variant="h6"
                sx={{ color: "#795285", textAlign: "left", fontSize: "18px" }}
              >
                Demand Side Management
              </Typography>
            </Grid>
            <Grid item align="center" xs={1} sx={{ mb: 1 }}>
            
            </Grid>
            <Grid item align="right" xs={6} sx={{ mb: 1 }}>
              <Typography
                variant="h6"
                sx={{ color: "#666666", textAlign: "right", fontSize: "18px" }}
              >
                <SearchBar
                  setFilteredList={setFilteredList}
                  group_meters={group_meters}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
       <Grid
      container
    >
      <Grid item sm={2} md={1} lg={1}/>
      <Grid item xs={12} sm={8} md={10} lg={10.5}>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12 }}
          gap={2}
          sx={{ mt: -15, mb: 5, p: 2 }}
          direction="row"
          justifyContent={{ xs: "center", lg: "left" }}
          alignItems="center"
        >
        {filteredList?.map((meter) => (
          <MeterCard key={meter.id} {...meter} />
        ))}
  </Grid>
      </Grid>
      <Grid item sm={2} md={1} lg={0.5} />
    </Grid>

    </>
    );
  } else {
    return (
      <>
        {" "}
        <Box
          sx={{ display: "flex" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress sx={{ color: "#795285" }} />
        </Box>
      </>
    );
  }
}

function SearchBar({ setFilteredList, group_meters }) {
  const [filter, setFilter] = new useState("");
  const clearSearch = () => {
    setFilter("");
    setFilteredList(group_meters);
  };
  const filterBySearch = (event) => {
    const query = event.target.value;
    setFilter(query);
    var updatedList = [...group_meters];

    updatedList = updatedList.filter((item) => {
      return (
        item.ecd_description
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1 ||
        item.serial_number
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };
  return (
    <TextField
      id="outlined-basic"
      label="Search"
      variant="outlined"
      className="inputRounded"
      placeholder="Search..."
      value={filter || ""}
      onChange={(e) => filterBySearch(e)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRoundedIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <ClearIcon sx={{ color: "#795285" }} onClick={clearSearch} />
          </InputAdornment>
        ),
      }}
    />
  );
}
