//React
import * as React from "react";
//Material UI
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import Chart from "react-apexcharts";
import { useGetClientsQuery } from "../api/apiSlice";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ScheduleLS from "../LoadShiftSchedule/ScheduleLoadShift";

export default function LandingPage() {
  let navigate = useNavigate();
  const {
    data: clients,
    isSuccess: isGetClientsSuccess,
    isLoading: isGetClientLoading,
  } = useGetClientsQuery();
  const [filteredList, setFilteredList] = new useState(clients);

  const handlePrevPage = () => {
    navigate(-1);
  };

  const goToClientDeviceGroups = (client_id) => {
    navigate(`/client/${client_id}/metergroups/`);
  };

  React.useEffect(() => {
    setFilteredList(clients);
  }, [isGetClientsSuccess, clients]);

  const buttonStyle = {
    borderRadius: "100px",
    border: "none",
    color: "#FFFFFF",
    backgroundColor: "#795285",
    opacity: 1,
    textTransform: "none",
    zIndex: 1,
  };

  const ClientCard = ({
    id,
    client_name,
    active_pipelines,
    total_pipelines,
    active_percentage,
    meter_groups,
    active_schedules,
    total_current_power,
    max_power,
    min_power,
  }) => (
    <Grid item align="center" xs={12} sx={{ mb: 1 }}>
      <Card
        sx={{
          overflow: "auto",
          minWidth: 700,
          maxWidth: 700,
          maxHeight: 360,
          display: "flex",
          flexDirection: "column",
          background: "#DBDBDB",
          padding: "20px",
          borderRadius: "10px",
          "&:hover": {
            background: "#DBDBDB",
          },
        }}
        elevation={3}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{ color: "#666666", textAlign: "left", fontSize: "18px" }}
          >
            {client_name}
          </Typography>
        </div>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ mt: 10, mb: 15 }}
          direction="row"
          justifyContent="center"
          alignItems="top"
        >
          <Grid item xs={6} align="left">
            <Card
              sx={{
                maxWidth: 360,
                maxHeight: 300,
                minWidth: 360,
                minHeight: 300,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                background: "#F8F8F8",
                color: "#fff",
                position: "relative",
                // "&:hover": {
                //   color: "#fff",
                //   background: "#0e0f23",
                //   cursor: "pointer",
                // },
              }}
              elevation={0}
            >
              <Grid item align="center" xs={12}>
                <Chart
                  options={{
                    chart: {
                      type: "radialBar",
                    },
                    colors: ["#D755FF"],
                    plotOptions: {
                      radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        hollow: {
                          margin: 0,
                          size: "70%",
                          background: "transparent",
                        },
                        track: {
                          background: "#989898",
                        },
                        dataLabels: {
                          show: true,
                          name: {
                            show: false,
                          },
                          value: {
                            offsetY: -55,
                            fontSize: "22px",
                            color: "#666666",
                          },
                        },
                      },
                    },
                    fill: {
                      type: "gradient",
                      gradient: {
                        shade: "dark",
                        type: "horizontal",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#795285"],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                      },
                    },
                    stroke: {
                      lineCap: "round",
                    },
                    labels: ["Progress"],
                  }}
                  series={[active_percentage]}
                  type="radialBar"
                  height={320}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "115px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ color: "#6C6C6C", fontSize: "16px" }}
                  >
                    {active_pipelines} / {total_pipelines}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#2E93FA", fontSize: "14px" }}
                  >
                    Active Devices
                  </Typography>
                </div>
                <div
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    marginTop: "-85px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#323232", fontSize: "14px" }}
                  >
                    Current Power: {total_current_power} KW
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#323232", fontSize: "14px" }}
                  >
                    Maximum Power (24Hr): {max_power} KW
                  </Typography>
                  {/* <Typography
                    variant="body1"
                    sx={{ color: "#323232", fontSize: "14px" }}
                  >
                    Minimum Power: {min_power} KW
                  </Typography> */}
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    padding: "10px",
                    textAlign: "left",
                    marginBottom: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#6C6C6C",
                      fontSize: "14px",
                      wordWrap: "break-word",
                      maxWidth: "170px",
                    }}
                  >
                    Schedules:{" "}
                    {active_schedules
                      .map((schedule) => schedule.schedule_group_name)
                      .join(", ")}
                  </Typography>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "5px",
                      border: "2px solid #795285",
                      color: "#795285",
                    }}
                    onClick={() => goToClientDeviceGroups(id)}
                  >
                    Open
                  </Button>
                </div>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={0.5} />
          <Grid xs={5.5} align="right">
            {/* Start of Group Card */}
            {meter_groups.map((meterGroup) => (
              <MeterGroupCard key={meterGroup.id} {...meterGroup} />
            ))}
            {/* End of Card */}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );

  const MeterGroupCard = ({
    meter_group_name,
    total_devices,
    total_current_power,
    max_power,
    min_power,
  }) => (
    <Card
      sx={{
        maxWidth: 300,
        maxHeight: 70,
        minWidth: 300,
        minHeight: 70,
        background: "#F8F8F8",
        color: "#fff",
        margin: "8px",
      }}
      elevation={0}
    >
      <Grid
        container
        spacing={{ xs: 0.1, sm: 0.1, md: 0.1 }}
        sx={{ mt: 50, mb: 15 }}
        direction="row"
        justifyContent="center"
        alignItems="top"
      >
        <Grid item align="left" xs={12} sx={{ mb: 1 }}>
          <Typography
            variant="h6"
            sx={{
              color: "#6C6C6C",
              fontSize: "18px",
              maxWidth: "170px",
              textAlign: "left",
              marginTop: "10px",
              marginLeft: "10px",
            }}
          >
            {meter_group_name}
          </Typography>
        </Grid>
        <Grid item align="left" xs={4}>
          <Typography
            variant="body1"
            sx={{
              color: "#6C6C6C",
              fontSize: "14px",
              maxWidth: "170px",
              textAlign: "left",
              marginLeft: "12px",
            }}
          >
            {total_devices} Devices
          </Typography>
        </Grid>
        <Grid item align="left" xs={4}>
          <Typography
            variant="body1"
            sx={{
              color: "#6C6C6C",
              fontSize: "14px",
              maxWidth: "170px",
              textAlign: "center",
              marginLeft: "12px",
            }}
          >
            {total_current_power} KW
          </Typography>
        </Grid>
        <Grid item align="left" xs={4}>
          <Typography
            variant="body1"
            sx={{
              color: "#6C6C6C",
              fontSize: "14px",
              maxWidth: "170px",
              textAlign: "left",
              marginLeft: "12px",
              marginBottom: "10px",
            }}
          >
            {max_power} KW
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );

  if (isGetClientsSuccess) {
    //

    return (
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 1 }}
        sx={{ mt: 10, mb: 15 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item align="center" xs={12} sx={{ mb: 1 }}>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ mt: 10, mb: 15 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item align="center" xs={1} sx={{ mb: 1 }}>
              <IconButton
                aria-label="more options"
                size="small"
                sx={{ color: "#795285", marginLeft: "auto" }}
                onClick={handlePrevPage}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item align="center" xs={2} sx={{ mb: 1 }}>
              <Typography
                variant="h6"
                sx={{ color: "#795285", textAlign: "left", fontSize: "18px" }}
              >
                Demand Side Management
              </Typography>
            </Grid>
            <Grid item align="center" xs={1} sx={{ mb: 1 }}>
              {/* <Button variant="outlined" style={buttonStyle}>
                Add
                <AddIcon style={{ color: "#fff" }} />
              </Button> */}
            </Grid>
            <Grid item align="right" xs={6} sx={{ mb: 1 }}>
              <Typography
                variant="h6"
                sx={{ color: "#666666", textAlign: "right", fontSize: "18px" }}
              >
                <SearchBar
                  setFilteredList={setFilteredList}
                  clients={clients}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Generate a card per client */}
        {filteredList?.map((client) => (
          <ClientCard key={client.id} {...client} />
        ))}
        {/* End of Card */}
      </Grid>
    );
  } else {
    return (
      <Box
        sx={{ display: "flex" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress sx={{ color: "#795285" }} />
      </Box>
    );
  }
}

function SearchBar({ setFilteredList, clients }) {
  const [filter, setFilter] = new useState("");
  const clearSearch = () => {
    setFilter("");
    setFilteredList(clients);
  };
  const filterBySearch = (event) => {
    const query = event.target.value;
    setFilter(query);
    var updatedList = [...clients];

    updatedList = updatedList.filter((item) => {
      return (
        item.client_name
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };
  return (
    <TextField
      id="outlined-basic"
      label="Search"
      variant="outlined"
      className="inputRounded"
      placeholder="Search..."
      value={filter || ""}
      onChange={(e) => filterBySearch(e)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRoundedIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <ClearIcon sx={{ color: "#795285" }} onClick={clearSearch} />
          </InputAdornment>
        ),
      }}
    />
  );
}
