import React from 'react';
import { useSelector } from "react-redux";
import { getUserGroups } from "../../Features/Authentication/authSlice";

export function WithAnyPermissionGroupTrue(WrappedComponent, required_user_groups) {
    const user_groups = useSelector(getUserGroups);
    return class extends React.Component {
        render() {
            
            const permission_check = required_user_groups.some(value => user_groups.includes(value));

            if (permission_check) {
                return <WrappedComponent {...this.props} />;
            } else {
                return null;
            }
        }
    };
}