import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
const scheduleAdapter = createEntityAdapter();
const initialState = scheduleAdapter.getInitialState();

export const scheduleApiSlice = apiSlice.injectEndpoints({


  endpoints: (builder) => ({    
    //GET REQUESTS -------------------------------------------------------------
    getSettings: builder.query({
      query: () => "/dsm/settings/",
      providesTags: ["settings"],
    }),

    getScheduleLSList: builder.query({
      query: () => "/dsm/schedule-load-shift/",
      providesTags: ["schedulels"],
    }),

    getSchedulePSList: builder.query({
      query: () => "/dsm/schedule-peak-shave/",
      providesTags: ["scheduleps","addSchedulePS"],
    }),

   

    //PATCH REQUESTS -------------------------------------------------------------
    updateSettings: builder.mutation({
      query: (request) => ({
      url: `/dsm/settings/`,
      method: "PATCH",
      body: request.body,
      }),
      invalidatesTags: ["settings"],
    }),
    updateScheduleLS: builder.mutation({
      query: (request) => ({
      url: `/dsm/schedule-load-shift/update/${request.schedule_id}/`,
      method: "PATCH",
      body: request.body,
      }),
      invalidatesTags: ["schedulels"],
    }),
    updateBulkScheduleLS: builder.mutation({
      query: (request) => ({
      url: `/dsm/schedule-load-shift/bulk-update/`,
      method: "PATCH",
      body: request.body,
      }),
      invalidatesTags: ["schedulels"],
    }),

    updateBulkSchedulePS: builder.mutation({
      query: (request) => ({
      url: `/dsm/schedule-peak-shave/bulk-update/`,
      method: "PATCH",
      body: request.body,
      }),
      invalidatesTags: ["scheduleps"],
    }),
    
    updateSchedulePS: builder.mutation({
      query: (request) => ({
      url: `/dsm/schedule-peak-shave/update/${request.schedule_id}/`,
      method: "PATCH",
      body: request.body,
      }),
      invalidatesTags: ["scheduleps"],
    }),

      //POST REQUESTS -------------------------------------------------------------
        addScheduleLS: builder.mutation({
          query: (request) => ({
          url: `/dsm/schedule-load-shift/`,
          method: "POST",
          body: request.body,
          }),
          invalidatesTags: ["schedulels"],
        }),

        addSchedulePS: builder.mutation({
          query: (request) => ({
          url: `/dsm/schedule-peak-shave/`,
          method: "POST",
          body: request.body,
          }),
          invalidatesTags: ["scheduleps","addSchedulePS"],
        }),
      
        //DELETE

        deleteScheduleLS: builder.mutation({
          query: ({schedule_id}) => ({
          url: `/dsm/schedule-load-shift/update/${schedule_id}/`,
          method: "DELETE",
          
          }),
          invalidatesTags: ["schedulels"],
        }),
        deleteSchedulePS: builder.mutation({
          query: ({schedule_id}) => ({
          url: `/dsm/schedule-peak-shave/update/${schedule_id}/`,
          method: "DELETE",
          
          }),
          invalidatesTags: ["scheduleps"],
        }),
       
  }),
 
  
});

export const { 
//GET REQUESTS -------------------------------------------------------------
  useGetSettingsQuery,
  useGetScheduleLSListQuery,
  useGetSchedulePSListQuery,
  //  PATCH
  useUpdateSettingsMutation,
  useUpdateScheduleLSMutation,
  useUpdateBulkScheduleLSMutation,
  useUpdateBulkSchedulePSMutation,
  useDeleteScheduleLSMutation,
  useUpdateSchedulePSMutation,
  useDeleteSchedulePSMutation,
  //POST
  useAddScheduleLSMutation,
  useAddSchedulePSMutation
} = scheduleApiSlice;


