import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { setnavName, setnavDrawer } from "../Api/ResidentsSlice";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
export default function About() {
  const dispatch = useDispatch();
  dispatch(setnavName("FAQ"));
  dispatch(setnavDrawer(false));

  let { serial_number } = useParams();
  const navigate = useNavigate();

  const onboardingCompleted = localStorage.getItem("onboardingCompleted");

  React.useEffect(() => {
    if (!onboardingCompleted) {
      navigate(`/residents/${serial_number}/how-it-works`);
    }
  }, []);
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 15 }}
      >
        <Grid item xs={1} />
        <Grid item xs={1} align="left">
          <HelpOutlineIcon fontSize="large" sx={{ color: "#FDB940" }} />
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "left",
              ml: 1,
              fontWeight: "500",
            }}
          >
            Frequently Asked Questions
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={10} align="center" sx={{ mb: 4 }}>
          <FAQs
            question={"Why is it important that we use energy efficiently?"}
            answer={
              "South Africa is experiencing an energy crisis. Electricity demand often exceeds the amount of power that can be generated, leading to loadshedding. National power generation is particularly constrained during peak periods of high usage between 6:00 to 9:00 in the morning and in the evenings between 18:00 and 20:00 in the summer and 17:00 to 19:00 in the Winter months. The National Energy Regulator (NERSA) and Eskom initiated various programs to alleviate possible future electricity shortages and the immediate need to generate more power. To ensure that there are sufficient resources available for all the people of our country, now and in the future, we must manage our current resources efficiently and fairly. The good news is that by working together, we can all make a difference."
            }
          />

          <FAQs
            question={"What is load management?"}
            answer={
              "Residential Load Management is a system which allows Eskom and the Nelson Mandela Bay Municipality to manage the demand for electricity specifically during peak demand periods. It is an efficient means of managing the load on the supply network, while minimising any inconvenience to consumers. It is the quickest and most cost effective interventions to help alleviate the country’s energy crisis."
            }
          />

          <FAQs
            question={
              "I already have a geyser control device, why do I need a new device?"
            }
            answer={
              "The Metro has one of the biggest geyser control systems in the country, with about 110,000 Ripple Relays installed at residents’ homes. This system needs to be upgraded and improved in order to deal cope with loadshedding and new grid requirements. If you already have a geyser control device it will be replaced with a modern state of the art Tjommie device."
            }
          />

          <FAQs
            question={"How does the system operate?"}
            answer={
              "A Tjommie (a small hardware device) is installed next to or inside the electrical distribution panel in your home. The Tjommie and its smart algorithms can determine how warm or cold your geyser is and whether it could be switched off to reduce the Metro’s electricity demand (when needed), without resulting in cold water."
            }
          />

          <FAQs
            question={"Why is load management necessary?"}
            answer={
              "By reducing the peak demand, the existing electrical network capacity will be used more efficiently. This will reduce the likelihood of total blackouts – and if the load needs to be reduced due to overloading or technical failures in the national distribution system, it may be possible to avoid blackouts by only switching off the geysers."
            }
          />

          <FAQs
            question={
              "What influence will the system have on the hot water supply?"
            }
            answer={
              "Consumers should not experience any inconvenience, provided that the geyser is in good working order and has not been fitted with a timer device by the resident."
            }
          />

          <FAQs
            question={
              "What is the benefit to me as a Nelson Mandela Bay resident?"
            }
            answer={
              "Firstly, the improved management of the grid infrastructure contributes towards combatting loadshedding and should result in the containment of annual electricity price increases. Secondly, you will be able to download the Tjommie app by scanning the QR code located on the face of your Tjommie device. The app will provide you with useful information about the performance of your geyser and will alert you if your geyser is running sub-optimally and as such is using more electricity than what it should, as well as notify you if the Tjommie detects a potential geyser leak."
            }
          />

          <FAQs
            question={"Who pays for the Tjommie?"}
            answer={"This initiative is funded by Eskom."}
          />

          <FAQs
            question={"How long does the installation take?"}
            answer={"The installation takes 20 to 30 minutes."}
          />

          <FAQs
            question={"Will installation teams require access to my home?"}
            answer={
              "Residents are kindly requested to allow the Tjommie team to enter their homes in order to install Tjommies. The members of the team can be identified by means of the identity cards issued to them by Eskom and the Nelson Mandela Bay Municipality. Please do not allow anyone without this identity card to enter your home: Residents will receive a circular and a “confirmation of appointment” card approximately one week prior to the date of actual installation. An Artisan will follow up the installation to verify and certify the installation procedure. You can also independently verify the installers by going onto www.mytjom.com where you will find the list of qualified installers, together with their photos for identification."
            }
          />

          <FAQs
            question={"Is it necessary to install a Tjommie on solar heating?"}
            answer={
              "No – unless the solar heating system is also connected to the electricity supply, in which case a Tjommie will be installed."
            }
          />

          <FAQs
            question={"Do I still need a Tjommie if I have a Solar on my roof?"}
            answer={
              "Yes. The NMBM still needs to be able to reduce electricity consumption, and as such any reliance that there might be on the grid network during such times it is required, irrespective of whether you have a Solar system or not."
            }
          />

          <FAQs
            question={"Should I switch off the geyser manually?"}
            answer={
              "No. Geyser elements switch on and remain on until the water reaches a certain upper temperature limit, it then switches off and only switches back on when the temperature of the water reaches a lower limit. Switching a geyser off manually does not reduce the total electricity consumption of the geyser as the electricity usage that was avoided by doing so will simply again be required to heat a geyser that cools beyond its lower limit. It would be better to insulate the geyser with a geyser blanket and to insulate the outlet, as this would save considerably more energy by retaining the heat."
            }
          />

          <FAQs
            question={"Will electricity be wasted if water is re-heated?"}
            answer={
              "Normally the heating of the water in a geyser is controlled by a thermostat – which constantly switches the heating element on and off, depending on the volume of water that has to be heated. The Tjommie switches on and off far less frequently, with the result that no energy is wasted. Please note that the thermostat of your geyser will not be replaced or removed."
            }
          />

          <FAQs
            question={"Do I have a choice to participate?"}
            answer={
              "According to the law a Tjommie device cannot be refused. The National Regulation 773 states that “where an electric water heating facility is required – a [Municipality] should install a facility to remotely control the supply of electricity to any electric geyser.” The Nelson Mandela Bay Metro Electricity by-law in turn states that “At times of peak load, or in an emergency, or when, in the opinion of the Engineer, it is necessary for any reason to reduce the load on its electricity supply system, the Council may without notice interrupt and, for such period as the Engineer may deem necessary, discontinue the supply of electricity to the whole or part of any consumer’s installation. The Council may, at its own cost and expense, install upon the premises of the consumer such apparatus and equipment as may be necessary to give effect to any provision of this by-law."
            }
          />

          <FAQs
            question={"What happens if I refuse to have a Tjommie installed?"}
            answer={
              "The Metro could disconnect your electricity supply and also issue you with a fine."
            }
          />

          <FAQs
            question={"Where can I obtain further information?"}
            answer={
              "For further information, please go to www.mytjom.com or call the Project Call Centre at 0860 376 626 or the Municipality at 041 506 5595. You may also call this number should you want to arrange to have the unit installed at an alternative time, which is convenient for you."
            }
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
}

function FAQs({ question, answer }) {
  return (
    <>
      <Typography
        variant="body1"
        sx={{ fontSize: "18px", textAlign: "left", fontWeight: "500", mt: 1.5 }}
      >
        {question}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: "14px", textAlign: "left", fontWeight: "400", mt: 1 }}
      >
        {answer}
      </Typography>
    </>
  );
}
