import React from "react";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function EnergyGraphLanding({ graphData }) {
  const energyWithoutInterventionAvg = graphData.map(
    (data) => data.total_energy_without_intervention
  );
  const energyWithInterventionAvg = graphData.map(
    (data) => data.total_energy_with_intervention
  );

  const dates = graphData?.map((data) => data.interval);

  const series = [
    {
      name: "Energy Without Intervention",
      type: "line",
      data: energyWithoutInterventionAvg,
      color: "#333333",
    },
    {
      name: "Energy With Intervention",
      type: "line",
      data: energyWithInterventionAvg,
      color: "#FDB940",
    },
  ];

  const data = {
    series: series,
    options: {
      chart: {
        type: "line",
        stacked: false,
        animations: {
          enabled: false,
          dynamicAnimation: {
            enabled: false,
          },
        },
        background: '#ffffff', 
      },
      markers: {
        size: [0, 0, 0, 0, 0, 10, 10],
        fillOpacity: [0, 0, 0, 0, 0, 1, 0.4],
      },
      fill: {
        type: "solid",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: dates,
        type: "datetime",
        labels: {
          datetimeUTC: false,
      },
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: '#808080', 
          }
        }
      },
      tooltip: {
        shared: true,
      },
    },
  };

  return (
    <>
    <Grid item sx={{ width: "100%" }}>
     <Typography variant="h5" align="Left" sx={{ mb: 2 }}>
            Energy
        </Typography>
        </Grid>
    <Box borderRadius={4} bgcolor="white" p={2} width="100%">
      <Grid item sx={{ width: "100%" }}>
        <Chart options={data.options} series={data.series} height={400} />
      </Grid>
    </Box>
    </>
  );
}
