import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";

export const Nav = styled.nav`
  background: #fdb940;
  height: 100px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  position: fixed;
  flexdirection: column;
  top: 0;
`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: left;
  text-decoration: none;
  height: 100%;

  cursor: pointer;
  &.active {
    color: #4f4f4f;
  }
`;

export const ImgLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  position: absolute;
  right: 3%;
  cursor: pointer;
  &.active {
    color: #15cdfc;
  }
`;

export const DivLink = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  position: absolute;
  left: 3%;
  cursor: pointer;
  &.active {
    color: #15cdfc;
  }
  @media screen and (max-width: 600px) {
    left: 0;
  }
`;

export const LogoStyle = styled.div`
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  position: absolute;
  margin-top: 15px;
`;

export const Bars = styled(MenuIcon)`
  @media screen and (max-width: 320px) {
    display: block;
    position: absolute;
    left: 0;
    justify-content: left;
    cursor: pointer;
    justify-content: flex-start;
  }

  @media screen and (max-width: 600px) {
    justify-content: left;
    position: absolute;
    left: 0;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 220px;
  margin-right: 220px;

  white-space: nowrap;
  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media screen and (max-width: 600px) {
    display: none;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const NavBtn = styled.nav`
  align-items: center;
  display: flex;

  margin-right: 15px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
