import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { setnavName, setnavDrawer } from "../Api/ResidentsSlice";
import { useDispatch } from "react-redux";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Typography from "@mui/material/Typography";

export default function SupportLanding() {
  const dispatch = useDispatch();
  dispatch(setnavName("Support and Information"));
  dispatch(setnavDrawer(true));

  let { serial_number } = useParams();
  const navigate = useNavigate();

  const onboardingCompleted = localStorage.getItem("onboardingCompleted");

  React.useEffect(() => {
    if (!onboardingCompleted) {
      navigate(`/residents/${serial_number}/how-it-works`);
    }
  }, []);

  const gotToAbout = () => {
    navigate(`/residents/${serial_number}/support/faq`);
  };
  const goToTerms = () => {
    navigate(`/residents/${serial_number}/support/terms-and-conditions/`);
  };
  const goToOnboarding = () => {
    navigate(`/residents/${serial_number}/how-it-works`);
  };
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 15 }}
      >
        <Grid item xs={12} align="center" sx={{ mb: 2 }} onClick={gotToAbout}>
          <Grid container>
            <Grid item xs={2}>
              <FilterNoneIcon sx={{ color: "#727272" }} />
            </Grid>
            <Grid item xs={8} align="left">
              <Typography sx={{ fontSize: "14px" }}> FAQ </Typography>
            </Grid>
            <Grid item xs={0.5} />
            <Grid item xs={1.5} align="left">
              <KeyboardArrowRightIcon sx={{ color: "#727272" }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} align="center" sx={{ mb: 2 }} onClick={goToTerms}>
          <Grid container>
            <Grid item xs={2}>
              <FilterNoneIcon sx={{ color: "#727272" }} />
            </Grid>
            <Grid item xs={8} align="left">
              <Typography sx={{ fontSize: "14px" }}>
                {" "}
                Terms & Conditions{" "}
              </Typography>
            </Grid>
            <Grid item xs={0.5} />
            <Grid item xs={1.5} align="left">
              <KeyboardArrowRightIcon sx={{ color: "#727272" }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          align="center"
          sx={{ mb: 2 }}
          onClick={goToOnboarding}
        >
          <Grid container>
            <Grid item xs={2}>
              <FilterNoneIcon sx={{ color: "#727272" }} />
            </Grid>
            <Grid item xs={8} align="left">
              <Typography sx={{ fontSize: "14px" }}> Onboarding </Typography>
            </Grid>
            <Grid item xs={0.5} />
            <Grid item xs={1.5} align="left">
              <KeyboardArrowRightIcon sx={{ color: "#727272" }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
