//React
import * as React from "react";
import { useParams } from "react-router-dom";
//Material UI
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import { useGetMeterGroupSchedulesQuery } from "../api/apiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import EditSchedule from "./EditSchedule";
import AddSchedule from "./AddSchedule";
export default function SchedulePage({ group_id }) {
  let { client_id } = useParams();

  const { data: schedules, isSuccess: isGetMeterGroupSchedulesSuccess } =
    useGetMeterGroupSchedulesQuery({ client_id, group_id });

  const buttonStyle = {
    borderRadius: "100px",
    border: "none",
    color: "#fff",
    backgroundColor: "#952BFF",
    opacity: 1,
    textTransform: "none",
    zIndex: 2,
    fontSize: "12px",
  };

  const ScheduleCard = ({ schedule_off, schedule_on ,schedule, day}) => (
    <Grid
      container
      spacing={{ xs: 1, sm: 1, md: 1 }}
      direction="row"
      justifyContent="left"
      alignItems="center"
    >
       <Grid item align="center" xs={3}></Grid>
      <Grid item align="center" xs={3}>
        <Typography
          variant="body1"
          sx={{
            color: "#707071",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          {schedule_off} OFF
        </Typography>
      </Grid>
      <Grid item align="center" xs={3}>
        <Typography
          variant="body1"
          sx={{
            color: "#707071",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          {schedule_on} ON
        </Typography>
      </Grid>
      <EditSchedule schedule={schedule} day={day}/>
      <Grid item align="center" xs={3}></Grid>
    </Grid>
  );

  if (isGetMeterGroupSchedulesSuccess) {
    return (
      <>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 1 }}
          direction="row"
          justifyContent="left"
          alignItems="center"
        >
          <Grid item align="center" xs={12} sx={{ mb: 1 }}>
            <div
              style={{
                background: "#795285",
                padding: "10px",
                textAlign: "left",
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
              >
                {schedules[0].active_schedule_group_name}
              </Typography>
            </div>
          </Grid>
          <Grid item align="center" xs={12} sx={{ mb: 1 }}>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 1 }}
              direction="row"
              justifyContent="left"
              alignItems="center"
            >
              {/* <Grid item align="center" xs={0.5} sx={{ mb: 1 }}>
                <CircleIcon style={{ color: "#952BFF", fontSize: "12px" }} />
              </Grid> */}
              <Grid item align="center" xs={2} sx={{ mb: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#707071",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  LAST SWITCH:
                </Typography>
              </Grid>

              <Grid item align="center" xs={3} sx={{ mb: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#707071",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {schedules[0].last_switch}
                </Typography>
              </Grid>
              {/* <Grid item align="center" xs={0.5} sx={{ mb: 1 }}>
                <CircleIcon style={{ color: "#C50000", fontSize: "12px" }} />
              </Grid> */}
              <Grid item align="center" xs={3} sx={{ mb: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#707071",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  NEXT SWITCH
                </Typography>
              </Grid>
              <Grid item align="center" xs={3} sx={{ mb: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#707071",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {schedules[0].next_switch} ({schedules[0].time_until_switch})
                </Typography>
              </Grid>

              {/* <Grid item align="right" xs={6}>
                <Button variant="outlined" style={buttonStyle}>
                  EDIT SCHEDULE
                  <EditIcon style={{ color: "#fff" }} />
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item align="center" xs={12} sx={{ mb: 1 }}>
            <div
              style={{
                background: "#F8F8F8",
                padding: "10px",
                textAlign: "left",
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item align="center" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#795285",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    MONDAY
                  </Typography>
                </Grid>
                {/* Start of Card */}
                {schedules[0].monday_schedule.map((schedule) => (
                  <>
                  <ScheduleCard key={schedule.schedule_off} {...schedule} schedule={schedule} day={"Monday"}/>
                  </>
                ))}
                {/* End of Card */}
              </Grid>
            </div>
            <div
              style={{
                background: "#fff",
                padding: "10px",
                textAlign: "left",
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item align="center" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#795285",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    TUESDAY
                  </Typography>
                </Grid>
                {/* Start of Card */}
                {schedules[0].tuesday_schedule.map((schedule) => (
                  <ScheduleCard key={schedule.schedule_off} {...schedule} schedule={schedule} day={"Tuesday"} />
                ))}
                {/* End of Card */}
              </Grid>
            </div>
            <div
              style={{
                background: "#F8F8F8",
                padding: "10px",
                textAlign: "left",
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item align="center" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#795285",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    WEDNESDAY
                  </Typography>
                </Grid>
                {/* Start of Card */}
                {schedules[0].wednesday_schedule.map((schedule) => (
                  <ScheduleCard key={schedule.schedule_off} {...schedule}  schedule={schedule} day={"Wednesday"}/>
                ))}
                {/* End of Card */}
              </Grid>
            </div>
            <div
              style={{
                background: "#fff",
                padding: "10px",
                textAlign: "left",
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item align="center" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#795285",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    THURSDAY
                  </Typography>
                </Grid>
                {/* Start of Card */}
                {schedules[0].thursday_schedule.map((schedule) => (
                  <>
                  <ScheduleCard key={schedule.schedule_off} {...schedule} schedule={schedule} day={"Thursday"} />
                  </>
                ))}
                {/* End of Card */}
              </Grid>
            </div>
            <div
              style={{
                background: "#F8F8F8",
                padding: "10px",
                textAlign: "left",
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item align="center" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#795285",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    FRIDAY
                  </Typography>
                </Grid>
                {/* Start of Card */}
                {schedules[0].friday_schedule.map((schedule) => (
                  <ScheduleCard key={schedule.schedule_off} {...schedule} schedule={schedule} day={"Friday"} />
                ))}
                {/* End of Card */}
              </Grid>
            </div>
            <div
              style={{
                background: "#E3E3E3",
                padding: "10px",
                textAlign: "left",
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item align="center" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#795285",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    SATURDAY
                  </Typography>
                </Grid>
                {/* Start of Card */}
                {schedules[0].saturday_schedule.map((schedule) => (
                  <ScheduleCard key={schedule.schedule_off} {...schedule} schedule={schedule} day={"Saturday"}/>
                ))}
                {/* End of Card */}
              </Grid>
            </div>
            <div
              style={{
                background: "#F2E7F5",
                padding: "10px",
                textAlign: "left",
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item align="center" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#795285",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    SUNDAY
                  </Typography>
                </Grid>
                {/* Start of Card */}
                {schedules[0].sunday_schedule.map((schedule) => (
                  <ScheduleCard key={schedule.schedule_off} {...schedule} schedule={schedule} day={"Sunday"}/>
                ))}
                {/* End of Card */}
              </Grid>
            </div>
          </Grid>
          <AddSchedule schedule={schedules[0]}/>
        </Grid>
      </>
    );
  } else {
    return (
      <Box
        sx={{ display: "flex" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress sx={{ color: "#795285" }} />
      </Box>
    );
  }
}
