import DeviceSwitchingGraph from "./DeviceSwitchingGraph";
import EnergyGraphLanding from "./EnergyGraphLanding";
import CustomTextFields from "./CustomeTextFields";

import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  useGetEnergyGraphQuery,
  useGetEnergyValuesQuery,
} from "./municipalityAPISlice";
import { useGetDeviceSwitchingGraphDataQuery, useGetTjommieGraphDataQuery } from "../api/apiSlice"

import Card from '@mui/material/Card';
import Typography from "@mui/material/Typography";
import TjommieDemandGraph from "./TjommieDemandGraph";
const styles = {
  container: {
    margin: "0 auto",
    padding: "0 16px",
    maxWidth: "1700px",
    marginTop: 55,
  },
};


export default function MuncipipalityLandingPage() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [start_date, setstart_date] = React.useState(
    dayjs().subtract(30, "day")
  );
  const [end_date, setEnd_date] = React.useState(dayjs());

  const handleStartDateChange = (newDate) => {
    setstart_date(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setEnd_date(newDate);
  };

  const request = {
    start_date: start_date.format("YYYY-MM-DD"),
    end_date: end_date.format("YYYY-MM-DD"),
    user_timezone: userTimezone,
  };
  const { data: eneryGraphData, isSuccess: isSuccessEnergyGraph } = useGetEnergyGraphQuery(request);
  const { data: dataValuesTextField, isSuccess: isSuccessDataValuesTextField } =
    useGetEnergyValuesQuery(request);
    const { data: device_switching_data, isSuccess: isDeviceSwitchingDataSuccess } = useGetDeviceSwitchingGraphDataQuery(request);
    const { data: tjommie_data, isSuccess: isTjommieSuccess } = useGetTjommieGraphDataQuery(request);

  React.useEffect(() => {}, [start_date, end_date]);
  if (isSuccessEnergyGraph && isSuccessDataValuesTextField && isDeviceSwitchingDataSuccess && isTjommieSuccess) {
    return (
      <>
        <Grid style={styles.container}>
        <Card  sx={{ borderRadius: '12px', backgroundColor: '#fff', mb: 4 }}>
          <Grid
            sx={{  mb: 2, mt: 2, p:2}}
            container
            alignItems="center"
            justifyContent="center"
          >
            <DatePickerSection
              start_date={start_date}
              end_date={end_date}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
            />
            </Grid>
          </Card>
          <Card sx={{ borderRadius: '12px', backgroundColor: '#F6F6F6' }}>
            <Grid
              sx={{  mb: 2, mt: 2, p: 4}}
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <EnergyGraphLanding graphData={eneryGraphData.data} />
            </Grid>

            <Grid
              sx={{ mb: 4, mt: 2 }}
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <CustomTextFields values={dataValuesTextField} />
            </Grid>
          </Card>
          
          <DeviceSwitchingGraph graph_data={device_switching_data} />
          <TjommieDemandGraph graph_data={tjommie_data}/>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
}

function DatePickerSection({
  start_date,
  end_date,
  handleStartDateChange,
  handleEndDateChange,
}) {
  return (
    <Grid container alignItems="left">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["MobileDateTimePicker"]}>
          <Grid container alignItems="left">
            <Grid item xs={5} sx={{ mr: 2, ml: 2 }}>
              <Typography variant = "h6">Start date:</Typography>
              <DemoItem >
                <DatePicker
                  value={start_date}
                  onChange={handleStartDateChange}
                />
              </DemoItem>
            </Grid>
            <Grid item xs={5} sx={{ ml: 2 }}>
            <Typography variant = "h6">End date:</Typography>
              <DemoItem >
                <DatePicker value={end_date} onChange={handleEndDateChange} />
              </DemoItem>
            </Grid>
          </Grid>
        </DemoContainer>
      </LocalizationProvider>
    </Grid>
  );
}
