//React
import * as React from "react";
import { useParams } from "react-router-dom";
//Material UI
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Chart from "react-apexcharts";

import { useGetGraphQuery, useGetFilteredGraphMutation } from "../api/apiSlice";
import ModalDatePicker from "../../Global/other/ModalDatePicker";

export default function GraphPage({meter, register }) {
  let { client_id, group_id, meter_id } = useParams();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let additional_parameters = { client_id, group_id, meter_id, register };
  const { data: graph_data, isSuccess: isGetGraphDataSuccess } =
    useGetGraphQuery({
      userTimezone,
      client_id,
      group_id,
      meter_id,
      register,
    });

  const [
    GetFilteredGraphData,
    { data: filtered_graph_data, isSuccess: isGetFilteredGraphDataSuccess },
  ] = useGetFilteredGraphMutation();

  let data_list_to_render = [];
  if (isGetGraphDataSuccess && !isGetFilteredGraphDataSuccess) {
    data_list_to_render = graph_data;
  } else if (isGetFilteredGraphDataSuccess) {
    data_list_to_render = filtered_graph_data;
  }
  const buttonStyle = {
    borderRadius: "100px",
    border: "none",
    color: "#fff",
    backgroundColor: "#952BFF",
    opacity: 1,
    textTransform: "none",
    zIndex: 2,
    fontSize: "12px",
  };
  console.log(data_list_to_render)
  if (isGetGraphDataSuccess || isGetFilteredGraphDataSuccess) {
    return (
      <>
        <Grid item align="left" xs={12} sx={{ mb: 1 }}>
          <Chart
            options={{
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    xaxis: {
                      tickAmount: 8,
                    },
                  },
                },
                {
                  breakpoint: 1200,
                  options: {
                    xaxis: {
                      tickAmount: 12,
                    },
                  },
                },
              ],
              chart: {
                type: "area",
                height: 350,
                stacked: false,
                    animations: {
                        enabled: false,
                        dynamicAnimation: {
                            enabled: false,
                        },
                    },
                    toolbar: {
                      export: {
                        csv: {
                          filename: `Meter: ${meter?.meter_data?.serial_number} - ECD: ${meter?.meter_data?.ecd_description} - ${register}`,
                          columnDelimiter: ",",
                          headerCategory: "Date,Time",
                          headerValue: "Time",
                          dateFormatter(datetime) {
                            const dateObj = new Date(datetime);
                            const hours = dateObj.getHours().toString().padStart(2, "0");
                            const minutes = dateObj
                              .getMinutes()
                              .toString()
                              .padStart(2, "0");
                            const day = dateObj.getDate().toString().padStart(2, "0");
                            const month = (dateObj.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const year = dateObj.getFullYear().toString();
                            return `${year}-${month}-${day},${hours}:${minutes}`;
                          },
                        },
                      },
                    },
              },


              stroke: {
                curve: 'stepline',
              },
              tooltip: {
                enabled: true,
                x: {
                  show: true,
                  format: 'dd MMM HH:mm:ss',

              },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                // categories: data_list_to_render.dates,
                type: 'datetime',
                labels: {
                  datetimeUTC: false,
              },
                // title: {
                //   text: "Date", // Add X-axis label
                // },
              },
              yaxis: {
                decimalsInFloat: 0,
                title: {
                  text: data_list_to_render.register, // Add Y-axis label
                },
              },
              title: {
                text: data_list_to_render.register, // Add chart title
                align: "left", // Alignment of the title
              },
              // colors: ['#008FFB', 'red'],

            }}

            series={[
              {
                name: "Value",
                data: data_list_to_render.values,
                type: 'line',
              },
              {
                name: "Broker-Online-Offline",
                data: data_list_to_render.broker_on_off_data,
                type: 'area',
                color: 'red',
              }
            ]}
            // type="area"
            height={300}
          />
        </Grid>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10} align="left">
            <ModalDatePicker
              GetFilteredGraphData={GetFilteredGraphData}
              additional_parameters={additional_parameters}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>
    );
  } else {
    return (
      <Box
        sx={{ display: "flex" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress sx={{ color: "#795285" }} />
      </Box>
    );
  }
}
