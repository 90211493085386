import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs'; 
import 'dayjs/locale/en';
import DateRangeIcon from '@mui/icons-material/DateRange';

dayjs.locale('en');

function DatePickerDialog({GetFilteredGraphData, additional_parameters}) {

  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmSelection = () => {
    if (startDate && endDate) {
      let queryParams = {
        ...additional_parameters,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
      }
      
      GetFilteredGraphData({ queryParams: queryParams });
    } else {
      console.error('Please select both start and end dates.');
    }
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        style={{
          borderRadius: "5px",
          border: "2px solid #795285",
          color: "#795285",
        }}
        onClick={handleOpen}
        endIcon={<DateRangeIcon />}
      >
        Date Range
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Date Range</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
            <DialogContentText>
              Select a Start and End Date:
            </DialogContentText>
            <div style={{ marginTop: '16px',marginBottom: '16px' }}>
              <MobileDatePicker
                label="Start Date"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div style={{ marginBottom: '0px' }}>
              <MobileDatePicker
                label="End Date"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>
        </DialogContent>
        <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '16px', marginRight: '16px' }}>
          <Button
            onClick={handleConfirmSelection}
            variant="outlined"
            style={{
              borderRadius: "5px",
              border: "2px solid #795285",
              color: "#795285",
            }}
          >
            Confirm
          </Button>
        </div>
      </Dialog>
    </div>
  );
  
}

export default DatePickerDialog;
