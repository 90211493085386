//React
import * as React from "react";
import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//Material UI
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import Chart from "react-apexcharts";

import { useGetMeterQuery } from "../api/apiSlice";
import SchedulePage from "./SchedulePage";
import GraphPage from "./GraphPage";

export default function DevicePage() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let navigate = useNavigate();
  let { client_id, group_id, meter_id } = useParams();
  const { data: meter, isSuccess: isGetMetersSuccess } = useGetMeterQuery({
    userTimezone,
    client_id,
    group_id,
    meter_id,
  });
  const handlePrevPage = () => {
    navigate(-1);
  };
  const [schedule_dialogue_open, setScheduleDialogueOpen] = useState(false);

  const handleOpenScheduleDialogue = () => {
    setScheduleDialogueOpen(true);
  };

  const handleCloseAllDialogues = () => {
    setScheduleDialogueOpen(false);
    // setGraphDialogueOpen(false);
  };

  if (isGetMetersSuccess) {
    return (
      <>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ mt: 10 }}
          padding="40px"
          direction="row"
          justifyContent="left"
          alignItems="center"
        >
          <Grid item align="center" xs={12} sx={{ mb: 1 }}>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 1 }}
              sx={{ mt: 10, mb: 15 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item align="center" xs={1} sx={{ mb: 1 }}>
                <IconButton
                  aria-label="more options"
                  size="small"
                  sx={{ color: "#795285", marginLeft: "auto" }}
                  onClick={handlePrevPage}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item align="center" xs={2} sx={{ mb: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ color: "#795285", textAlign: "left", fontSize: "18px" }}
                >
                  Demand Side Management
                </Typography>
              </Grid>
              <Grid item align="center" xs={1} sx={{ mb: 1 }}></Grid>
              <Grid item align="right" xs={6} sx={{ mb: 1 }}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          gap={1}
          justifyContent="center"
          alignItems="center"
          sx={{ p: 5, mt: -15 }}
        >
          <Grid item xs={0.5} />

          <Grid item xs={4} align="left">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} align="center" sx={{ mb: 2 }}>
                <HeatingCoolingCard meter={meter} />
              </Grid>

              <Grid item xs={12} align="center" sx={{ mb: 2 }}>
                <Graph meter={meter} />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5} align="center" sx={{ mb: 2 }}>
                <Last meter={meter} />
              </Grid>
              <Grid item xs={5} align="center" sx={{ mb: 2 }}>
                <Next meter={meter} />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={12} align="center" sx={{ mb: 2 }}>
                <Description
                  meter={meter}
                  handleOpenScheduleDialogue={handleOpenScheduleDialogue}
                  schedule_dialogue_open={schedule_dialogue_open}
                  group_id={group_id}
                  handleCloseAllDialogues={handleCloseAllDialogues}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} align="left">
            <ECDCard
              meter={meter}

            />
          </Grid>
          <Grid item xs={3} align="left">
            <Alerts />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <Box
        sx={{ display: "flex" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress sx={{ color: "#795285" }} />
      </Box>
    );
  }
}

function HeatingCoolingCard({ meter }) {
  return (
    <Card
      sx={{
        minWidth: 378,
        maxWidth: 378,
        minHeight: 50,
        maxHeight: 50,
        display: "flex",
        flexDirection: "column",
        background: "#F8F8F8",
        padding: "0px",
        borderRadius: "10px",
      }}
      elevation={0}
    >
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 1, p: 2 }}
        sx={{ mt: 5, mb: 5 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {meter.meter_data.heating ? (
          <>
            <Grid item xs={4} align="left" />
            <Grid item align="left" xs={1} sx={{ mt: 1 }}>
              <WbSunnyIcon style={{ color: "#C23D4A" }} />
            </Grid>
            <Grid item align="left" xs={3} sx={{ mt: 1 }}>
              <Typography
                variant="body1"
                sx={{ color: "#000000", fontSize: "14px" }}
              >
                Heating
              </Typography>
            </Grid>
            <Grid item xs={4} align="left" />
          </>
        ) : (
          <>
            {" "}
            <Grid item xs={4} align="left" />
            <Grid item align="center" xs={1} sx={{ mt: 1 }}>
              <WbSunnyIcon style={{ color: "#2E93FA" }} />
            </Grid>
            <Grid item align="left" xs={3} sx={{ mt: 1 }}>
              <Typography
                variant="body1"
                sx={{ color: "#000000", fontSize: "14px" }}
              >
                Cooling
              </Typography>
            </Grid>
            <Grid item xs={4} align="left" />
          </>
        )}
      </Grid>
    </Card>
  );
}
function Graph({ meter }) {

  return (
    <Card
      sx={{
        minWidth: 378,
        maxWidth: 378,
        minHeight: 293,
        maxHeight: 293,
        display: "flex",
        flexDirection: "column",
        background: "#F8F8F8",
        padding: "0px",
        borderRadius: "10px",
      }}
      elevation={0}
    >
      <Chart
        options={{
          responsive: [
            {
              breakpoint: 600,
              options: {
                xaxis: {
                  tickAmount: 8,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                xaxis: {
                  tickAmount: 12,
                },
              },
            },
          ],
          chart: {
            type: "line",
            height: 350,
            stacked: false,
                    animations: {
                        enabled: false,
                        dynamicAnimation: {
                            enabled: false,
                        },
                    },
                    toolbar: {
                      export: {
                        csv: {
                          filename: `Meter: ${meter?.meter_data?.serial_number} - ECD: ${meter?.meter_data?.ecd_description} - ActivePower`,
                          columnDelimiter: ",",
                          headerCategory: "Date,Time",
                          headerValue: "Time",
                          dateFormatter(datetime) {
                            const dateObj = new Date(datetime);
                            const hours = dateObj.getHours().toString().padStart(2, "0");
                            const minutes = dateObj
                              .getMinutes()
                              .toString()
                              .padStart(2, "0");
                            const day = dateObj.getDate().toString().padStart(2, "0");
                            const month = (dateObj.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const year = dateObj.getFullYear().toString();
                            return `${year}-${month}-${day},${hours}:${minutes}`;
                          },
                        },
                      },
                    },
          },
          // fill: {
          //   type: "gradient",
          //   solid: {
          //     shadeIntensity: 1,
          //     opacityFrom: 0.7,
          //     opacityTo: 0.9,
          //     stops: [0, 90, 100],
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: true,
            x: {
              show: true,
              format: 'dd MMM HH:mm',
          },
        },
          xaxis: {
            categories: meter.graph_data.timestamp,
            type: "datetime",
            labels: {
              datetimeUTC: false,
          },
          },
          yaxis: {
            decimalsInFloat: 0,
        },


        }}
        series={[
          {
            name: "Value",
            data: meter.graph_data.total_active_energy,
          },
        ]}
        type="line"
        height={300}
      />
    </Card>
  );
}
function Last({ meter }) {
  return (
    <Card
      sx={{
        minWidth: 180,
        maxWidth: 180,
        minHeight: 32,
        maxHeight: 52,
        display: "flex",
        flexDirection: "column",
        background: "#F8F8F8",
        padding: "0px",
        borderRadius: "10px",
      }}
      elevation={0}
    >
      <Typography variant="body1" sx={{ color: "#7C7A7ADE", fontSize: "16px" }}>
        Last: {meter.schedule_data.last_switch}
      </Typography>
    </Card>
  );
}
function Next({ meter }) {
  return (
    <Card
      sx={{
        minWidth: 180,
        maxWidth: 180,
        minHeight: 32,
        maxHeight: 52,
        display: "flex",
        flexDirection: "column",
        background: "#F8F8F8",
        padding: "0px",
        borderRadius: "10px",
      }}
      elevation={0}
    >
      <Grid item align="center" xs={12} sx={{ mb: 1 }}>
        <Typography
          variant="body1"
          sx={{ color: "#7C7A7ADE", fontSize: "16px" }}
        >
          Next: {meter.schedule_data.next_switch}
        </Typography>
      </Grid>
    </Card>
  );
}
function Description({
  meter,
  handleOpenScheduleDialogue,
  schedule_dialogue_open,
  group_id,
  handleCloseAllDialogues,
}) {
  return (
    <Card
      sx={{
        minWidth: 378,
        maxWidth: 378,
        minHeight: 92,
        maxHeight: 92,
        display: "flex",
        flexDirection: "column",
        background: "#F8F8F8",
        padding: "0px",
        borderRadius: "10px",
      }}
      elevation={0}
    >
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 1 }}
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Grid item align="center" xs={2}>
          <CalendarTodayIcon style={{ color: "#795285" }} />
        </Grid>
        <Grid item align="center" xs={3}>
          <Typography
            variant="body1"
            sx={{ color: "#795285", fontSize: "20px" }}
          >
            {meter.schedule_data.active_schedule_group_name}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#795285", fontSize: "12px" }}
          >
            {meter.schedule_data.group_description}
          </Typography>
        </Grid>
        <Grid item align="right" xs={6}>
          <IconButton
            aria-label="more options"
            size="small"
            sx={{ color: "#795285", marginLeft: "auto" }}
            onClick={(event) => handleOpenScheduleDialogue(event)}
          >
            <ChevronRightIcon />
          </IconButton>
          <Dialog
            BackdropProps={{ invisible: true }}
            open={schedule_dialogue_open}
            PaperProps={{
              sx: {
                width: "620px",
                height: "960px",
              },
            }}
          >
            <DialogContent>
              <SchedulePage group_id={group_id} />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                style={{
                  borderRadius: "5px",
                  border: "none",
                  color: "#795285",
                }}
                onClick={handleCloseAllDialogues}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Card>
  );
}

function ECDCard({
  meter,
}) {
  const RegisterCard = ({
    register,
    latest_date_received,
  }) => {
    const [graph_dialogue_open, setGraphDialogueOpen] = useState(false);
    const handleOpenGraphDialogue = (event) => {
      event.stopPropagation();
      setGraphDialogueOpen(true);
    };

    const handleCloseAllDialogues = () => {
      setGraphDialogueOpen(false);
    };
    return (
      <Grid item align="center" xs={12}>
        <Card
          sx={{
            minWidth: 358,
            maxWidth: 358,
            minHeight: 79,
            maxHeight: 79,
            display: "flex",
            flexDirection: "column",
            background: "#F0F0F0",
            borderRadius: "10px",
            padding: 2,
          }}
          elevation={0}
        >
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ mt: 1, mb: 5 }}
            direction="row"
            justifyContent="left"
            alignItems="center"
          >
            <Grid item align="left" xs={12}>
              <Typography
                variant="body1"
                sx={{ color: "#393939", fontSize: "18px", fontWeight: "bold" }}
              >
                {register}
              </Typography>
            </Grid>
            <Grid item align="left" xs={10}>
              <Typography
                variant="body1"
                sx={{ color: "#393939DE", fontSize: "12px" }}
              >
                {latest_date_received}
              </Typography>
            </Grid>
            <Grid item align="right" xs={2}>
              <IconButton
                aria-label="more options"
                size="small"
                sx={{ color: "#795285", marginLeft: "auto" }}
                onClick={(event) => handleOpenGraphDialogue(event)}
              >
                <ChevronRightIcon />
              </IconButton>
              <Dialog
                BackdropProps={{ invisible: true }}
                open={graph_dialogue_open}
                PaperProps={{
                  sx: {
                    width: "620px",
                    height: "960px",
                  },
                }}
              >
                <DialogContent>
                  <GraphPage meter={meter} register={register} />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "5px",
                      border: "none",
                      color: "#795285",
                    }}
                    onClick={handleCloseAllDialogues}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };
  return (
    <Card
      sx={{
        overflow: "auto",
        minWidth: 378,
        maxWidth: 378,
        minHeight: 502,
        maxHeight: 502,
        display: "flex",
        flexDirection: "column",
        background: "#F8F8F8",
        padding: "0px",
        borderRadius: "10px",
        padding: 4,
      }}
      elevation={0}
    >
      <Typography
        variant="body1"
        sx={{ color: "#795285", fontSize: "20px", fontWeight: "bold" }}
      >
        {meter.meter_data.ecd_description}
      </Typography>
      <Grid container sx={{ mt: 2, mb: 1 }}>
        <Grid item xs={4}>
          <Typography
            variant="body1"
            sx={{ color: "#646464", fontSize: "14px" }}
          >
            {meter.meter_data.serial_number}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body1"
            sx={{ color: "#646464", fontSize: "14px" }}
          >
            {meter.meter_data.uuid}
          </Typography>
        </Grid>
      </Grid>
      {/* Start of Card */}
      {meter.register_data.map((register, index) => (
        <RegisterCard
          key={index}
          register={register}
          latest_date_received={meter.meter_data.latest_date_received}

        />
      ))}
      {/* End of Card */}
    </Card>
  );
}

function Alerts() {
  return (
    <Card
      sx={{
        minWidth: 80,
        maxWidth: 120,
        minHeight: 30,
        maxHeight: 30,
        display: "flex",
        flexDirection: "column",
        background: "#F8F8F8",
        padding: "0px",
        borderRadius: "10px",
        padding: 4,
      }}
      elevation={0}
    >
      <Typography> No active alerts</Typography>
    </Card>
  );
}
