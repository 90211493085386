import * as React from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { setCurrentStep } from "./Stepper/stepperSlice";
import { useDispatch } from "react-redux";
import NavbarStepper from "./Stepper/NavBarStepper";
import { useParams } from "react-router-dom";
import { useEditSmartNodeMutation, useGetIMSSmartnodeQuery } from "../api/apiSlice";
import { useEffect } from "react";
import { showSuccess, showError } from "../../Global/notifications/notificationsSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";

export default function QRCodeScanPage() {
  const [
    editSmartNode, 
    {
      isSuccess: isEditSmartNodeSuccess,
      isError: isEditSmartNodeError,
      error: editSmartNodeError
    }
  ] = useEditSmartNodeMutation();



  const [qrCodeData, setQRCodeData] = React.useState(null);
  const [isNodeExists, setIsNodeExists] = React.useState(null);
  const [request, setRequest] = React.useState(null);

  let { job_card_reference_id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();


 dispatch(setCurrentStep(1));


  const { data: smartnode, isSuccess: isGetSmartnodeSuccess, isError: isGetSmartnodeError, error: getSmartNodeError } = useGetIMSSmartnodeQuery(
    qrCodeData ? { serial_number: qrCodeData.serial_number, imsi_number: qrCodeData.imsi_number } : skipToken
  );

  useEffect(() => {
    if (isGetSmartnodeError) {
      console.log(getSmartNodeError)
      dispatch(showError(getSmartNodeError.data.error));
    }
   
  }, [isGetSmartnodeError, smartnode]);

  useEffect(() => {
    if (isGetSmartnodeSuccess && qrCodeData ) {
      const { serial_number, imsi_number } = qrCodeData;
      const body = {
        state: "BEFWA",
        serial_number: serial_number,
        reference_id: job_card_reference_id,
        imsi_number: imsi_number,
        status: "In Field",
      };
      editSmartNode({ body: body });
      setIsNodeExists(true)
    } 
    else {
      setIsNodeExists(false)
    }
  }, [editSmartNode, job_card_reference_id, isGetSmartnodeSuccess, qrCodeData]);

  useEffect(() => {
    if (isEditSmartNodeSuccess) {
      setIsNodeExists(true)
      navigate(`/installer/${job_card_reference_id}/${qrCodeData.serial_number}/${qrCodeData.imsi_number}/work_area_before_install`);
    }
   
  }, [isEditSmartNodeSuccess, navigate, job_card_reference_id, qrCodeData, ]);

  useEffect(() => {
    if (isEditSmartNodeError) {
      dispatch(showError(editSmartNodeError.data.error_message));
    }
   
  }, [isEditSmartNodeError, editSmartNodeError, dispatch, isNodeExists]);

  return (
    <>
      <NavbarStepper />
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          align: "center",
          justifyContent: "center",
          marginTop: "-60px",
        }}
      >
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (result) {
              const qr_code_data = extractDataFromUrl(result.text);
              setQRCodeData(qr_code_data);
            }
            if (error) {
              console.info(error);
            }
          }}
        />
      </div>
    </>
  );
}

function extractDataFromUrl(url) {
  const urlParts = url.split("/");
  if (urlParts.length < 7) {
    throw new Error(`Invalid URL format: Current Part length${urlParts.length}`);
  }
  return {
    serial_number: urlParts[5],
    imsi_number: urlParts[6],
  };
}
