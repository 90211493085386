import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import NfcIcon from '@mui/icons-material/Nfc';
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { ReactComponent as BottomLogo } from "./BottomLogo.svg";
import { Nav, Bars, NavLink, DivLink, LogoStyle } from "./NavBarElements";
import { ReactComponent as Logo } from "./Logo.svg";

import { useDispatch, useSelector } from "react-redux";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getnavName, getnavDrawer } from "../Api/ResidentsSlice";

import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useParams } from "react-router-dom";

const drawerWidth = 240;

export default function ResidentsNavbar(props) {
  let { serial_number } = useParams();

  const navigate = useNavigate();
  const navbarName = useSelector(getnavName);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElLogout, setanchorElLogout] = React.useState(null);

  const handleBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleCloseDrawer = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <>
      <Box
        onClick={handleCloseDrawer}
        sx={{ textAlign: "left", background: "#FFCC72" }}
      >
        <Box
          sx={{
            bgcolor: "#FFCC72",
            height: 50,
            width: 100,
            maxHeight: { xs: 50, md: 100 },
            maxWidth: { xs: 100, md: 200 },
          }}
        />

        <nav aria-label="main mailbox folders">
          <List>
            <ListItem
              style={{ color: "#000" }}
              component={NavLink}
              to={`/residents/${serial_number}/`}
              reloadDocument
              sx={{
                "&:hover , &:focus": {
                  backgroundColor: "#FDB940",
                },
              }}
            >
              <ListItemButton 
           >
                <ListItemIcon sx={{mr:-2}}>
                  <NfcIcon sx={{ color: "#000" }} />
                </ListItemIcon>
                <ListItemText primary={`Device ${serial_number}`} />
              </ListItemButton>
            </ListItem>
            <Divider />

            <ListItem
              style={{ color: "#000" }}
              component={NavLink}
              to={`/residents/${serial_number}/support`}
              reloadDocument
              sx={{
                "&:hover , &:focus": {
                  backgroundColor: "#FDB940",
                },
              }}
            >
              <ListItemButton  >
                <ListItemIcon sx={{mr:-2}}>
                  <HelpOutlineIcon sx={{ color: "#000" }} />
                </ListItemIcon>
                <ListItemText primary="Support and Information" />
              </ListItemButton>
            </ListItem>
            <Divider />

            <Divider />
          </List>
        </nav>
        <LineBreaks />

        <LineBreaks />
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          mb: -2,
          background: "#fff",
        }}
      >
        <BottomLogo />
      </Box>
    </>
  );

  return (
    <>
      <Box sx={{ mb: "6rem" }}>
        <Nav>
          {navbarName == "" ? (
            <LogoStyle>
              {" "}
              <Logo sx={{ mt: 10 }} />{" "}
            </LogoStyle>
          ) : (
            <Typography
              sx={{
                flexGrow: 14,
                fontFamily: "Roboto",
                fontWeight: "600",
                background: "#000",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                ml: 10,
              }}
              textAlign="Left"
            >
              {navbarName}
            </Typography>
          )}
          <DivLink>
            {useSelector(getnavDrawer) ? (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar-left"
                aria-haspopup="true"
                color="inherit"
                onClick={handleDrawerToggle}
                justifycontent="flex-start"
              >
                <Bars sx={{ color: "#000" }} />
              </IconButton>
            ) : (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar-left"
                aria-haspopup="true"
                color="inherit"
                onClick={handleBack}
                justifycontent="flex-start"
              >
                <ArrowBackIcon sx={{ color: "#000" }} />
              </IconButton>
            )}
          </DivLink>
        </Nav>
        <Box component="nav">
          <Drawer
            open={mobileOpen}
            onClose={handleCloseDrawer}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                bgcolor: "#FFCC72",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

function LineBreaks() {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
