import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ReactComponent as Tjommie } from "../Resources/Tjommie.svg";
import { ReactComponent as AddToHome } from "../Resources/AddToHome.svg";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
export default function AddToHomePage() {
  let { serial_number } = useParams();
  const navigate = useNavigate();

  const goToNext = () => {
    navigate(`/residents/${serial_number}/terms-and-conditions`);
  };
  const goToPrevious = () => {
    navigate(`/residents/${serial_number}/whatsapp`);
  };
  const onboardingCompleted = localStorage.getItem("onboardingCompleted");

  const skipOnboarding = () => {
    if (onboardingCompleted == "true") {
      navigate(`/residents/${serial_number}/support`);
    } else {
      navigate(`/residents/${serial_number}/terms-and-conditions`);
    }
  };

  return (
    <Box bgcolor="#636569" minHeight="100vh">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      >
        <Grid item xs={11} align="center">
          <Grid container>
            <Grid item xs={2} align="left" sx={{ mt: 2 }}>
              <ClearIcon sx={{ color: "white" }} onClick={skipOnboarding} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      />

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      >
        <Grid item xs={12} sx={{ mt: 2, mb: 14 }} align="center">
          <Tjommie width="45%" />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="61vh"
      >
        <Grid item xs={11} align="center">
          <Grid container>
            <Grid item xs={10} sx={{ mt: -15 }} align="left">
              <AddToHome width="120%" />
            </Grid>

            <Grid item xs={2} align="right"></Grid>
            <Grid item xs={0.5} />
            <Grid item xs={10} align="left">
              <Typography
                variant="h5"
                sx={{
                  color: "#fff",
                  fontSize: "30px",
                  textAlign: "left",
                
                }}
              >
                ADD <span style={{ color: "#FDB940" }}>TJOMMIE</span> TO YOUR HOME
                SCREEN
              </Typography>
            </Grid>
            <Grid item xs={1.5} />
            <Grid item xs={0.5} />
            <Grid item xs={11}  align="center">
              <ParagraphedText />
            </Grid>
            <Grid item xs={12} sx={{ mb: -1 }} align="center"></Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="10vh"
      >
        <Grid item xs={5} align="left">
          <Button fullWidth onClick={goToPrevious} style={{ color: "#FDB940" }}>
            Previous
          </Button>
        </Grid>
        <Grid item xs={6} align="left">
          <Button
            fullWidth
            onClick={goToNext}
            style={{ color: "#000", backgroundColor: "#FDB940" }}
          >
            Next
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      />
    </Box>
  );
}

function ParagraphedText() {
  return (
    <>
      <p
        style={{
          fontSize: "16px",
          color: "white",
          textAlign: "left",
          lineHeight: "1.5",
        }}
      >
        For the best experience add the Tjommie app to your home screen when
        prompted or click share and add to your home screen.
        <br />
      </p>
    </>
  );
}
