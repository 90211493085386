import * as React from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SvgFile } from "./Stepper/logo.svg";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import styled from 'styled-components';
import { useEffect } from "react";
import { useGetTestingPipelineQuery } from "../api/apiSlice";
import {  useGetIMSSmartnodeQuery, } from "../api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { showSuccess, showError } from "../../Global/notifications/notificationsSlice";
import { useDispatch } from "react-redux";

export default function PreDispatchConnectionTestPage() {
  const dispatch = useDispatch();

  const [qrCodeData, setQRCodeData] = React.useState({ "serial_number": "NONE" });
  const { data: smartnode, isSuccess: isGetSmartnodeSuccess, isError: isGetSmartnodeError, error: getSmartNodeError } = useGetIMSSmartnodeQuery(
    qrCodeData ? { serial_number: qrCodeData.serial_number, imsi_number: qrCodeData.imsi_number, url:"test" } : skipToken
  );
  const { data, isSuccess, refetch } = useGetTestingPipelineQuery(
    { serial_number: qrCodeData.serial_number }
  );
  useEffect(() => {
    if (qrCodeData.serial_number != "NONE" && qrCodeData.imsi_number != "NONE" && isGetSmartnodeError) {
      dispatch(showError(getSmartNodeError.data.error));
    }
   
  }, [isGetSmartnodeError, smartnode]);

  useEffect(() => {
    if (isGetSmartnodeSuccess ) {
      dispatch(showSuccess("Scan Successful"));
          
    }
  }, [qrCodeData, isGetSmartnodeSuccess]);
  useEffect(() => {
    { refetch();}
  }, [isGetSmartnodeSuccess]);
  if (isSuccess ) {
    return (
      <>
        <Navbar />
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            align: "center",
            justifyContent: "center",
            marginTop: "-60px",
          }}
        >
          <Grid container alignItems="center">

            <Grid item xs={1} />
            <Grid item xs={10} align="center">
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={(result, error) => {
                  if (result) {
                    const qr_code_data = extractDataFromUrl(result.text);
                    setQRCodeData(qr_code_data);
                  }
                  if (error) {
                    console.info(error);
                  }
                }}
              />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={1} />
            <Grid item xs={10} align="left">
              <Typography sx={{ mt: 1 }}>SN: {isGetSmartnodeSuccess? qrCodeData.serial_number : "NONE"}</Typography>
              <ConnectionStatus connected={data.connected} />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={1} />
            <Grid item xs={10} align="left">
              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() => refetch()}
                endIcon={<RefreshIcon />}
              >
                Test Connection
              </Button>
            </Grid>
            <Grid item xs={1} />

          </Grid>

        </div>
      </>
    );
  } else {
    return <></>
  }

}

function extractDataFromUrl(url) {
  const urlParts = url.split("/");
  if (urlParts.length < 7) {
    throw new Error(`Invalid URL format: Current Part length${urlParts.length}`);
  }
  return {
    serial_number: urlParts[5],
    imsi_number: urlParts[6],
  };
}

function Navbar() {

  const Nav = styled.nav`
  background: #2E2E2E;
  height: 80px;
  width: 100%;
  padding: 5;
  position: fixed;
  top: 0;
  z-index: 1000;
  `;

  const headingStyle = {
    position: "absolute",
    left: "35%",
    alignItems: "center",
    justifyContent: "center",
    color: "#FDB940",
    fontSize: "16px",
    fontFamily: ["Roboto"],
  };

  return (
    <Box sx={{ mb: 15, background: "#FDB940" }}>
      <Nav>
        <br />
        <Grid container alignItems="center" spacing={0.5} sx={{ mt: -2 }}>
          <Grid item xs={2} align="center">
            <CancelJobButton />
          </Grid>
          <Grid item xs={8} align="center">
            <div style={headingStyle}>
              Test Connection
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <SvgFile />
          </Grid>
        </Grid>
      </Nav>
    </Box>
  );
}

function CancelJobButton() {

  let navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };


  return (
    <>
      <ClearIcon
        variant="contained"
        color="error"
        sx={{ mt: 1 }}
        onClick={handleBack}
      />
    </>
  );

}

function ConnectionStatus({ connected }) {
  if (connected) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography>Connected</Typography>
        <CircleIcon sx={{ color: "#119B05" }} />
      </Stack>
    );
  } else if (!connected) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography>Not Connected </Typography>
        <CircleIcon sx={{ color: "red" }} />
      </Stack>
    );
  } else {
    return <Typography>Unknown</Typography>;
  }
}
