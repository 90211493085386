//React
import * as React from "react";
//Material UI
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TuneIcon from '@mui/icons-material/Tune';
import BoltIcon from '@mui/icons-material/Bolt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
//Routing
import { useNavigate } from "react-router-dom";
//Components
import { Nav, NavLink, DivLink } from "./NavBarElements";
import { logout } from "../../Features/Authentication/authSlice";
//Redux
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

const drawerWidth = 240;

export default function Navbar(props) {
  <Helmet>
    <style>{"body { background: #fff; }"}</style>
  </Helmet>;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElLogout, setanchorElLogout] = React.useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleCloseDrawer = () => {
    setMobileOpen(false);
  };
  const handleMenuLogout = (event) => {
    setanchorElLogout(event.currentTarget);
  };

  const handleCloseLogout = () => {
    setanchorElLogout(null);
  };

  const handleLogout = () => {
    setanchorElLogout(null);
    dispatch(logout());
  };

  // #171837

  const drawer = (
    <Box
      onClick={handleCloseDrawer}
      sx={{ textAlign: "left", background: "#fff" }}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          height: 50,
          width: 100,
          maxHeight: { xs: 50, md: 100 },
          maxWidth: { xs: 100, md: 200 },
        }}
      />

      <nav aria-label="main mailbox folders">
        <List>

          <ListItem
            style={{ color: "#3F8CC4" }}
            component={NavLink}
            to="/rawdata/"
            reloadDocument
          >
            <ListItemButton>
              <ListItemIcon>
                <SsidChartIcon sx={{ color: "#795285" }} />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>

          <ListItem
            style={{ color: "#3F8CC4" }}
            component={NavLink}
            to="/"
            reloadDocument
          >
            <ListItemButton>
              <ListItemIcon>
                <TuneIcon sx={{ color: "#795285" }} />
              </ListItemIcon>
              <ListItemText primary="Operator" />
            </ListItemButton>
          </ListItem>

          <ListItem
            style={{ color: "#3F8CC4" }}
            component={NavLink}
            to="/municipality/"
            reloadDocument
          >
            <ListItemButton>
              <ListItemIcon>
                <BoltIcon sx={{ color: "#795285" }} />
              </ListItemIcon>
              <ListItemText primary="Municipality" />
            </ListItemButton>
          </ListItem>

          <ListItem
            style={{ color: "#3F8CC4" }}
            component={NavLink}
            to="/installer/"
            reloadDocument
          >
            <ListItemButton>
              <ListItemIcon>
                <LocalShippingIcon sx={{ color: "#795285" }} />
              </ListItemIcon>
              <ListItemText primary="Installer" />
            </ListItemButton>
          </ListItem>

        </List>
      </nav>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ mb: 15, background: "#fff" }}>
        <Nav>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontFamily: "Arial",
              marginTop: "25px",
              background: "#fff",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              ml: 17, // Adjust the left margin for the title
            }}
          >
            Tjommie DSM
          </Typography>
          <IconButton
            size="large"
            aria-label="search"
            color="#fff"
            sx={{
              marginLeft: "auto", // Push the search icon to the right
              marginRight: 3, // Add margin to the right of the search icon
            }}
          >
            <SearchIcon sx={{ color: "#fff" }} />
          </IconButton>
          <DivLink>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar-right"
              aria-haspopup="true"
              color="#fff"
              onClick={handleDrawerToggle}
              sx={{
                marginRight: 10, // Add margin to the right to create space
              }}
            >
              <MenuIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Menu
              id="menu-appbar-right"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElLogout)}
              onClose={handleCloseLogout}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </DivLink>
        </Nav>
        <Box component="nav">
          <Drawer
            container={container}
            open={mobileOpen}
            onClose={handleCloseDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            PaperProps={{
              sx: {
                bgcolor: "#fff",
                color: "#fff",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}
