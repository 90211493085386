import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ReactComponent as Tjommie } from "../Resources/Tjommie.svg";
import { ReactComponent as QR } from "../Resources/QR.svg";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useGetDeviceDetailsQuery } from "../Api/residentsAPISlice";

export default function DeviceDetailsPage() {
  const [checkedInfo, setCheckedInfo] = React.useState(false);
  const [checkedTerms, setCheckedTerms] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(true);

  let { serial_number } = useParams();
  const navigate = useNavigate();
  const onboardingCompleted = localStorage.getItem("onboardingCompleted");
  const {
    data: residentsDetails,
    isLoading: isresidentsDetailsStatusLoading,
    isError: isresidentsStatusDetailsError,
  } = useGetDeviceDetailsQuery({ serial_number });
  const goToDone = () => {
    localStorage.setItem("onboardingCompleted", "true");
    navigate(`/residents/${serial_number}/`);
  };
  const goToPrevious = () => {
    navigate(`/residents/${serial_number}/terms-and-conditions`);
  };

  React.useEffect(() => {
    if (checkedTerms) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [checkedInfo, checkedTerms]);

  React.useEffect(() => {
    if (onboardingCompleted) {
      setCheckedInfo(true);
      setCheckedTerms(true);
    }
  }, []);

  if (isresidentsDetailsStatusLoading || isresidentsStatusDetailsError) {
    return <></>;
  } else {
    return (
      <Box bgcolor="#636569" minHeight="100vh">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="10vh"
       
      />

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
       
      >
        <Grid item xs={12} sx={{ mt: 2, mb: 14 }} align="center">
          <Tjommie width="45%" />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="4vh"
      />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="57vh"
        sx={{mb:-1}}
      >
          <Grid item xs={11} align="center">
            <Grid container>
             
              <Grid item xs={12} align="center">
                <DeviceDetails
                  serial_number={serial_number}
                  residentsDetails={residentsDetails}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 4 }} align="left">
                <CheckBoxes
                  setCheckedInfo={setCheckedInfo}
                  setCheckedTerms={setCheckedTerms}
                  checkedInfo={checkedInfo}
                  checkedTerms={checkedTerms}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          bgcolor="#636569"
          height="10vh"
        >
          <Grid item xs={5} align="left">
            <Button
              fullWidth
              onClick={goToPrevious}
              sx={{ mt: 2 }}
              style={{ color: "#FDB940" }}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }} align="left">
            <Button
              fullWidth
              onClick={goToDone}
              sx={{ color: "#000", backgroundColor: "#FDB940" }}
              disabled={disabledButton}
            >
              Done
            </Button>
          </Grid>
        </Grid>
        <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="8vh"
      />
      </Box>
    );
  }
}

function CheckBoxes({
  checkedInfo,
  setCheckedInfo,
  checkedTerms,
  setCheckedTerms,
}) {
  const handleInfoChange = (event) => {
    setCheckedInfo(event.target.checked);
  };

  const handleTermsChange = (event) => {
    setCheckedTerms(event.target.checked);
  };

  return (
    <>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={checkedInfo}
            onChange={handleInfoChange}
            color="default"
            sx={{ color: "white" }}
          />
        }
        label="I agree the information is correct"
        sx={{ color: "white", textAlign: "left" }}
      />
      <br /> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedTerms}
            onChange={handleTermsChange}
            color="default"
            sx={{ color: "white" }}
          />
        }
        label="I agree to the Terms & Conditions"
        sx={{ color: "white", textAlign: "left" }}
      />
    </>
  );
}

function DeviceDetails({ serial_number, residentsDetails }) {
  return (
    <>
    <Box 
    sx={{ 
      backgroundColor: '#4D4D4D', 
      borderRadius: '8px', 
      padding: '16px' 
    }}
  >
      <Grid container>
        <Grid item xs={9} sx={{ mt: 2 }} align="left">
          <Typography
            variant="h5"
            sx={{ color: "#FDB940", fontSize: "18px", textAlign: "left" }}
          >
            Device information
          </Typography>
        </Grid>

        <Grid item xs={3} align="center">
          <QR />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, mb: 1 }} align="left">
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontSize: "14px", textAlign: "left" }}
          >
            Serial Number: {serial_number}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, mb: 1 }} align="left">
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontSize: "14px", textAlign: "left" }}
          >
            Address: {residentsDetails.energy_consuming_device.address}
          </Typography>
        </Grid>

      </Grid>
      </Box>
    </>
  );
}
