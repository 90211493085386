import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ReactComponent as Tjommie } from "../Resources/Tjommie.svg";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
export default function HWIPage() {
  let { serial_number } = useParams();
  const navigate = useNavigate();

  const goToNext = () => {
    navigate(`/residents/${serial_number}/whatsapp`);
  };
  const goToPrevious = () => {
    navigate(`/residents/${serial_number}/how-it-works`);
  };
  const skipOnboarding = () => {
    if (onboardingCompleted == "true") {
      navigate(`/residents/${serial_number}/support`);
    } else {
      navigate(`/residents/${serial_number}/terms-and-conditions`);
    }
  };

  const onboardingCompleted = localStorage.getItem("onboardingCompleted");

  React.useEffect(() => {
    if (onboardingCompleted) {
    }
  }, []);
  return (
    <Box bgcolor="#636569" minHeight="100vh">
         <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
        
      >
        <Grid item xs={11} align="center">
          <Grid container>
            <Grid item xs={2} align="left" sx={{ mt: 2 }}>
              <ClearIcon sx={{ color: "white" }} onClick={skipOnboarding} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
       
      />

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
       
      >
        <Grid item xs={12} sx={{ mt: 2, mb: 14 }} align="center">
          <Tjommie width="45%" />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="4vh"
      />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="57vh"
      >
        <Grid item xs={11} align="center">
         
            <Grid container>
           

              <Grid item xs={12} align="left">
                <Typography
                  variant="h5"
                  sx={{ color: "#fff", fontSize: "30px", textAlign: "left" , ml: 1}}
                >
                  {" "}
                  HOT WATER INDEX
                </Typography>
              </Grid>

              <Grid item xs={0.5} />

              <Grid item xs={11} sx={{ mt: 1 }} align="center">
                <ParagraphedText />
              </Grid>

            
            </Grid>
          
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="10vh"
      >
         <Grid item xs={5} align="left">
                
                <Button
                  fullWidth
                  onClick={goToPrevious}
                  style={{ color: "#FDB940" }}
                >
                  Previous
                </Button>
                
              </Grid>
              <Grid item xs={6} align="left">
                <Button
                  fullWidth
                  onClick={goToNext}
                  style={{ color: "#000", backgroundColor: "#FDB940" }}
                >
                  Next
                </Button>
              </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      />
    </Box>
  );
}

function ParagraphedText() {
  return (
    <>
      <p style={{ fontSize: "14px", color: "white", textAlign: "left" }}>
        Our smart technology ensures your geyser stays on when you need it most,
        minimizing cold water complaints and inconvenience.
        <br />
      </p>
      <p style={{ fontSize: "15px", color: "white", textAlign: "left" }}>
        <ul style={{ paddingLeft: "20px" }}>
          <li>
            Hot Water Index analyzes usage patterns to predict when hot water is
            needed.
          </li>
          <br />
          <li>
            It intelligently keeps geysers on during high-demand periods to
            prevent cold water issues.
          </li>
    
        </ul>
      </p>
    </>
  );
}
