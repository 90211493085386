import * as React from "react";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import theme from "../../Global/theme";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import LogOutDialog from "./LogoutDialog";
import Alert from "@mui/material/Alert";
import { useEditJobCardMutation, useEditIMSSmartnodeMutation } from "../api/apiSlice";

export default function JobCompletePage() {

  let navigate = useNavigate();
  let { job_card_reference_id, serial_number, imsi_number } = useParams();

  const [
    editIMSSmartNode, 
    {
      isSuccess: isEditIMSSmartNodeSuccess,
    }
  ] = useEditIMSSmartnodeMutation();

  const [
    editJobCard,
    { isSuccess: isEditJobCardSuccess, isLoading: isEditJobCardLoading },
  ] = useEditJobCardMutation();

  const handleCompleteJob = () => {
    const body = {
      serial_number: serial_number,
      imsi_number: imsi_number,
      status: "In Field",
    };
    editIMSSmartNode({ body: body })
  };

  useEffect(() => {
    if (isEditIMSSmartNodeSuccess) {
      const body = {
        reference_id: job_card_reference_id,
        state:"COM"
      };
      editJobCard({ body: body });
    }
  }, [isEditIMSSmartNodeSuccess]);

  useEffect(() => {
    if (isEditJobCardSuccess) {
      navigate(`/installer/`)
    }
  }, [isEditJobCardSuccess]);

  return (
    <>
      <Helmet>
        <style>{"body { background: #FDB940; }"}</style>
      </Helmet>
      <Grid container alignItems="center" justifyContent="center" sx={{mt:5}}>
        <Grid item xs={8} align="center" />
        <Grid item xs={3.5}  align="right">
          <LogOutDialog />
        </Grid>
        <Grid item xs={0.5} align="right" />
      
        <Typography
          variant="h4"
          sx={{ mt: 20, mb: 5 }}
          style={{ fontSize: "40px", color: "white" }}
        >
          Job Reference Number 
        </Typography>
        <Grid item xs={12}  align="center">
        <Typography
         style={{fontSize: "24px", color: "black"}}
        sx={{mb:5}}
        >
          REF : {job_card_reference_id}
        </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={10} align="center">
        <Alert severity="warning" sx={{ mt: 1, mb: 1 }}>
           Note: In order to complete the Installation, click the button below
          </Alert>
          <Button
            style={theme.whiteButtonStyle.root}
            variant="outlined"
            onClick={handleCompleteJob}
          >
            COMPLETE JOB
          </Button>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
}
