import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ReactComponent as Tjommie } from "../Resources/Tjommie.svg";
import Button from "@mui/material/Button";
export default function TermsAndConditionsPage() {
  let { serial_number } = useParams();
  const navigate = useNavigate();

  const goToNext = () => {
    navigate(`/residents/${serial_number}/device-details`);
  };
  const goToPrevious = () => {
    navigate(`/residents/${serial_number}/add-to-home`);
  };

  const onboardingCompleted = localStorage.getItem("onboardingCompleted");

  React.useEffect(() => {
    if (onboardingCompleted) {
    }
  }, []);
  return (
    <Box bgcolor="#636569" minHeight="100vh">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="10vh"
      />

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      >
        <Grid item xs={12} sx={{ mt: 2, mb: 14 }} align="center">
          <Tjommie width="45%" />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="8vh"
      />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="53vh"
       
      >
        <Grid item xs={11} align="center">
          <Grid container>
            <Grid item xs={10} align="left">
              <Typography
                variant="h5"
                sx={{ color: "#fff", fontSize: "30px", textAlign: "left", ml:1 }}
              >
                Ts & Cs
              </Typography>
            </Grid>

            <Grid item xs={2} align="right"></Grid>
            <Grid item xs={0.5} />

            <Grid item xs={11} sx={{ mt: 1 }} align="center">
              <ParagraphedText />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="10vh"
      >
        <Grid item xs={5} align="left">
          <Button fullWidth onClick={goToPrevious} style={{ color: "#FDB940" }}>
            Previous
          </Button>
        </Grid>
        <Grid item xs={6} align="left">
          <Button
            fullWidth
            onClick={goToNext}
            style={{ color: "#000", backgroundColor: "#FDB940" }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="#636569"
        height="5vh"
      />
    </Box>
  );
}

function ParagraphedText() {
  return (
    <div style={{ height: "330px", overflow: "auto" }}>
      <p style={{ fontSize: "12px", color: "white", textAlign: "left" }}>
        NEURA: TJOMMIE WEBAPP TERMS AND CONDITIONS <br /> <br />I INTRODUCTION
        This Website offers for use a web-based application (“Webapp”) which
        webapp links to a specific hardware device connected to an electrical
        distribution board in your home (site?/on your property / the property
        you rent / the property in which you reside/have other interest in). The
        hardware device (“Tjommie”) monitors and controls your geyser/s (load
        management). You are not required to register and/or use this Webapp in
        order for the tjommie to function as intended; registration and/or use
        of the Webapp and/or Website are voluntary and use of such merely allows
        NEURA to collect certain information, as more fully described below, and
        NEURA will provide you with information and/or recommendations and/or
        suggestions that may enhance your Tjommie experience. The Webapp
        provides basic and/or interesting information - sourced through the
        Tjommie installed at your distributions board – about the performance,
        functionality and load management of your geyser but requires a link in
        order to provide you with this useful information. Registration of the
        device is linked to the property at which it is installed and not the
        person(s) using the geyser although - as a unique identifier is required
        to use the Webapp - your mobile phone number is required to register on
        the app. Your mobile phone number is also used for transmission of
        information from the Tjommie and/or from NEURA. The information
        transmitted to you will typically be of a useful nature or type, as more
        fully detailed in clause X below. The Webapp does not permit you to
        alter any operation and/or functionality of the Tjommie. You may have
        received further information and/or contractual stipulations from the
        installing institution which may apply to the Tjommie and/or the use
        and/or the functionality thereof; these terms and conditions do not
        replace or otherwise amend same. II These terms and conditions relate
        solely to the use of the Tjommie Webapp. The aforegoing notwithstanding,
        certain information gathered from you and/or the tjommie device may be
        transmitted to the installing institution. This is addressed more fully
        below. III IMPORTANT Your registration and/or use and/or continued use
        of the webapp serves as acceptance of these terms and conditions. You
        may opt-out of any push communications in the manner set out in clause X
        below. 1. WEBAPP TERMS AND CONDITIONS OF USE 1.1 The Webapp offers a
        platform for the display of information sourced from your Tjommie device
        at no additional cost to you. 1.2 This document sets out the terms and
        conditions (the “Terms”) pertaining to the access and use of the
        information, products, services and/or functions provided on this
        “Website” or “Webapp”. 1.3  Should any person accessing the Website
        (“you” or the “user”) refuse to accept or otherwise disagree with any of
        the Terms, such a person must refrain from accessing the Website and/or
        Webapp. 1.4  If you are under the age of 18 (eighteen), you must obtain
        your parents’ or legal guardians’ advance authorisation, permission and
        consent to be bound by these Terms before accessing the Website and/or
        Webapp. Such consent will be assumed by Neura, to be in place by virtue
        of these Terms and Conditions being accepted and/or the WEbapp and/or
        Website and/or use the services being accessed. 1.5 NEURA reserves the
        right, in its sole discretion, to amend and/or replace any of, or the
        whole of, the Terms. Such amendments shall supersede and replace any
        previous Terms and shall be made available on the Website. Each time a
        user accesses the Webapp and/or Website and/or uses the services, the
        user shall be deemed to have consented, by such access and/or use, to
        the Terms, as amended and/or replaced by NEURA from time to time. If you
        do not accept the amended Terms, you should refrain from using the
        Website and/or Webapp. 1.6 NEURA will give prior notice where collected
        personal information and the purpose for which NEURA collected that
        information is affected by the intended amendment. 1.7  Please contact
        NEURA should you require clarity on any Terms. NEURA may be contacted in
        the following ways - _______________ 1.8 As a user of the Website and/or
        Webapp, you agree that NEURA may send or direct communications to you,
        such communications typically being by way of messages accessible on the
        Webapp. The information sent or pushed to you will generally be of a
        useful and/or interesting nature and may include the following –
        Information relating to the performance of your geyser Information
        relating to the status of load control management of your tjommie
        Warnings of possible failure or imminent failure of your geyser
        Marketing information of retailers of geysers, installers, insurance
        companies or the like 1.9 Where the user relies on any information
        provided by NEURA, for example, of potential imminent failure of a
        geyser, NEURA is not liable for any call-out fee, repair costs,
        inspection costs or the like and the user expressly indemnifies NEURA
        against any such costs where the geyser proves to be functioning
        correctly and such information was then ostensibly incorrect and/or
        resulting from something other than NEURA’s information and/or the
        Tjommie device and/or its functionality. 1.10 The use of any specialised
        or third party service may be subject to registration and further terms
        and conditions, including payment provisions, may be applicable. 2
        CONTENT AND WARRANTIES 2.1 NEURA reserves the right to make
        improvements, to change or to discontinue, without notice, any aspect,
        service or feature of the Website and any information or content on the
        Website. 2.2 NEURA reserves the right to change and amend any services
        and/or information on this Website from time to time and without notice.
        2.3 NEURA may use the services of third parties to provide information
        on the Website. NEURA has no control over this information and makes no
        representations or warranties of any nature as to its accuracy,
        appropriateness or correctness. The user agrees that such information is
        provided “as is” and that NEURA and its services providers, third party
        users, online partners and the like shall not be liable for any losses
        or damages that may arise from the user’s reliance on it, howsoever
        these may arise. 2.4 NEURA makes no representations or warranties,
        whether express or implied, as to the accuracy, completeness or
        reliability of any information, data and/or content on the Website,
        including without limitation: 2.4.1 NEURA does not warrant that the
        Website or information or downloads shall be error-free or that they
        shall meet any particular criteria of performance or quality. NEURA
        expressly disclaims all implied warranties, including without
        limitation, warranties of merchantability, fitness for a particular
        purpose, non-infringement, compatibility, security and accuracy; 2.4.2
        whilst NEURA has taken reasonable measures to ensure the integrity of
        the Website and its contents, no warranty, whether express or implied,
        is given that any files, downloads or applications available via this
        Website are free of viruses, or any other data or code which has the
        ability to corrupt, damage or affect the operation of the user’s system;
        and 2.4.3 NEURA disclaims any responsibility for the verification of any
        claims. Information published on this Website may be done so in the
        format in which NEURA receives it and statements from external parties
        are accepted as fact. 3 LINKED THIRD PARTY WEBSITES AND THIRD PARTY
        CONTENT 3.1 NEURA may provide links to third party websites on the
        Website. These links are provided to the user for convenience purposes
        only and NEURA does not endorse, nor does the inclusion of any link
        imply NEURA’s endorsement of, such websites, their owners, licensees or
        administrators or such websites’ content or security practices and
        operations. 3.2 While NEURA tries to provide links only to reputable
        websites or online partners, NEURA cannot accept responsibility or
        liability for the information provided on other websites. Linked
        websites or pages are not under, nor subject to, the control of NEURA.
        NEURA is not responsible for and gives no warranties or makes any
        representations in respect of the privacy policies or practices of
        linked or any third party or advertised websites on the Website. 3.3  
        You agree that NEURA shall not be held liable, directly or indirectly,
        in any way for the content, the use or inability to use or access any
        linked website or any link/s contained in a linked website, nor for any
        loss or damage of any sort incurred as a result of any dealings with, or
        as the result of the presence of such third party linked websites on the
        Website. Any dealings that you may have with any linked websites,
        including advertisers, found on the Website, are solely between you and
        the third party website. 4 USAGE RESTRICTIONS The user hereby agrees
        that it shall not itself, nor through a third party: 4.1 copy (other
        than for backup, archival or disaster recovery purposes), reproduce,
        translate, adapt, vary, modify, lease, licence, sub-licence, encumber or
        in any other way deal with any part of the Website for any reason and in
        any manner, unless it is consistent with the intent and purpose of these
        Terms; 4.2 decompile, disassemble or reverse engineer any portion of the
        Website; 4.3 write and/or develop any derivative of the Website or any
        other software program based on the Website; 4.4 modify or enhance the
        Website. In the event of a user effecting any modifications or
        enhancements to the Website in breach of this paragraph 4, such
        modifications and enhancements shall be the property of NEURA; 4.5
        without NEURA’s prior written consent, provide, disclose, divulge or
        make available to or permit the use of or give access to the Website by
        persons other than the user; 4.6 remove any identification, trademark,
        copyright or other notices from the Website; 4.7 post or transmit, by
        means of reviews, comments, suggestions, ideas, questions or other
        information through the Website, any content which is unlawful, harmful,
        threatening, abusive, harassing, defamatory, vulgar, obscene,
        sexually-explicit, profane or hateful, or racially, ethnically or
        otherwise objectionable content of any kind; and/or 4.8 notwithstanding
        anything contained to the contrary in these Terms, use the Website for
        any purpose other than personal, non-commercial and information
        purposes. 5 SECURITY 5.1 In order to ensure the security and reliable
        operation of the services to all NEURA’s users, NEURA hereby reserves
        the right to take whatever action it may deem necessary to preserve the
        security, integrity and reliability of its network and back-office
        applications. 5.2 You may not utilise the Website in any manner which
        may compromise the security of NEURA’s networks or tamper with the
        Website in any manner whatsoever, which shall include without
        limitation, gaining or attempting to gain unauthorised access to the
        Website, or delivering or attempting to deliver any unauthorised,
        damaging or malicious code to the Website, all of which is expressly
        prohibited. Any person or entity which does so, or attempts to do so,
        shall be held criminally liable. Further, should NEURA suffer any damage
        or loss, civil damages shall be claimed by NEURA against the user. 5.3
        Any user who commits any of the offences detailed in Chapter 13 of the
        Electronic Communications and Transactions Act 25 of 2002 (“ECTA”)
        (specifically sections 85 to 88 (inclusive)) shall, notwithstanding
        criminal prosecution, be liable for all resulting liability, loss or
        damages suffered and/or incurred by NEURA and its affiliates, agents
        and/or partners. 6 INTELLECTUAL PROPERTY RIGHTS 6.1 For the purpose of
        this paragraph 6, the following words shall have the following meanings
        ascribed to them: 6.1.1   “Intellectual property rights” means all and
        any of the rights in and to intellectual property of any nature
        whatsoever owned and/or controlled directly or under licence by NEURA,
        now or in the future, including without limitation, NEURA’s rights,
        title and interest in and to all technology, source code/s, trade
        secrets, logos, systems, methods, trademarks, trade names, styles,
        insignia, designs, patents and copyright, and all similar proprietary
        rights which may subsist in any part of the world, whether registered or
        not. 6.2  All copyright and other intellectual property rights in all
        content, trademarks, software, data, material, including logos,
        databases, text, graphics, icons, hyperlinks, confidential information,
        designs, agreements, and multimedia works, published on or via the
        Website (“proprietary material”), are the property of, or are licensed
        to, NEURA and as such are protected from infringement by local and
        international legislation and treaties. 6.3   By submitting reviews,
        comments and/or any other content (other than your personal information)
        to NEURA for posting on the Website, you automatically grant NEURA and
        its affiliates a non-exclusive, royalty-free, perpetual, irrevocable
        right and licence to use, reproduce, publish, translate, sub-license,
        copy and distribute such content in whole or in part worldwide, and to
        incorporate it in other works in any form, media, or technology now
        known or hereinafter developed, for the full term of any copyright that
        may exist in such content. Subject to this licence, you retain any and
        all rights that may exist in such content. 6.4 All rights not expressly
        granted are reserved and no right, title or interest in any proprietary
        material or information contained in this Website is granted to you. 6.5
        Except with NEURA’s express written permission, no proprietary material
        from this Website may be copied or retransmitted. 6.6 Irrespective of
        the existence of copyright, the user acknowledges that NEURA is the
        proprietor of all material on the Website (except where a third party is
        indicated as the proprietor), whether it constitutes confidential
        information or not, and that the user has no right, title or interest in
        any such material. 6.7 NEURA authorises you only to view, copy,
        temporarily download to a local drive and to print the content of this
        Website, or any part thereof, provided that such content is used for
        personal purposes and for information purposes only, and such content is
        used for non-commercial purposes. 7 RISK, LIMITATION OF LIABILITY AND
        INDEMNITY 7.1 The user’s use of this Website and the information
        contained on the Website is entirely at the user’s own risk and the user
        assumes full responsibility and risk of loss resulting from the use
        thereof. 7.2 The transmission of information via the internet, including
        without limitation e-mail, is susceptible to monitoring and
        interception. The user bears all risk of transmitting information in
        this manner. Under no circumstances shall NEURA be liable for any loss,
        harm, or damage suffered by the user as a result thereof. NEURA reserves
        the right to request independent verification of any information
        transmitted via e-mail and the user consents to such verification should
        NEURA deem it necessary. 7.3 To the extent permissible by law: 7.3.1
        Neither NEURA, its affiliates, shareholders, agents, consultants or
        employees shall be liable for any damages whatsoever, including without
        limitation any direct, indirect, special, incidental, consequential or
        punitive damages, howsoever arising (whether in an action arising out of
        contract, statute, delict or otherwise) related to the use of, or the
        inability to access or use the content of the Website or any
        functionality thereof, or the information contained on the website, or
        of any linked website, even if NEURA knows or should reasonably have
        known or is expressly advised thereof. 7.3.2 The liability of NEURA for
        faulty execution of the Website as well as all damages suffered by the
        user, whether direct or indirect, as a result of the malfunctioning of
        the Website shall be limited to NEURA rectifying the malfunction, within
        a reasonable time and free of charge, provided that NEURA is notified
        immediately of the damage or faulty execution of the Website. This
        liability shall fall away and be expressly excluded if the user attempts
        to correct or allows third parties to correct or attempt to correct the
        Website without the prior written approval of NEURA. However, in no
        event shall NEURA be liable to the user for loss of profits or for
        special, incidental, consequential or punitive losses or damages arising
        out of or in connection with the Website or its use or the delivery,
        installation, servicing, performance or use of it in combination with
        other computer software. 7.3.3 You hereby unconditionally and
        irrevocably indemnify NEURA and agree to hold NEURA free from all loss,
        damages, claims and/or costs, of whatsoever nature suffered or incurred
        by NEURA or instituted against NEURA as a direct or indirect result of:
        7.3.3.1 your use of the Website; 7.3.3.2 software, programs and support
        services supplied by, obtained by or modified by you or any third party
        without the consent or knowledge of NEURA; 7.3.3.3 your failure to
        comply with any of the terms or any other requirements which NEURA may
        impose from time to time; 7.3.3.4 the actions or requirements of any
        telecommunications authority or a supplier of telecommunications
        services or software; 7.3.3.5 any unavailability of, or interruption in,
        the service which is beyond the reasonable control of NEURA; 7.3.3.6
        tampering with the device, including but not limited to personal harm
        and/or death; or 7.3.3.7 criminal prosecution as a result of tampering
        with the Tjommie device. 7.4 NEURA makes no warranty or representation
        as to the availability, validity, accuracy or completeness of the
        content of the Website and/or as may be provided or displayed in respect
        of the Tjommie. You expressly waive and renounce all your rights of
        whatever nature that you may have against NEURA for any loss suffered by
        you, as a result of information supplied by NEURA being incorrect,
        incomplete or inaccurate. 8 NEURA PRIVACY AND COOKIE POLICY 8.1 NEURA
        may collect the following information, which information will be
        processed in accordance with the Protection of Personal Information Act
        4 of 2013 (as amended) as more fully detailed in our Privacy and/or
        Cookie Policies – ________________________________ 8.2 You may
        discontinue use of this Webapp at any time and your Tjommie will remain
        unaffected. 8.3 You may opt-out of communications by discontinuing use
        of this Webapp and by deregistering in the manner set out in our Privacy
        Policy. 8.4 Personal information when used in this Policy means
        information that can identify you as an individual or is capable of
        identifying you. Personal information does not mean general,
        statistical, aggregated or anonymised information. The definition of
        “personal information”, as defined in the Promotion of Access to
        Information Act 2 of 2000 (“PAIA”) shall generally be applied for
        purposes of this clause 8 (PAIA may be downloaded from
        http://www.polity.org.za/attachment.php?aa_id=3569). 8.5 Your use of
        NEURA’s services signifies your consent to NEURA collecting and using
        your personal information as specified below. 8.6 How NEURA collects
        information about you - Your name, username, password, address, identity
        number, contact numbers and/or email address may be collected when you:
        register to use the Webapp/Website; communicate with NEURA; and
        participate in promotions, competitions and the like. 8.7 How NEURA uses
        your information - NEURA uses (or may use) your information to – confirm
        that the Tjommie installed on the applicable distribution board is
        functioning as expected; validate you as a Tjommie and/or Webapp user
        when communicating with you; and prevent and detect criminal activity,
        fraud and misuse of or damage to NEURA’s and/or the installing
        institution’s services or networks or damage any Tjommie device and
        prosecute those responsible. 8.8 How long does NEURA keep your
        information for? The time period for which NEURA keeps your information
        may vary according to the use or purpose attached to the information.
        Unless there is a specific legal requirement requiring NEURA to keep
        your information, however, NEURA will not keep it for longer than
        necessary for the purpose for which the information was collected or for
        which it is to be processed. 8.9 Disclosing your information to third
        parties - NEURA may provide aggregate statistics regarding Tjommie
        device usage and/or functionality to third parties, but these statistics
        will not include any information that could identify you (except in an
        instance where possible criminal or unlawful activity is suspected, for
        example, tampering with Tjommie by its user(s)). Third party information
        and/or marketing information may also be directed to you in the manner/s
        set out herein. 8.10 When and where does NEURA use cookies? NEURA
        allocates cookies during the registration process for NEURA’s services
        and the use of the Webapp. These cookies will hold information collected
        during your registration and will allow NEURA to recognise you as a
        user, your identity will be linked to one or more Tjommie devices, and
        provide you with available information and/or services. 8.11 How can you
        refuse or opt out of cookies? Most browsers are set by default to accept
        cookies automatically, but usually you can alter the settings of your
        browser to prevent automatic acceptance and to prompt you each and every
        time a cookie is sent to you or to allow you to choose not to receive
        cookies at all. 8.12 How can you manage your privacy preferences? If you
        would like NEURA to stop processing your information for marketing
        purposes, please opt-out by _________________________. 9 CONFIDENTIALITY
        9.1 By subscribing as a user, you agree that you shall hold in the
        strictest confidence and not disclose to any third party information
        acquired in connection with any aspect of the services offered by NEURA.
        You further agree that you shall notify NEURA should you discover any
        loss or unauthorised disclosure of the information. 9.2 Any information
        or material sent to NEURA will be deemed not to be confidential, unless
        otherwise agreed in writing by the user and NEURA. 10 BREACH OR
        CANCELLATION BY NEURA 10.1 NEURA is entitled without notice, in addition
        to any other remedy available to it at law or under these Terms,
        including obtaining an interdict, to cancel these Terms, limit or deny
        such user use of the Website and services, or to claim specific
        performance of any obligation whether or not the due date for
        performance has arrived, in either event without prejudice to NEURA’s
        right to claim damages, should any user: 10.1.1 breach any of these
        Terms; 10.1.2 in the sole discretion of NEURA, use the Website in an
        unauthorised manner; or 10.1.3 infringe any statute, regulation,
        ordinance or law. 10.2 Breach of these Terms entitles NEURA to take
        legal action without prior notice to the user and the user agrees to
        reimburse the costs associated with such legal action to NEURA on an
        attorney and own client scale. 11 COMPLIANCE WITH SECTION 43(1) OF ECT
        ACT 11.1 In compliance with section 43(1) of ECTA, the following details
        of the NEURA are recorded: 11.1.1 Full name: 11.1.2 Registration number:
        11.1.3 Physical address: 11.1.4 Telephone number: 11.1.5 Website
        address: 11.1.6 E-mail address: 11.1.7 Names of office bearers: 11.1.8
        Registered at: 12 COMPLIANCE WITH LAWS You shall comply with all
        applicable laws, statues, bylaws, ordinances and regulations pertaining
        to your use of and access to this Website. 13 NOTICES AND DOMICILE 13.1
        Except as explicitly stated otherwise, any notices shall be given by
        email to [insert relevant email address here] (in the case of NEURA) or,
        in the sole discretion of NEURA, to your inbox on the NEURA Webapp, the
        mobile number and/or e-mail address you have provided to NEURA (in your
        case), or such other address that has been specified. Notice shall be
        deemed given 48 (forty eight) hours after an email is sent, unless the
        sending party is notified that the email address is invalid.
        Alternatively, NEURA may give you notice by registered mail, postage
        prepaid and return receipt requested, to the address which you have
        provided to NEURA. In such case, notice shall be deemed given 7 (seven)
        days after the date of mailing. You acknowledge that all agreements,
        notices or other communication required to be given in terms of the law
        or these Terms may be given via electronic means and that such
        communications shall be “in writing”. Notwithstanding anything to the
        contrary, a written notice or communication actually received by a party
        shall be an adequate written notice or communication to it,
        notwithstanding that it was not sent to or delivered at its chosen
        address(es) for that purpose. 13.2 This Website is governed by the laws
        of South Africa and NEURA chooses as its domicilium citandi et
        executandi for all purposes under these Terms and/or this agreement,
        whether in respect of court process, notice, or other documents or
        communication of whatsoever nature, the address/es set out in paragraph
        11 above. 14 GENERAL PROVISIONS 14.1 These Terms shall be governed in
        all respects by the laws of the Republic of South Africa as such laws
        are applied to agreements entered into and to be performed within South
        Africa. 14.2 All payments, whether by NEURA or user, shall be made in
        South African Rands (“ZAR”). 14.3 This Website is controlled, operated
        and administered by NEURA from its office/s within the Republic of South
        Africa. NEURA makes no representation that the content of the Website is
        appropriate or available for use outside of South Africa. Access to the
        Website from territories or countries where the content of the Website
        is illegal is prohibited. Users may not use this Website in violation of
        South African export laws and regulations. If the user accesses this
        Website from locations outside of South Africa, that user is responsible
        for compliance with all local laws. 14.4 NEURA does not guarantee
        continuous, uninterrupted or secure access to NEURA services, as
        operation of NEURA’s Website may be interfered with as a result of a
        number of factors which are outside of NEURA’s control. This Website
        and/or Webapp and/or NEURA’s services may be unavailable from time to
        time for maintenance, upgrades, planned down-time and/or reasons beyond
        the control of NEURA. 14.5 If any provision of these Terms is held to be
        illegal, invalid or unenforceable for any reason, such provision shall
        be struck out from these Terms and the remaining provisions shall be
        enforced to the full extent of the law. 14.6 NEURA’s failure to act with
        respect to a breach by yourself or others does not constitute a waiver
        of NEURA’s right to act with respect to subsequent or similar breaches.
        14.7 You shall not be entitled to cede your rights or assign your rights
        or delegate your obligations in terms of these Terms to any third party
        without the prior written consent of NEURA. 14.8 No party shall be bound
        by any express or implied term, representation, warranty, promise or the
        like not recorded herein, whether it induced the contract and/or whether
        it was negligent or not. 14.9 The head notes to the paragraphs to these
        Terms are inserted for reference purposes only and shall not affect the
        interpretation of any of the provisions to which they relate. 14.10
        Words importing the singular shall include the plural and vice versa,
        and words importing the masculine gender shall include females and words
        importing persons shall include partnerships and corporate and
        unincorporated entities. 14.11 These Terms set forth the entire
        understanding and agreement between NEURA and yourself with respect to
        the subject matter hereof.
      </p>
    </div>
  );
}
