import React from "react";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import { useGetTotalInstalledCapacityQuery } from "../api/apiSlice";
import CircularProgress from "@mui/material/CircularProgress";

export default function TjommieDemandGraph({ graph_data }) {
  const {
    data: totalInstalledCapacity,
    isLoading: totalInstalledCapacityLoading,
    isError: totalInstalledCapacityError,
  } = useGetTotalInstalledCapacityQuery();
  const renderCircle = (color) => (
    <div
      style={{
        width: "18px",
        height: "4px",
        borderRadius: "100%",
        backgroundColor: color,
        marginRight: "8px",
      }}
    />
  );

  const textFieldStyle = {
    minWidth: "346px",
    mmaxWidth: "446px",
    height: "68px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    padding: "0 12px",
  };

  const series = [
    {
      name: "Tjommie Demand",
      type: "line",
      data: graph_data.tjommie_data,
      color: "#FDB940",
    },
  ];

  const data = {
    series: series,
    options: {
      chart: {
        type: "line",
        stacked: false,
        animations: {
          enabled: false,
          dynamicAnimation: {
            enabled: false,
          },
        },
        background: "#ffffff",
      },
      markers: {
        size: [0, 0, 0, 0, 0, 10, 10],
        fillOpacity: [0, 0, 0, 0, 0, 1, 0.4],
      },
      fill: {
        type: "solid",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: graph_data.timestamps,
        type: "datetime",
        labels: {
          datetimeUTC: false,
      },
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: "#808080",
          },
        },
      },
      tooltip: {
        shared: true,
      },
    },
  };

  return (
    <Card
      sx={{ borderRadius: "12px", backgroundColor: "#F6F6F6", mt: 5, mb: 10 }}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={0.25} />
        <Grid item xs={5.5} align="left">
          <Typography
            style={{ fontSize: "25px", color: "black" }}
            sx={{ mt: 10, mb: 5 }}
          >
            Tjommie Demand
          </Typography>
        </Grid>
        <Grid item xs={5.5} align="right">
          <Grid container alignItems="center" justifyContent="right">
            <Grid item>
              <Typography
                style={{ fontSize: "20px", color: "grey" }}
                sx={{ mt: 10, mb: 5, mr: 1 }}
              >
                Total Geyser Installed Capacity
              </Typography>
            </Grid>
            {totalInstalledCapacityLoading ? (
              <CircularProgress />
            ) : (
              <Grid item>
                <Typography fontWeight="bold" sx={{ mt: 10, mb: 5, mr: 1 }}>
                  {totalInstalledCapacity !==
                  undefined
                    ? totalInstalledCapacity + " kVA"
                    : "No value"}{" "}
                 
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={0.5} />
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} align="center">
          <Box borderRadius={6} bgcolor="white" p={2} width="95%">
            <Chart options={data.options} series={data.series} height={400} />
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="left" sx={{ mt: 1 }}>
        <Grid item xs={0.25} />
        <Grid item xs={4} align="left">
          <InputBase
            readOnly
            startAdornment={renderCircle("#FDB940")}
            value="Tjommie Demand"
            style={textFieldStyle}
            sx={{ mt: 2, mb: 5 }}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
