import Grid from "@mui/material/Grid";
import Graph from "./Graphs";
import TableHWI from "./Table";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useNavigate } from "react-router-dom";

const styles = {
  container: {
    margin: "0 auto",
    padding: "0 16px",
    maxWidth: "1700px",
    marginTop: 70,
  },
};

export default function HistoricalDataPage() {
  return (
    <>
      <Grid style={styles.container}>
        <Heading />
        <Grid
          sx={{ marginTop: "20px" }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Graph />
        </Grid>
        <Grid
          sx={{ marginTop: "20px" }}
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <TableHWI />
        </Grid>
      </Grid>
    </>
  );
}

function Heading() {
  let {serial_number, meter_id } = useParams();

  let navigate = useNavigate();

  const handlePrevPage = () => {
    navigate(-1);
  };
  return (
    <Grid
      sx={{ marginTop: "30px" }}
      container
      alignItems="left"
      justifyContent="left"
      xs={12}
    >
      <Grid ite xs={0.5}>
        <ArrowBackIcon
          size="large"
          sx={{ color: "#000", mb: 2, mt: 2, cursor: "pointer" }}
          onClick={handlePrevPage}
        />
      </Grid>

      <Grid item xs={11.5} align="left">
        <Typography variant="h5" sx={{ mb: 2, mt: 1.5 }}>
          Tjommie SN : {serial_number}
        </Typography>
      </Grid>
    </Grid>
  );
}
