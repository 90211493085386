import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../Authentication/authSlice";

export default function LogOutDialog() {
  const [anchorElLogout, setanchorElLogout] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    setanchorElLogout(null);
    dispatch(logout());
  };
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <LogoutIcon onClick={handleOpenDialog} />

      <Dialog open={open}>
        <DialogTitle>Are you sure you want to Log Out?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will need to log in to track installations.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="text">
            Back
          </Button>
          <Button onClick={handleLogout} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
