//React
import React from "react";
import { useState } from "react";
//Material UI
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useAddScheduleToScheduleGroupMutation } from "../api/apiSlice";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import DeleteSchedule from "./DeleteSchedule";
import TextField from "@mui/material/TextField";

export default function AddSchedule({ schedule }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
    <Button endIcon={<AddCircleIcon/>} variant="outlined"  sx={{
                        borderRadius: "25px",
                        border: "none",
                        color: "#795285",
                      }} onClick={() => handleOpen()}>Add Schedule</Button>
      {/* <AddCircleIcon style={{ color: "#000" }} onClick={() => handleOpen()} /> */}
      <Dialog
        BackdropProps={{ invisible: true }}
        PaperProps={{
          // style: theme.modalCardStyle.root,
        }}
      
        open={open}
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <ScheduleDialogContent
            schedule={schedule}
           
            handleClose={handleClose}
            open={open}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

function ScheduleDialogContent({ schedule, handleClose }) {
  const dispatch = useDispatch();

  const dayList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const [schedule_type, setschedule_day] = useState(
    ""
  );
  const [schedule_name, setSchedulename] = useState("");
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");

  const [addSchedule] = useAddScheduleToScheduleGroupMutation();

  const handleSubmit = (event) => {
    const body = {
      schedule_name: schedule_name,
      schedule_day: getDayNumber(schedule_type),
      schedule_off: startTime.$H + ":" + startTime.$m + ":" + startTime.$s,
      schedule_on: endTime.$H + ":" + endTime.$m + ":" + endTime.$s,
    };
    const request = {
      body: body,
      schedule_group_id: schedule.id,
    };
    
    addSchedule(request);
    handleClose();
  };
  const onChangeStart = (time: Dayjs) => {
    setstartTime(time);
  };
  const onChangeEnd = (time: Dayjs) => {
    setendTime(time);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={11} align="left">
          <Typography >Add schedule</Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <ClearIcon onClick={handleClose} />
        </Grid>
        <Grid item xs={12} align="center">
        <Typography sx={{mt:1}}>
              Schedule Name
            </Typography>
            <TextField
              onChange={(e) => setSchedulename(e.target.value)}
              margin="normal"
              fullWidth
              className="inputRounded"
              id="schedule_name"
              name="schedule_name"
              required
              autoComplete="schedule_name"
              value={schedule_name || ""}
            />
            </Grid>
        <Grid item xs={12} align="center">
          <Typography>Schedule Day</Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <FormControl sx={{ mb: 2 }} fullWidth className="inputRounded">
            <Select
              required
              value={schedule_type}
              onChange={(e) => setschedule_day(e.target.value)}
            >
              {dayList.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

       
        <Grid item xs={12} md={5.5} align="center">
          <Grid item xs={12} align="center">
            <Typography >Switch off </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{ mb: 2 }} components={["TimePicker"]}>
                <TimePicker
                  className="inputRounded"
                  value={startTime}
                  onChange={onChangeStart}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={0} md={1} align="center" />
        <Grid item xs={12} md={5.5} align="center">
          <Grid item xs={12} align="center">
            <Typography>Switch on </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  className="inputRounded"
                  value={endTime}
                  onChange={onChangeEnd}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item xs={12} align="center">
          <Button
            sx={{ mt: 2, mb: 2 }}
            style={{ color: "#000" }}
            onClick={handleSubmit}
          >
           Add
          </Button>
        </Grid>

       
      </Grid>
    </>
  );
}


function getDayNumber(dayGiven) {
  let day = "";
  switch (dayGiven) {
    case "Sunday":
      day = 6;
      break;
    case "Monday":
      day = 0;
      break;
    case "Tuesday":
      day = 1;
      break;
    case "Wednesday":
      day = 2;
      break;
    case "Thursday":
      day = 3;
      break;
    case "Friday":
      day = 4;
      break;
    case "Saturday":
      day = 5;
      break;
    default:
      day = dayGiven;
  }

  return day;
}
