import React from "react";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import { useGetHWIGraphQuery } from "../operatorDashboardAPISlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
export default function Graph() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let { meter_id } = useParams();

  const request = {
    meter_id: meter_id,
    timezone: userTimezone
  }
  const {
    data: HotWaterIndex,
    isSuccess: isHotWaterIndexSuccess,
    isLoading: isHotWaterIndexLoading,
    isError: isHotWaterIndexError,
  } = useGetHWIGraphQuery(request, {
    pollingInterval: 60000,
  });

  if (isHotWaterIndexLoading) {
    return (
      <>
        <Grid
          style={{ marginLeft: "50px" }}
          item
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
        <Grid item alignItems="center" justifyContent="center"></Grid>
        <p>Loading Dashboard Graph Data</p>
      </>
    );
  } else if (isHotWaterIndexError) {
    return (
      <Grid
        style={{ marginLeft: "50px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        Error retrieving current Dashboard Graph Data
      </Grid>
    );
  } else {
    const title = "With load shifting & peak shaving";
    const title2 = "Without load shifting & peak shaving";

    const data = {
      series: [
        {
          name: title,
          type: "line",
          color: "#ED4B82",
          data: HotWaterIndex.data?.map((item) => item.hot_water_index),
        },
      ],
      options: {
        chart: {
          stacked: false,
          animations: {
            enabled: false,
            dynamicAnimation: {
              enabled: false,
            },
          },
          toolbar: {
            export: {
              csv: {
                filename: `Device_Historical_Report`,
                dateFormatter(datetime) {
                  const dateObj = new Date(datetime);
                  const hours = dateObj.getHours().toString().padStart(2, "0");
                  const minutes = dateObj
                    .getMinutes()
                    .toString()
                    .padStart(2, "0");
                  const day = dateObj.getDate().toString().padStart(2, "0");
                  const month = (dateObj.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = dateObj.getFullYear().toString();
                  return `${year}-${month}-${day} ${hours}:${minutes}`;
                },
              },
            },
          },
        },

        fill: {
          type: "solid",
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: title, // Use the dynamically determined title
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },

        xaxis: {
          categories: HotWaterIndex.data?.map((item) => item.interval),
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
        
          title: {
            text: "HWI",
          },
          decimalsInFloat: 2,
        },
        tooltip: {
          followCursor: true,
          x: {
            format: "yyyy MMM dd HH:mm"
        },
        
        },
      },
    };

    const data2 = {
      series: [
        {
          name: title2,
          type: "line",
          color: "#FF9800",
          data: HotWaterIndex.data?.map((item) => item.hwi_simulated),
        },
      ],
      options: {
        chart: {
          stacked: false,
          animations: {
            enabled: false,
            dynamicAnimation: {
              enabled: false,
            },
          },
          toolbar: {
            export: {
              csv: {
                filename: `Device_Historical_Report`,
                dateFormatter(datetime) {
                  const dateObj = new Date(datetime);
                  const hours = dateObj.getHours().toString().padStart(2, "0");
                  const minutes = dateObj
                    .getMinutes()
                    .toString()
                    .padStart(2, "0");
                  const day = dateObj.getDate().toString().padStart(2, "0");
                  const month = (dateObj.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = dateObj.getFullYear().toString();
                  return `${year}-${month}-${day} ${hours}:${minutes}`;
                },
              },
            },
          },
        },

        fill: {
          type: "solid",
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: title2, // Use the dynamically determined title
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },

        xaxis: {
          categories: HotWaterIndex.data?.map((item) => item.interval),
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          title: {
            text: "HWI",
          },
          decimalsInFloat: 0,
        },
        tooltip: {
          followCursor: true,
          x: {
            format: "yyyy MMM dd HH:mm"
        },
        },
      },
    };

    return (
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item lg={6} xs={12}>
        <Card variant="outlined">
          <Chart options={data.options} series={data.series} height={400} />
          </Card>
        </Grid>
        <Grid item lg={6} xs={12}>
        <Card variant="outlined">
          <Chart options={data2.options} series={data2.series} height={400} />
          </Card>
        </Grid>
      </Grid>
    );
  }
}
