import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import { useGetDSMMetersQuery } from "./operatorDashboardAPISlice";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";

function GoTo({ obj }) {
  const navigate = useNavigate();


  const handleClick = (serial_number, id) => {
    navigate(`/operator/historical-data/${serial_number}/${id}/`);
  };
  if (obj.hwi === "No geyser connected" || obj.hwi === "No device connected") {
    return <Chip label={obj.serial_number}></Chip>;
  } else {
    return (
      <Chip
        label={obj.serial_number}
        onClick={() => handleClick(obj.serial_number, obj.id)}
      ></Chip>
    );
  }
}
const columns = [
  {
    field: "serial_number",
    headerName: "ID",
    width: "120",
    renderCell: (params) => <GoTo obj={params.row} />,
  },
  { field: "hwi", headerName: "HWI", width: "170" },
  { field: "switch_status", headerName: "Switch", width: "80" },
  // {
  //   field: "thermo",
  //   headerName: "Thermo",
  //   width: "170",
  //   valueGetter: (params) => {
  //     const thermoValue = params.row.thermo;
  //     if (thermoValue === "No device connected") {
  //       return "No device connected";
  //     } else if (thermoValue === "No geyser connected") {
  //       return "No geyser connected";
  //     } else {
  //       return thermoValue ? "On" : "Off";
  //     }
  //   },
  // },
  { field: "control_status", headerName: "Control", width: "120" },
  { field: "power", headerName: "Power", width: "100" },
  // { field: "last_hg", headerName: "Last HG", width: "170" },
  // { field: "last_cg", headerName: "Last CG", width: "170" },
  // { field: "heating_time", headerName: "Heating time", width: "100" },
  // { field: "cooling_time", headerName: "Cooling time", width: "120" },
  {
    field: "last_switch_controlled",
    headerName: "Last Used switch",
    width: "180",
  },
  {
    field: "lower_limit_flagged",
    headerName: "Lower Limit",
    width: "120",
    renderCell: (params) => {
      const flagged = params.row.lower_limit_flagged;
      return flagged ? <FlagIcon sx={{ color: "red" }} /> : null;
    },
  },
];

export default function OperatorDataGrid() {
  const [serialNumber, setSerialNumber] = React.useState("");
  const [control, setControl] = React.useState("");
  const [operational, setOperational] = React.useState("Operational");

  const [switchStatus, setSwitchStatus] = React.useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);

  const [tempQuery, setTempQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [queryParams, setParamsQuery] = useState('');

  const handleControlFilterChange = (event) => {
    if (control === event.target.value) {
      setControl("");
    } else {
      setControl(event.target.value);
    }
  };
  const handleSwitchStatusFilterChange = (event) => {
    if (switchStatus === event.target.value) {
      setSwitchStatus("");
    } else {
      setSwitchStatus(event.target.value);
    }
  };

  const {
    data: dsmMeters,
    isLoading: isDSMMetersLoading,
    isSuccess: isDSMMetersSuccess,
    isError: isDSMMetersError,
  } = useGetDSMMetersQuery(queryParams, {
    pollingInterval: 60000,
  });
  React.useEffect(() => {
    if (isDSMMetersSuccess) {
      setFilteredRows(dsmMeters.data);
    }
  }, [isDSMMetersSuccess]);
  React.useEffect(() => {
    if (isDSMMetersSuccess && dsmMeters) {
      const filtered = dsmMeters?.data.filter((row) => {
        const serialNumberMatch = row.serial_number
          .toLowerCase()
          .includes(serialNumber.toLowerCase());
        const controlMatch = row.control_status
          .toLowerCase()
          .includes(control.toLowerCase());
        const switchStatusMatch = row.switch_status
          .toLowerCase()
          .includes(switchStatus.toLowerCase());
        const operationalMatch = row.operational
          .toLowerCase()
          .includes(operational.toLowerCase());

        return (
          serialNumberMatch &&
          controlMatch &&
          switchStatusMatch &&
          operationalMatch
        );
      });

      setFilteredRows(filtered);
    }
  }, [
    isDSMMetersSuccess,
    dsmMeters,
    serialNumber,
    control,
    switchStatus,
    operational,
  ]);

  if (isDSMMetersLoading) {
    return (
      <>
        <Grid
          style={{ marginLeft: "50px" }}
          item
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
        <Grid item alignItems="center" justifyContent="center"></Grid>
        <p>Loading Table</p>
      </>
    );
  } else if (isDSMMetersError) {
    return (
      <Grid
        style={{ marginLeft: "50px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        Error retrieving DSM Meter Table
      </Grid>
    );
  } else {
    return (
      <>
        <Grid item sx={{ width: "100%" }}>
          <Paper sx={{ borderRadius: 3 }}>
            <Grid container>
              <Grid item>
                <ToggleButtonGroup
                  sx={{ marginTop: 3, marginLeft: 3 }}
                  color="primary"
                  value={operational}
                  exclusive
                  onChange={(e) => setOperational(e.target.value)}
                  aria-label="Platform"
                >
                  <ToggleButton value="Operational">Connected</ToggleButton>
                  <ToggleButton value="Faulty">Disconnected</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  sx={{ marginTop: 3, marginLeft: 3 }}
                  color="primary"
                  value={switchStatus}
                  exclusive
                  onChange={handleSwitchStatusFilterChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="On">On</ToggleButton>
                  <ToggleButton value="Off">Off</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  sx={{ marginTop: 3, marginLeft: 3 }}
                  color="primary"
                  value={control}
                  exclusive
                  onChange={handleControlFilterChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="Nil">Nil</ToggleButton>
                  <ToggleButton value="PS">PS</ToggleButton>
                  <ToggleButton value="LS">LS</ToggleButton>
                  <ToggleButton value="PSLS">PS&LS</ToggleButton>
                  <ToggleButton value="Rec">Rec</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item sx={{ marginLeft: "auto", marginRight: 3 }}>
                <TextField
                  sx={{ marginTop: 3 }}
                  name="search-bar"
                  size="small"
                  className="inputRounded"
                  id="search-bar"
                  variant="outlined"
                  value={tempQuery} 
                  placeholder="Search Serial Number"
                  onChange={(e) => setTempQuery(e.target.value)} 
                  onBlur={() => {
                    setParamsQuery({'searchQuery':tempQuery});
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <DataGrid
              sx={{ borderRadius: 3, marginTop: 3 }}
              rows={filteredRows}
              columns={columns}
              autoHeight
              // loading={isDSMMetersLoading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              autoWidth
              pageSizeOptions={[25]}
              disableSelectionOnClick
              disableRowSelectionOnClick={true}
              columnBuffer={3}
              paginationMode='server'
              rowCount = {dsmMeters?.total_devices}
              onPaginationModelChange={(paginationModel) => {
                setParamsQuery({'page':paginationModel.page,
                  'pageSize':paginationModel.pageSize
                })
              }}
            />
          </Paper>
        </Grid>
      </>
    );
  }
}
