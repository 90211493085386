import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDeleteJobCardMutation } from "../api/apiSlice";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getCurrentStep } from "../Installer/Stepper/stepperSlice";
import { useSelector, useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";

export default function CancelJobButton() {
  const [currentStep, setCurrentStep] = useState(useSelector(getCurrentStep));

  let { job_card_reference_id } = useParams();
  let navigate = useNavigate();
  const [deleteJobCard, { isSuccess: isDeleteJobCardSuccess }] =
    useDeleteJobCardMutation();
  const [are_you_sure_flag, setAreYouSureFlag] = useState(false);

  const handleCancelJob = () => {
    if (are_you_sure_flag) {
      // if (currentStep == 0) {
      //   navigate(`/installer/`);
      // } else {
        const body = {
          reference_id: job_card_reference_id,
        };
        deleteJobCard({ body: body });
      // }
    } else {
      setAreYouSureFlag(true);
    }
  };
  const handleCloseDialog = () => {
    setAreYouSureFlag(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isDeleteJobCardSuccess) {
      navigate(`/installer/`);
    }
  }, [isDeleteJobCardSuccess]);

  if (currentStep === 0) {
    return (
      <>
        <ClearIcon
          variant="contained"
          color="error"
          sx={{ mt: 1 }}
          onClick={handleBack}
        />
      </>
    );
  } else {
    return (
      <>
        <ClearIcon
          variant="contained"
          color="error"
          sx={{ mt: 1 }}
          onClick={handleCancelJob}
        />
        <Dialog open={are_you_sure_flag}>
          <DialogTitle>
            Are you sure you want to abort the current installation?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You will need to start a new install.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant="text">
              Back
            </Button>
            <Button onClick={handleCancelJob} variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
