import { createSlice } from "@reduxjs/toolkit/";

const initialState = {
  navName:"",
  navDrawer: true,

};

export const residentsSlice = createSlice({
  name: "residents",
  initialState,

  reducers: {
    setnavName:(state, action) =>{
      state.navName = action.payload;
    },
    setnavDrawer:(state, action) =>{
      state.navDrawer = action.payload;
    },
   
  },
});

export const getnavName = (state)=> state.residents.navName;
export const getnavDrawer = (state)=> state.residents.navDrawer;

export const { setnavName, setnavDrawer} = residentsSlice.actions;

export default residentsSlice.reducer;
