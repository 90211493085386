import React from "react";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useState } from "react";

export default function CustomTextFields({ values }) {
  return (
    <Grid container spacing={2} alignItems="center" sx={{ p: 4 }}>
 
      <LoadWithIntervionAndShiftedValues values={values} />
      <ShavedValues values={values} />
    
    </Grid>
  );
}

function LoadWithIntervionAndShiftedValues({ values }) {
  const [openWithoutIntervention, setOpenWithoutIntervention] = useState(false);
  const [openWithIntervention, setOpenWithIntervention] = useState(false);
  const [openShifted, setOpenShifted] = useState(false);

  const handleTooltipClose = (tooltipType) => {
    if (tooltipType === "withoutIntervention") {
      setOpenWithoutIntervention(false);
    } else if (tooltipType === "withIntervention") {
      setOpenWithIntervention(false);
    } else if (tooltipType === "shifted") {
      setOpenShifted(false);
    }
  };

  const handleTooltipOpen = (tooltipType) => {
    if (tooltipType === "withoutIntervention") {
      setOpenWithoutIntervention(true);
    } else if (tooltipType === "withIntervention") {
      setOpenWithIntervention(true);
    } else if (tooltipType === "shifted") {
      setOpenShifted(true);
    }
  };

  const textFieldStyle = {
    minWidth: "346px",
    // maxWidth: "600px",
    height: "68px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    padding: 10,
    cursor: "pointer",
    transition: "cursor 0.3s ease",

  };

  const renderCircle = (color) => (
    <div
      style={{
        width: "18px",
        height: "4px",
        borderRadius: "100%",
        backgroundColor: color,
        marginRight: "8px",
      }}
    />
  );

  return (
    <Grid container alignItems="center" spacing={1} sx={{ p: 2 }}>
      {/* <Grid item sx={0.5} align="left"/> */}
    <Grid item xs={4} align="center">
      <ClickAwayListener
        onClickAway={() => handleTooltipClose("withoutIntervention")}
      >
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => handleTooltipClose("withoutIntervention")}
          open={openWithoutIntervention}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Estimated baseline geyser energy usage without intervention"
          placement='bottom-start'
          arrow={true}
        >
          <InputBase
            readOnly
            onClick={() => handleTooltipOpen("withoutIntervention")}
            startAdornment={renderCircle("#333333")}
            endAdornment={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <strong>{`${values.data.energy_without_intervention_sum}`}</strong>
                <strong style={{ marginLeft: "4px" }}>MWh</strong>
              </div>
            }
            value="Load Without Intervention"
            style={textFieldStyle}
          />
        </Tooltip>
      </ClickAwayListener>
    </Grid>
    <Grid item xs={4} align="center">
      <ClickAwayListener
        onClickAway={() => handleTooltipClose("withIntervention")}
      >
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => handleTooltipClose("withIntervention")}
          open={openWithIntervention}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Actual summated geyser energy with intervention."
          placement='bottom-start'
          arrow={true}
        >
          <InputBase
            onClick={() => handleTooltipOpen("withIntervention")}
            readOnly
            startAdornment={renderCircle("#FDB940")}
            endAdornment={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <strong>{`${values.data.energy_with_intervention_sum}`}</strong>
                <strong style={{ marginLeft: "4px" }}>MWh</strong>
              </div>
            }
            value="Load With Intervention"
            style={textFieldStyle}
          />
        </Tooltip>
      </ClickAwayListener>
    </Grid>
    <Grid item xs={4} align="center">
      <ClickAwayListener onClickAway={() => handleTooltipClose("shifted")}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => handleTooltipClose("shifted")}
          open={openShifted}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Difference between the summated geyser energy consumption with and without intervention"
          placement='bottom-start'
          arrow={true}
        >
          <InputBase
            onClick={() => handleTooltipOpen("shifted")}
            readOnly
            endAdornment={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <strong>{`${values.data.energy_shifted_sum}`}</strong>
                <strong style={{ marginLeft: "4px" }}>MWh</strong>
              </div>
            }
            value={`Shifted`}
            style={textFieldStyle}
          />
        </Tooltip>
      </ClickAwayListener>
    </Grid>
  </Grid>
);
 
}

function ShavedValues({ values }) {
  const textFieldShavedStyle = {
    width: "100%",
    height: "auto",
    lineHeight: "3",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: 10,
  };

  let shavedValuesString = values.data.demand_shaved_max_per_month
    .map((item) => ` ${item.month}: ${item.demand_shaved_max} MVA \t`)
    .join("");

  const [openShaved, setOpenShaved] = useState(false);

  const handleTooltipClose = () => {
    setOpenShaved(false);
  };

  const handleTooltipOpen = () => {
    setOpenShaved(true);
  };
  return (
    <Grid item xs={12} align="center">
      <ClickAwayListener
        onClickAway={() => handleTooltipClose()}
      >
        <Tooltip
          PopperProps={{
            disablePortal: true,
            style: { marginTop: 100 } 
          }}
          onClose={() => handleTooltipClose()}
          open={openShaved}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="The difference between the actual peak 
          demand (kVA) and what the peak would have 
          been at that time without intervention"
          placement='bottom-start'
          arrow={true}
        >
          <InputBase
            onClick={() => handleTooltipOpen()}
            readOnly
            multiline
            maxRows={2}
            startAdornment={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    borderRight: "1px solid #F1F1F1",
                    height: "100%",
                    marginRight: "8px",
                    paddingRight: "8px",
                  }}
                >
                  Shaved
                </div>
              </div>
            }
            value={` ${shavedValuesString}`}
            style={{
              ...textFieldShavedStyle,
              padding: "14px",
            }}
          />
        </Tooltip>
      </ClickAwayListener>
    </Grid>
  );
}
