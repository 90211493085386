import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const residentsAdapter = createEntityAdapter();

const initialState = residentsAdapter.getInitialState();

export const residentsAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({


      getResidentsNotifications: builder.query({
        query: ({serial_number, userTimezone}) => `/meter/${serial_number}/notifications/?timezone=${userTimezone}`,
        providesTags: ["residents_notifications"],
      }),
      getDeviceDetails: builder.query({
        query: ({serial_number}) => `/meter/${serial_number}/details`,
        providesTags: ["residents_notifications"],
      }),
  }),
});

export const {
    useGetDeviceDetailsQuery,
    useGetResidentsNotificationsQuery
} = residentsAPISlice;
