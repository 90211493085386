//React
import React from "react";
import { useState } from "react";
//Material UI
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteScheduleMutation } from "../api/apiSlice";

export default function DeleteSchedule({ schedule }) {

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Button style={{ color: "red" }} sx={{ mt: 2, mb: 2 }} onClick={() => handleOpen()} >
        DELETE this schedule
</Button>      <Dialog
        BackdropProps={{ invisible: true }}
        PaperProps={{
          // style: theme.modalCardStyle.root,
        }}
      
        open={open}
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <ScheduleDialogContent
            schedule={schedule}
           
            handleClose={handleClose}
            
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

function ScheduleDialogContent({ schedule, handleClose }) {


  const [deleteSchedule] = useDeleteScheduleMutation();

  const handleSubmit = (event) => {
   
    const request = {
      schedule_id: schedule.id,
    };
   
    deleteSchedule(request);
    handleClose();
  };

  return (
    <>
      <Grid container>
       <Typography>Are you sure you want to delete this schedule? (This schedule may be linked to other groups)</Typography>

        <Grid item xs={12} align="center">
          <Button
            sx={{ mt: 2, mb: 2 }}
            style={{ color: "#000" }}
            onClick={handleSubmit}
          >
           YES
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
