//React
import * as React from "react";
import { useEffect } from "react";
//Material UI
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import theme from "../../Global/theme";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
//Redux
import { useAddjobCardMutation } from "../api/apiSlice";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "./Stepper/stepperSlice";
//Routing
import { useNavigate } from "react-router-dom";
//Components
import NavbarStepper from "./Stepper/NavBarStepper";

export default function ClientDetailsPage() {
  const dispatch = useDispatch();
  dispatch(setCurrentStep(0));

  const [
    addJobCard,
    {
      data: job_card_data,
      isSuccess: isAddJobCardSuccess,
      isError: isAddJobCardError,
      error: addJobCardError,
    },
  ] = useAddjobCardMutation();

  const [address, setAddress] = React.useState("");
  const [geyser_name, setGeyserName] = React.useState("");
  const [customer_consent, setCustomerConsent] = React.useState("Unwilling");
  const [disabled_button, setDisabled] = React.useState(true);

  const [error_alert, setErrorAlert] = React.useState(false);
  const [error_message, setErrorMessage] = React.useState(false);
  const [abort_dialog_open, setAbortDialogOpen] = React.useState(false);

  const [geyser_type, setgeyser_type] = React.useState("Primary");
  const [geyser_orientation, setgeyser_orientation] = React.useState("Vertical");
  const [breaker_control, setbreaker_control] = React.useState(false);
  const [solar_geyser, setsolar_geyser] = React.useState(false);
  const [solar_pv, setsolar_pv] = React.useState(false);
  const [ripple_replacement, setripple_replacement] = React.useState(false);
  const [timer_installed, settimer_installed] = React.useState(false);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    switch (name) {
      case "breaker_control":
        setbreaker_control(checked);
        break;
      case "solar_geyser":
        setsolar_geyser(checked);
        break;
      case "solar_pv":
        setsolar_pv(checked);
        break;
      case "timer_installed":
        settimer_installed(checked);
        break;
        case "ripple_replacement":
        setripple_replacement(checked);
        break;
      default:
        break;
    }
  };
  const handleOpenAbortDialog = () => {
    if (address == "") {
      setErrorMessage("Address is missing!");
      setErrorAlert(true);
    } else {
      setAbortDialogOpen(true);
    }
  };

  const handleCloseAbortDialog = () => {
    setAbortDialogOpen(false);
  };

  const handleChangeCustomerConsent = (event) => {
    setCustomerConsent(event.target.value);
  };

  const handleChangeGeyser = (event) => {
    setgeyser_type(event.target.value);
  };

  const handleChangeGeyserOrientation = (event) => {
    setgeyser_orientation(event.target.value);
  };
  let navigate = useNavigate();

  const handleAddJobCard = () => {
    if (address == "") {
      setErrorMessage("Address is missing!");
      setErrorAlert(true);
    } else if (geyser_name == "") {
      setErrorMessage("Geyser Name/Description is missing!");
      setErrorAlert(true);
    } else {
      const body = {
        address: address,
        geyser_name: geyser_name,
        customer_consent: customer_consent,
        geyser_type: geyser_type,
        geyser_orientation: geyser_orientation,
        breaker_control: breaker_control,
        solar_geyser: solar_geyser,
        solar_pv: solar_pv,
        ripple_replacement: ripple_replacement,
        timer_installed: timer_installed,
        aborted: false,
      };
      addJobCard({ body: body });
    }
  };

  const handleAbortJob = () => {
    const body = {
      address: address,
      customer_consent: customer_consent,
      aborted: true,
    };

    addJobCard({ body: body });
  };

  useEffect(() => {
    if (isAddJobCardSuccess) {
      navigate(`/installer/${job_card_data.job_card_reference_id}/qrcode/`);
    }
  }, [isAddJobCardSuccess]);

  useEffect(() => {
    if (isAddJobCardError) {
      setErrorAlert(true);
      setErrorMessage(addJobCardError.data.error_message);
    }
  }, [isAddJobCardError]);

  useEffect(() => {
    if (customer_consent === "Willing") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [customer_consent]);

  return (
    <>
      <NavbarStepper />
      <DialogAbort
        open={abort_dialog_open}
        handleCloseDialog={handleCloseAbortDialog}
        handleAbortJob={handleAbortJob}
      />
      <Typography align="center" sx={{fontSize:16, color: "grey", mt:-2}}>Installation Details</Typography>

      <Grid container alignItems="center" justifyContent="center" sx={{mt:-2}}>
        <GenericTextField
          setterVariable={address}
          setterFunction={setAddress}
          label="Address"
        />

        <GenericTextField
          setterVariable={geyser_name}
          setterFunction={setGeyserName}
          label="Geyser Name / Description"
        />

        <RadioButtons
          handleChange={handleChangeCustomerConsent}
          value={customer_consent}
          geyser_type={geyser_type}
          geyser_orientation={geyser_orientation}
          handleChangeGeyser={handleChangeGeyser}
          handleChangeGeyserOrientation={handleChangeGeyserOrientation}
        />
        <MyCheckboxes
          breaker_control={breaker_control}
          solar_geyser={solar_geyser}
          solar_pv={solar_pv}
          ripple_replacement={ripple_replacement}
          timer_installed={timer_installed}
          handleChange={handleChange}
        />
        <ErrorAlert
          error_alert={error_alert}
          setErrorAlert={setErrorAlert}
          error_message={error_message}
        />
      </Grid>
      <Grid container justifyContent="flex-end" spacing={0.5}>
        <Grid
          item
          xs={11}
          align="right"
          style={{ marginBottom: 20, marginTop: 40 }}
          sx={{ mr: 1 }}
        >
          <Button
            sx={theme.blackButtonStyle.root}
            variant="outlined"
            onClick={handleOpenAbortDialog}
            disabled={!disabled_button}
            style={{ marginRight: 5 }}
          >
            Abort
          </Button>
          <Button
            sx={theme.yellowButtonStyle.root}
            variant="outlined"
            onClick={() => handleAddJobCard()}
            disabled={disabled_button}
            style={{ marginLeft: 5 }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

function GenericTextField({ setterVariable, setterFunction, label }) {
  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={10} align="center" style={{ marginBottom: 10 }}>
        <TextField
          onChange={(e) => setterFunction(e.target.value)}
          margin="normal"
          label={label}
          value={setterVariable}
          variant="standard"
          fullWidth
        />
      </Grid>
      <Grid item xs={1} />
    </>
  );
}

function RadioButtons({
  handleChange,
  value,
  geyser_type,
  geyser_orientation,
  handleChangeGeyser,
  handleChangeGeyserOrientation
}) {
  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={10} align="left" style={{ marginBottom: 10 }} sx={{mt:1}}>
        <Typography align="left" sx={{fontSize:12, color: "grey"}}>Customer Detail - select one</Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={"Willing"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Customer Willing"
            />
          
            <FormControlLabel
              value={"Unwilling"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Customer Unwilling"
            />
           
            <FormControlLabel
              value={"Absent"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Customer Absent"
            />
          </RadioGroup>
        </FormControl>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item xs={10} align="left" style={{ marginBottom: 10 }} sx={{mt:1}}>
      <Typography align="left" sx={{fontSize:12, color: "grey"}}>Geyser Orientation - select one</Typography>

        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={geyser_orientation}
            onChange={handleChangeGeyserOrientation}
          >
            <FormControlLabel
              value={"Vertical"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Vertical"
            />
            <FormControlLabel
              value={"Horizontal"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Horizontal"
            />
          </RadioGroup>
        </FormControl>
        <Divider variant="middle" />
      </Grid>
      
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item xs={10} align="left" style={{ marginBottom: 10 }} sx={{mt:1}}>
      <Typography align="left" sx={{fontSize:12, color: "grey"}}>Geyser type - select one</Typography>

        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={geyser_type}
            onChange={handleChangeGeyser}
          >
            <FormControlLabel
              value={"Primary"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Primary"
            />
            <FormControlLabel
              value={"Secondary"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Secondary"
            />
          </RadioGroup>
        </FormControl>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={1} />
    </>
  );
}

function MyCheckboxes({
  breaker_control,
  solar_geyser,
  solar_pv,
  ripple_replacement,
  timer_installed,
  handleChange,
}) {
  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={10} align="left" style={{ marginBottom: 10 }}>
        <FormGroup>
        <Typography align="left" sx={{fontSize:12, color: "grey", mt:1, mb:1}}>Breaker Control</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={breaker_control}
                onChange={handleChange}
                name="breaker_control"
                sx={{
                  "&.Mui-checked": {
                    color: "#FDB940",
                  },
                }}
              />
            }
            label="User switches breaker"
          />
          <Divider variant="middle" />
          <Typography align="left" sx={{fontSize:12, color: "grey", mt:1, mb:1}}>Geyser type</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={solar_geyser}
                onChange={handleChange}
                name="solar_geyser"
                sx={{
                  "&.Mui-checked": {
                    color: "#FDB940",
                  },
                }}
              />
            }
            label="Solar Geyser"
          />
          <Divider variant="middle" />
          <Typography align="left" sx={{fontSize:12, color: "grey", mt:1, mb:1}}>Solar PV Detail</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={solar_pv}
                onChange={handleChange}
                name="solar_pv"
                sx={{
                  "&.Mui-checked": {
                    color: "#FDB940",
                  },
                }}
              />
            }
            label="Solar PV on roof"
          />
          <Divider variant="middle" />
          <Typography align="left" sx={{fontSize:12, color: "grey", mt:1, mb:1}}>Timer or Device</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={timer_installed}
                onChange={handleChange}
                name="timer_installed"
                sx={{
                  "&.Mui-checked": {
                    color: "#FDB940",
                  },
                }}
              />
            }
            label="Timer or device Installed"
          />
           <Divider variant="middle" />
          <Typography align="left" sx={{fontSize:12, color: "grey", mt:1, mb:1}}>Ripple Replacement</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={ripple_replacement}
                onChange={handleChange}
                name="ripple_replacement"
                sx={{
                  "&.Mui-checked": {
                    color: "#FDB940",
                  },
                }}
              />
            }
            label="Ripple Replacement"
          />
        </FormGroup>
        <Divider variant="middle" />

        
      </Grid>
      <Grid item xs={1} />
    </>
  );
}
function ErrorAlert({ error_alert, setErrorAlert, error_message }) {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorAlert(false);
  };

  return (
    <div>
      <Snackbar
        open={error_alert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error_message}
        </Alert>
      </Snackbar>
    </div>
  );
}

function DialogAbort({ open, handleCloseDialog, handleAbortJob }) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        Are you sure you want to abort the current installation?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You will need to start a new install.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="text">
          Back
        </Button>
        <Button onClick={() => handleAbortJob()} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
