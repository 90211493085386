import { createSlice } from "@reduxjs/toolkit/";

const initialState = {

  bulkList:[],

};

export const loadShiftSlice = createSlice({
  name: "loadShift",
  initialState,
  
  reducers: {
    
    setbulkList:(state, action) =>{
      state.bulkList = action.payload;
    },
   
  },
});


export const getbulkList = (state)=> state.loadShift.bulkList;

export const { setbulkList } = loadShiftSlice.actions;

export default loadShiftSlice.reducer;
