import { createSlice } from "@reduxjs/toolkit/";

const initialState = {

  steps:[0,1,2,3,4,5,6,7,8,9],
  currentStep:'',
};

export const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  
  reducers: {
    
    setsteps:(state, action) =>{
      state.steps = action.payload;
    },
   setCurrentStep:(state, action) =>{
    state.currentStep = action.payload;
  },
  },
});


export const getsteps = (state)=> state.stepper.steps;
export const getCurrentStep = (state)=> state.stepper.currentStep;

export const { setsteps,setCurrentStep } = stepperSlice.actions;

export default stepperSlice.reducer;
